// @flow
import {
  PAGE_MODIFIERS_SET_PAGE_MODIFIERS,
  PAGE_MODIFIERS_SET_MODIFIER,
  PAGE_MODIFIERS_ADD_PAGE_MODIFIERS,
  PAGE_MODIFIERS_REMOVE_PAGE_MODIFIERS,
  PAGE_MODIFIERS_RESET_PAGE_MODIFIERS,
} from "../../helpers/constants";
import type { IncomingPageModifiersState, Modifier } from "../../reducers/pageModifiers/reducer";

export type SetModifierByName = {|
  type: typeof PAGE_MODIFIERS_SET_MODIFIER,
  name: string,
  content: string,
|};

export type SetPageModifiersAction = {|
  type: typeof PAGE_MODIFIERS_SET_PAGE_MODIFIERS,
  modifierCollection?: Array<IncomingPageModifiersState>,
|};

export type ResetPageModifiersAction = {|
  type: typeof PAGE_MODIFIERS_RESET_PAGE_MODIFIERS,
|};

export type AddModifierAction = {|
  type: typeof PAGE_MODIFIERS_ADD_PAGE_MODIFIERS,
  modifier: Modifier,
|};

export type RemoveModifierAction = {|
  type: typeof PAGE_MODIFIERS_REMOVE_PAGE_MODIFIERS,
  name: string,
|};

export type Actions =
SetPageModifiersAction
| SetModifierByName
| AddModifierAction
| RemoveModifierAction
| ResetPageModifiersAction

export function addModifier(modifier: Modifier): AddModifierAction {
  return {
    type: PAGE_MODIFIERS_ADD_PAGE_MODIFIERS,
    modifier,
  };
}

export function removeModifier(name: string): RemoveModifierAction {
  return {
    type: PAGE_MODIFIERS_REMOVE_PAGE_MODIFIERS,
    name,
  };
}

export function setModifierByName(name: string, content: string): SetModifierByName {
  return {
    type: PAGE_MODIFIERS_SET_MODIFIER,
    name,
    content,
  };
}

export function setPageModifiers(modifierCollection?: Array<IncomingPageModifiersState>): SetPageModifiersAction {
  return {
    type: PAGE_MODIFIERS_SET_PAGE_MODIFIERS,
    modifierCollection,
  };
}

export function resetPageModifiers(): ResetPageModifiersAction {
  return {
    type: PAGE_MODIFIERS_RESET_PAGE_MODIFIERS,
  };
}
