// @flow
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box, FormControl, Grid, TextField, Typography,
} from "@mui/material";
import { SetupCard } from "@fas/ui-core";
import { getErrorsMessage } from "@fas/ui-framework/lib/redux/selectors/errors/selectors";
import { removeError } from "@fas/ui-framework/lib/redux/actions/errors/actions";
import { getGeneralInfo } from "../../selectors/account";
import { changeAccountDomain, changeAccountName } from "../../actions/accountInfo";
import type { State } from "../../pages/AppStoreWrapper";

function AccountInfo() {
  const dispatch: <A>(A) => A = useDispatch();
  const { name, domain } = useSelector((state: State) => getGeneralInfo(state));
  const errors = useSelector((state: State) => getErrorsMessage(state));
  const handleChangeName = (e) => {
    dispatch(changeAccountName(e.target.value));
    dispatch(removeError(["name"]));
  };
  const handleCHangeDomain = (e) => {
    dispatch(changeAccountDomain(e.target.value));
    dispatch(removeError(["domain"]));
  };

  return (
    <SetupCard title="General info">
      <>
        <Box width={1} mb={2}>
          <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
            <Grid item xs={3}>
              <Typography>Account Name</Typography>
            </Grid>
            <Grid item xs={9}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id="account-name"
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={handleChangeName}
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                  inputProps={{ maxLength: "128" }}
                  data-testid="account-name-text"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box width={1} mb={2}>
          <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
            <Grid item xs={3}>
              <Typography>Domain</Typography>
            </Grid>
            <Grid item xs={9}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id="account-domain"
                  variant="outlined"
                  size="small"
                  value={domain}
                  onChange={handleCHangeDomain}
                  inputProps={{ maxLength: "128" }}
                  data-testid="account-domain-text"
                  error={Boolean(errors.domain)}
                  helperText={errors.domain}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </>
    </SetupCard>
  );
}

export default AccountInfo;
