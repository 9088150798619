// @flow
import {
  CHANGE_REDIRECT_PAGE_URL,
  CHANGE_REDIRECT_STATUS_CODE,
  CHANGE_REDIRECT_REDIRECT_URL,
  SET_REDIRECT,
} from "../../helpers/constants";
import type { IncomingRedirectInfoState } from "../../reducers/redirectInfo";

export type ChangeRedirectPageUrlAction = {|
  type: typeof CHANGE_REDIRECT_PAGE_URL,
  pageUrl: string,
|};

export type ChangeRedirectStatusCodeAction = {|
  type: typeof CHANGE_REDIRECT_STATUS_CODE,
  statusCode: string,
|};

export type ChangeRedirectRedirectUrlAction = {|
  type: typeof CHANGE_REDIRECT_REDIRECT_URL,
  redirectUrl: string,
|};

export type SetRedirectAction = {|
  type: typeof SET_REDIRECT,
  payload: IncomingRedirectInfoState
|};

export type Actions =
  ChangeRedirectPageUrlAction
  | ChangeRedirectStatusCodeAction
  | ChangeRedirectRedirectUrlAction
  | SetRedirectAction;

export const changeRedirectPageUrl = (pageUrl: string): ChangeRedirectPageUrlAction => ({
  type: CHANGE_REDIRECT_PAGE_URL,
  pageUrl,
});

export const changeRedirectStatusCode = (statusCode: string): ChangeRedirectStatusCodeAction => ({
  type: CHANGE_REDIRECT_STATUS_CODE,
  statusCode,
});

export const changeRedirectRedirectUrl = (redirectUrl: string): ChangeRedirectRedirectUrlAction => ({
  type: CHANGE_REDIRECT_REDIRECT_URL,
  redirectUrl,
});

export const setRedirect = (payload: IncomingRedirectInfoState): SetRedirectAction => ({
  type: SET_REDIRECT,
  payload,
});
