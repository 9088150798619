// @flow
import React from "react";
import { connect } from "react-redux";
import ImportPageFd from "../../components/ImportPageFd";
import { closeImportPageDialog, startImportPage } from "../../actions/pageActions";
// $FlowFixMe
import type { ImportFileType } from "../../components/ImportPageFd/types/ImportPageFd.types";
import { getIsPageImporting } from "../../selectors/loading";
import { getIsPageImportingOpen } from "../../selectors/pageActions";

type OwnProps = $ReadOnly<{|
  store?: Object,
|}>;

type Props = $ReadOnly<{|
...OwnProps,
  isOpen: boolean,
  isPageImporting: boolean,
  startImport: (file: ImportFileType, type: string, activate: boolean) => void,
  closeDialog: () => void,
|}>;

const mapStateToProps = (state) => ({
  isOpen: getIsPageImportingOpen(state),
  isPageImporting: getIsPageImporting(state),
});

const mapDispatchToProps = (dispatch) => ({
  startImport: (file: ImportFileType, type: string, activate: boolean) => dispatch(
    startImportPage(file, type, activate)
  ),
  closeDialog: () => dispatch(
    closeImportPageDialog()
  ),
});

function ImportPageDialog({
  isOpen,
  isPageImporting,
  startImport,
  closeDialog,
}: Props) {
  return (
    <ImportPageFd
      open={isOpen}
      importing={isPageImporting}
      onApply={startImport}
      onClose={closeDialog}
    />
  );
}

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(ImportPageDialog);
