// @flow
/* eslint-disable import/max-dependencies */
import { useNavigate, createSearchParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardContent,
  Grid,
  Box,
} from "@mui/material";
import React, { useEffect } from "react";
import type { DataItem } from "@fas/ui-framework/lib/redux/reducers/table";
import type { StoreType } from "@fas/ui-framework/lib/services/table/types/table.types";
import { getTableData } from "@fas/ui-framework/lib/redux/selectors/table";
import { getAccountList } from "../../actions/accountList";
import { ACCOUNT_LIST } from "../../helpers/constants";
import TitleSection from "./TitleSection";
import SearchSection from "./SearchSection";
import AccountSkeletonCard from "./AccountSkeletonCard";
import { getAccountsListLoading } from "../../selectors/loading";
import AccountCard from "./AccountCard";
import useHashId from "../../hooks/useHashId";

function IndexComponent() {
  const navigate = useNavigate();
  const dispatch: <A>(A) => A = useDispatch();
  const data: DataItem[] = useSelector((state: StoreType): DataItem[] => getTableData(state, ACCOUNT_LIST));
  const isAccountsListLoading = useSelector(getAccountsListLoading);
  const {
    hashId, hashIdCookie, setHashId, removeHashId,
  } = useHashId();

  useEffect(() => {
    if (!hashId && hashIdCookie) {
      navigate({
        pathname: "/",
        search: `?${createSearchParams({
          hashId: hashIdCookie,
        })}`,
      });
    }
  }, [hashIdCookie]);

  const handleSearch = ({
    nameFilter,
    domainFilter,
    userFilter,
  }) => {
    dispatch(getAccountList({
      ...(nameFilter && { name: nameFilter }),
      // $FlowFixMe
      ...(domainFilter && { domain: domainFilter }),
      ...(userFilter && { user: userFilter }),
    }));
  };
  const handleChoiceAccount = (id: string) => {
    removeHashId();
    setHashId(id);
    navigate({
      pathname: "/",
      search: `?${createSearchParams({
        hashId: id,
      })}`,
    });
  };
  const handleEditClick = (id: string) => {
    navigate(`/editAccount/${id}`);
  };

  return (
    <Box py={2}>
      {!hashId && (
        <>
          <TitleSection />
          <SearchSection handleSearch={handleSearch} isAccountsListLoading={isAccountsListLoading} />
          <Grid container alignItems="flex-start" justifyContent="center">
            {isAccountsListLoading && Array(10).fill("").map((i, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={idx} mb={1} px={1}>
                <AccountSkeletonCard />
              </Box>
            ))}
            {!isAccountsListLoading && (data.length || null)
                && data.map((i) => (
                  <Box key={i.id} mb={1} px={1}>
                    <AccountCard info={i} handleClickArea={handleChoiceAccount} handleEditClick={handleEditClick} />
                  </Box>
                ))}
          </Grid>
        </>
      )}
      {hashId && (
        <Box p={2} display="flex" alignItems="flex-start" justifyContent="space-around">
          <Card variant="outlined" p={1} sx={{ width: "350px", textAlign: "center" }}>
            <CardContent>
              <Box mb={1}>
                <Link to={`/pages/list?hashId=${hashId}`}>Pages List</Link>
              </Box>
              <Box>
                <Link to={`/pages/create?hashId=${hashId}`}>Create page</Link>
              </Box>
            </CardContent>
          </Card>
          <Card variant="outlined" p={1} sx={{ width: "350px", textAlign: "center" }}>
            <CardContent>
              <Box mb={1}>
                <Link to={`/redirects/list?hashId=${hashId}`}>Redirects List</Link>
              </Box>
              <Box>
                <Link to={`/redirects/create?hashId=${hashId}`}>Create redirect</Link>
              </Box>
            </CardContent>
          </Card>
          <Card variant="outlined" p={1} sx={{ width: "350px", textAlign: "center" }}>
            <CardContent>
              <Box mb={1}>
                <Link to={`/linking/list?hashId=${hashId}`}>Linkings List</Link>
              </Box>
              <Box>
                <Link to={`/linking/create?hashId=${hashId}`}>Create linking</Link>
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
}

export default IndexComponent;
