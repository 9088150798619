// @flow
import type { RecordFactory, RecordOf } from "immutable";
import { Record, List } from "immutable";
import {
  ACCOUNT_CHANGE_USERS,
  ADD_ACCOUNT_USER,
  REMOVE_ACCOUNT_USER,
  CHANGE_ACCOUNT_USER_ROLE,
} from "../../helpers/constants";
import type { Actions } from "../../actions/accountUsers";

export type DefaultState = {
  users: List<{ id: string, username: string, role?: string }>,
}

const defaultValues: DefaultState = {
  users: List([]),
};

export const makeState: RecordFactory<DefaultState> = Record(defaultValues);

export type State = RecordOf<DefaultState>;

export const initialState: State = makeState({});

export function initSetupAccountUsersState(): State {
  return initialState;
}

// eslint-disable-next-line default-param-last
export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ACCOUNT_CHANGE_USERS: {
      return state.withMutations((newState) => {
        newState.setIn(["users"], List(action.value));
      });
    }
    case ADD_ACCOUNT_USER: {
      return state.withMutations((newState) => {
        newState.update("users", (users) => users.push(action.value));
      });
    }
    case REMOVE_ACCOUNT_USER: {
      return state.withMutations((newState) => {
        const users = newState.get("users");
        const index = users.findIndex(({ id }) => (id === action.userId));
        if (index !== -1) {
          newState.deleteIn(["users", index]);
        }
      });
    }
    case CHANGE_ACCOUNT_USER_ROLE: {
      return state.withMutations((newState) => {
        const users = newState.get("users");
        const index = users.findIndex(({ id }) => (id === action.userId));
        // $FlowFixMe
        newState.setIn(["users", index, "role"], action.value);
      });
    }
    default:
      return state;
  }
};
