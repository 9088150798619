// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type { State } from "../../pages/AppStoreWrapper";

import type {
  LinkingInfo,
} from "../../reducers/linkingList";

import type { State as LinkingInfoState } from "../../reducers/linkingInfo";

export const getName: OutputSelector<
  State,
  *,
  string
> = createSelector(
  (state: State): LinkingInfoState => state.linking,
  (linking: LinkingInfoState): string => linking.get("name")
);

export const getType: OutputSelector<
  State,
  *,
  string
> = createSelector(
  (state: State): LinkingInfoState => state.linking,
  (linking: LinkingInfoState): string => linking.get("type")
);

export const getTagsGeneration: OutputSelector<
  State,
  *,
  Array<string>
> = createSelector(
  (state: State): LinkingInfoState => state.linking,
  (linking: LinkingInfoState): Array<string> => linking.get("tagsGeneration")
);

export const getTagsDisplay: OutputSelector<
  State,
  *,
  Array<string>
> = createSelector(
  (state: State): LinkingInfoState => state.linking,
  (linking: LinkingInfoState): Array<string> => linking.get("tagsDisplay")
);

export const getTemplateCode: OutputSelector<
  State,
  *,
  string
> = createSelector(
  (state: State): LinkingInfoState => state.linking,
  (linking: LinkingInfoState): string => linking.get("templateCode")
);

export const getSorting: OutputSelector<
  State,
  *,
  string
> = createSelector(
  (state: State): LinkingInfoState => state.linking,
  (linking: LinkingInfoState): string => linking.get("sorting")
);

export const getHeight: OutputSelector<
  State,
  *,
  number
> = createSelector(
  (state: State): LinkingInfoState => state.linking,
  (linking: LinkingInfoState): number => linking.get("height")
);

export const getWidth: OutputSelector<
  State,
  *,
  number
> = createSelector(
  (state: State): number => state.linking.get("width"),
  (width: number): number => width
);

export const getLinkingInfo: OutputSelector<
  State,
  *,
  LinkingInfo
> = createSelector(
  (state: State): LinkingInfoState => state.linking,
  // $FlowFixMe
  (linking: LinkingInfoState): LinkingInfo => linking.toJS()
);

export const getAlgorithm: OutputSelector<
  State,
  *,
  string
> = createSelector(
  (state: State): LinkingInfoState => state.linking,
  (li: LinkingInfoState): string => li.get("algorithm")
);

export const getLinkingInfoUsePagination : OutputSelector<
  State,
  *,
  boolean
> = createSelector(
  (state: State): LinkingInfoState => state.linking,
  (li: LinkingInfoState): boolean => li.get("usePagination")
);
