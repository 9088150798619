// @flow

import type { ValidationResult } from "joi";
import setWith from "lodash.setwith";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";

export default function errorsTransform(
  validationResult: ValidationResult
): ErrorsMapper {
  if (validationResult.error && validationResult.error.details) {
    const errorObj = validationResult.error.details.reduce((acc, errorItem) => {
      const [globPath, localPath] = errorItem.path;
      setWith(
        acc,
        localPath || globPath,
        { message: errorItem.message },
        Object
      );
      return acc;
    }, {});
    return errorObj;
  }
  return {};
}
