// @flow
import {
  CLEAR_BACKUP,
  RESTORE_BACKUP,
  SET_BACKUP,
} from "../../helpers/constants";

export default (reducer: Function) => {
  const history = [];
  // eslint-disable-next-line default-param-last
  return (state: any = reducer(undefined, {}), action: Object) => {
    switch (action.type) {
      case SET_BACKUP: {
        const backup = action.backup.reduce((obj, key) => ({ ...obj, [key]: state[key] }), {});
        history.push(backup);
        return state;
      }
      case RESTORE_BACKUP: {
        const backup = history.pop() || {};
        return {
          ...state,
          ...backup,
        };
      }
      case CLEAR_BACKUP: {
        history.pop();
        return state;
      }
      default:
        return reducer(state, action);
    }
  };
};
