// @flow
import { call, put, takeEvery } from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import type { Saga } from "redux-saga";
import type { GetLinkingInfoSaga } from "../../actions/linkingList";
import { setLinkingInfo } from "../../actions/linkingList";
import { GET_LINKING_ITEM_SAGA, GET_LINKING_LOADING } from "../../helpers/constants";
import LinkingApi from "../../services/linkingApi";
import setLoading from "../../actions/loading";

export function* makeFetch(action: GetLinkingInfoSaga): Saga<void> {
  try {
    const { id } = action;
    yield put(setLoading(GET_LINKING_LOADING, true));

    const {
      data: { data: linkingInfo },
    } = yield call(LinkingApi.getLinkingInfo, id);
    yield put(setLinkingInfo(linkingInfo));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch linking", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(GET_LINKING_LOADING, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_LINKING_ITEM_SAGA, (makeFetch: Function));
}
