// @flow
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getAccountsModulesSaga } from "../../actions/accountModules";
import { userService } from "../../services/user";
import { getHashValue } from "../../services/jusCan";

type Props = {
  children: React$Node,
};

function AppStoreWrapper({ children }: Props) {
  const dispatch: <A>(A) => A = useDispatch();
  const navigate = useNavigate();
  const isHaveAccess = userService.can([{ path: "/api/v1/jus/accounts", method: "GET" }]);
  const hashValue = getHashValue();

  useEffect(() => {
    if (hashValue) {
      dispatch(getAccountsModulesSaga(["linking", "document", "resource"]));
    }
  }, [dispatch, getAccountsModulesSaga, hashValue]);

  useEffect(() => {
    if (!isHaveAccess) {
      navigate("/accessDenied");
    }
  }, [isHaveAccess, navigate]);

  return children;
}

export default AppStoreWrapper;
