// @flow
import React from "react";
import {
  Button,
  Grid,
} from "@mui/material";
import type { ExportType, PageTypes } from "./types/ExportPageFd.types";
import FormDialog from "../FormDialog";
import type { Page } from "../../reducers/exportPage/reducer";

interface Props {
  open: boolean;
  exporting: boolean;
  page: Page;
  onApply: (type: ExportType, ids: Array<string>, pageType: PageTypes) => void;
  onClose: () => void;
}

function ExportPageFd({
  open,
  exporting,
  page: {
    id: pageId,
    name: pageName,
    type: pageType,
  },
  onApply,
  onClose,
}: Props) {
  let ids = [];
  const resetDialog = () => {
    ids = [];
  };
  const handleClose = () => {
    onClose();
    resetDialog();
  };
  const handleApply = (type: ExportType) => {
    onApply(type, [...ids], pageType);
    resetDialog();
  };

  if (pageId) {
    ids.push(pageId);
  }

  return (
    <FormDialog
      open={open}
      loading={exporting}
      title={`Export Page ${pageName}`}
      hideApply
      onClose={handleClose}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        {pageType === "pft" && (
          <Grid item xs={6}>
            <Button
              id="csv-button"
              data-testid="csv-type-button"
              variant="contained"
              fullWidth
              onClick={() => handleApply("csv")}
            >
              CSV
            </Button>
          </Grid>
        )}
        {pageType !== "pft" && (
          <Grid item xs={6}>
            <Button
              id="archive-button"
              data-testid="archive-type-button"
              variant="contained"
              fullWidth
              onClick={() => handleApply("zip")}
            >
              Archive
            </Button>
          </Grid>
        )}
      </Grid>
    </FormDialog>
  );
}

ExportPageFd.defaultProps = {
  open: false,
  exporting: false,
  page: {
    id: "",
    name: "",
    type: "",
  },
};

export default ExportPageFd;
