// @flow
import type { Saga } from "redux-saga";
import {
  call, put, takeEvery,
} from "redux-saga/effects";
import { setTableData, changeTableItemsTotalAmount } from "@fas/ui-framework/lib/redux/actions/table/actions";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { GET_ACCOUNT_LIST_SAGA, ACCOUNT_LIST } from "../../helpers/constants";
import { getAccountList } from "../../services/accountApi/account";
import setLoading from "../../actions/loading";
import type { AccountListLoadingTypes as LoadingTypes } from "../../actions/loading";
import type { Sorting } from "../../pages/AppStoreWrapper";
import type { GetAccountListSaga } from "../../actions/accountList/actions";

export function* makeFetch(action: GetAccountListSaga): Saga<void> {
  const { filters } = action;
  try {
    yield put(setLoading<LoadingTypes>(ACCOUNT_LIST, true));

    const page: number = 1;
    const pageSize: number = 50;
    const sorting: Sorting = { name: "desc" };

    const response = yield call(getAccountList, {
      page, pageSize, filters, sorting,
    });
    const { data: { accountsList, count } } = response.data;

    yield put(setTableData(ACCOUNT_LIST, accountsList));
    yield put(changeTableItemsTotalAmount(ACCOUNT_LIST, count));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch account list", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
    yield put(setTableData(ACCOUNT_LIST, []));
  }
  finally {
    yield put(setLoading<LoadingTypes>(ACCOUNT_LIST, false));
  }
}

export default function* getAccountListSaga(): Saga<void> {
  yield takeEvery(GET_ACCOUNT_LIST_SAGA, makeFetch);
}
