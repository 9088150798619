// @flow
/* eslint-disable import/max-dependencies */
import React, {
  useEffect,
  useState,
  type Node,
} from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { mtuDefaultTheme as theme } from "@fas/ui-themes";
import { Loader, AccessDenied, Error } from "@fas/ui-core";
import { ConfirmProvider } from "material-ui-confirm";
import { fetchUserInfo, fetchUserInfoWithJUSPermissions, loginUrl } from "../../services/request";
import AppHeader from "../AppHeader";
import Pages from "../../pages/Pages";
import PageEdit from "../../pages/PageEdit";
import PageCreate from "../../pages/PageCreate";
import Redirects from "../../pages/Redirects";
import EditAccount from "../../pages/EditAccount";
import Redirect from "../../pages/Redirect";
import LinkingList from "../../pages/LinkingList";
import Linking from "../../pages/Linking";
import IndexPage from "../../pages/IndexPage";
import AppStoreWrapper from "../../pages/AppStoreWrapper";

function App(): Node {
  const [apiKey, setApiKey] = useState(null);
  const [loading, setLoading] = useState(false);

  const auth = async () => {
    try {
      const { api } = await fetchUserInfo();
      setApiKey(api);
    }
    catch (error) {
      window.location.href = loginUrl;
      return;
    }

    try {
      await fetchUserInfoWithJUSPermissions();
    }
    catch (error) {
      window.location.href = "/accessDenied";
    }
  };

  useEffect(() => {
    setLoading(true);

    auth().finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <Router>
            <AppHeader apiKey={apiKey} />
            {loading && <Loader loading size={35} mt={37} />}
            {!loading && (
              <Routes>
                {apiKey && (
                  <Route element={<AppStoreWrapper />}>
                    <Route path="/" element={<IndexPage />} />
                    <Route path="/createAccount" element={<EditAccount />} />
                    <Route path="/editAccount/:id" element={<EditAccount />} />

                    <Route path="/pages/list" element={<Pages />} />
                    <Route path="/pages/create" element={<PageCreate />} />
                    <Route path="/pages/editInfo/:id" element={<PageCreate />} />
                    <Route path="/pages/edit/:id" element={<PageEdit />} />

                    <Route path="/redirects/create" element={<Redirect />} />
                    <Route path="/redirects/edit/:id" element={<Redirect />} />
                    <Route path="/redirects/list" element={<Redirects />} />

                    <Route path="/linking/list" element={<LinkingList />} />
                    <Route path="/linking/edit/:id" element={<Linking />} />
                    <Route path="/linking/create" element={<Linking />} />
                  </Route>
                )}
                <Route path="/accessDenied" element={<AccessDenied />} />
                <Route path="*" element={<Error />} />
              </Routes>
            )}
          </Router>
        </ConfirmProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
