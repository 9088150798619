// @flow
import joi from "joi";
import type { Sorting as LinkingInfoSorting } from "../../components/LinkingInfoForm/types/LinkingInfoForm.types";

// eslint-disable-next-line import/prefer-default-export
export const schema = (sortings: Array<LinkingInfoSorting>) => joi
  .object({
    name: joi
      .string()
      .trim()
      .max(128)
      .label("Name"),
    tagsDisplay: joi
      .array()
      .items(joi.string())
      .min(1)
      .label("Display Tags")
      .when("type", { is: "matrix", then: joi.array().min(0) }),
    tagsGeneration: joi
      .array()
      .items(joi.string())
      .min(1)
      .label("Generation tags"),
    type: joi
      .string()
      .valid("matrix", "list")
      .label("Type")
      .error((errors) => {
        for (const err of errors) {
          if (err.code === "string.base") {
            err.message = `"${err.local.label}" is not allowed to be empty`;
          }
        }
        return errors;
      }),
    sorting: joi
      .string()
      .valid(...sortings)
      .label("Sorting")
      .error((errors) => {
        for (const err of errors) {
          if (err.code === "string.base") {
            err.message = `"${err.local.label}" is not allowed to be empty`;
          }
        }
        return errors;
      }),
    width: joi
      .number()
      .min(1)
      .label("Page size"),
    height: joi.number(),
  })
  .options({ abortEarly: false, stripUnknown: true });
