// @flow
import React from "react";
import DocumentTitle from "react-document-title";
import { Grid, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IndexComponent from "../../components/IndexComponent";

const useStyles = makeStyles((theme) => ({
  mainBackground: {
    background: theme.palette.background.mainBg,
    minHeight: "calc(100% - 64px)",
  },
}));

function IndexPage() {
  const classes = useStyles();

  return (
    <DocumentTitle title="Select account">
      <Grid container spacing={0} className={classes.mainBackground}>
        <Container maxWidth={false}>
          <IndexComponent />
        </Container>
      </Grid>
    </DocumentTitle>
  );
}

export default IndexPage;
