// @flow
import { takeEvery, call, put } from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import type { Saga } from "redux-saga";
import get from "lodash.get";
import { GET_LINKING_ITEM_HEIGHT_SAGA } from "../../helpers/constants";
import type { GetLinkingInfoHeightSaga } from "../../actions/linkingList";

import { setLinkingInfoHeight } from "../../actions/linkingList";
import LinkingApi from "../../services/linkingApi";
import setLoading from "../../actions/loading";

export function* makeFetch(action: GetLinkingInfoHeightSaga): Saga<void> {
  try {
    yield put(setLoading("height", true));
    const { tag } = action;

    const res = yield call(LinkingApi.getHeight, tag);

    const height: number = get(res, "data.data.count", 0);

    yield put(setLinkingInfoHeight(height));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch linking height", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("height", false));
  }
}
export default function* watch(): Saga<void> {
  yield takeEvery(GET_LINKING_ITEM_HEIGHT_SAGA, (makeFetch: Function));
}
