// @flow
import type { RecordFactory, RecordOf } from "immutable";
import { Record } from "immutable";
import {
  ACCOUNT_GENERAL_INFO_CHANGE_NAME,
  ACCOUNT_GENERAL_INFO_CHANGE_DOMAIN,
} from "../../helpers/constants";
import type { Actions } from "../../actions/accountInfo";

export type DefaultState = {
  name: string,
  domain: string,
  description:string,
  isActive:1
}

const defaultValues: DefaultState = {
  name: "",
  domain: "",
  description: "",
  isActive: 1,
};

export const makeState: RecordFactory<DefaultState> = Record(defaultValues);

export type State = RecordOf<DefaultState>;

export const initialState: State = makeState({});

export function initAccountGeneralInfoState(): State {
  return initialState;
}

// eslint-disable-next-line default-param-last
export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ACCOUNT_GENERAL_INFO_CHANGE_NAME: {
      return state.set("name", action.value);
    }
    case ACCOUNT_GENERAL_INFO_CHANGE_DOMAIN: {
      return state.set("domain", action.value);
    }
    default:
      return state;
  }
};
