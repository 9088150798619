// @flow
import {
  SET_PAGES_LIST,
  GET_PAGES_LIST_SAGA,
  SET_PAGES_LIST_COUNT,
  ADD_SELECTED_PAGE,
  REMOVE_SELECTED_PAGE,
  PAGES_LIST_BULK_DELETE_SAGA,
  ACTIVATE_DOCUMENT_SAGA,
  CHANGE_DOCUMENT_VERSION_STATUS,
  ARCHIVE_PAGE_SAGA,
  PAGES_LIST_SET_PAGE,
  PAGES_LIST_SET_SORTING,
  PAGES_LIST_SET_FILTERS,
  PAGES_LIST_SET_PAGE_SIZE,
} from "../../helpers/constants";

import type {
  Page,
  Filters,
  Sorting,
} from "../../components/PagesList/types/PagesList.types";

export type SetPagesList = {|
  type: typeof SET_PAGES_LIST,
  data: Array<Page>,
|};

export type GetPagesListSaga = {|
  type: typeof GET_PAGES_LIST_SAGA,
|};

export type SetPagesListCount = {|
  type: typeof SET_PAGES_LIST_COUNT,
  count: number,
|};

export type AddSelectedPage = {|
  type: typeof ADD_SELECTED_PAGE,
  selectedPage: string,
|};

export type RemoveSelectedPage = {|
  type: typeof REMOVE_SELECTED_PAGE,
  removedPage: string,
|};

export type PagesListBulkDeleteSaga = {|
  type: typeof PAGES_LIST_BULK_DELETE_SAGA,
  selectedPages: Array<string>,
|};

export type ActivateDocumentSaga = {|
  type: typeof ACTIVATE_DOCUMENT_SAGA,
  documentId: string,
|};

export type ChangeDocumentVersionStatus = {|
  type: typeof CHANGE_DOCUMENT_VERSION_STATUS,
  documentId: string,
  status: string,
|};

export type ArchivePageSaga = {|
  type: typeof ARCHIVE_PAGE_SAGA,
  documentId: string,
|};

export type SetPage = {|
  type: typeof PAGES_LIST_SET_PAGE,
  page: number,
|};

export type SetFilters = {|
  type: typeof PAGES_LIST_SET_FILTERS,
  filters: Object,
|};

export type SetSortings = {|
  type: typeof PAGES_LIST_SET_SORTING,
  sorting: Object,
|};

export type SetPageSize = {|
  type: typeof PAGES_LIST_SET_PAGE_SIZE,
  pageSize: number,
|};

export type Actions =
  | SetPagesList
  | GetPagesListSaga
  | SetPagesListCount
  | AddSelectedPage
  | RemoveSelectedPage
  | PagesListBulkDeleteSaga
  | ChangeDocumentVersionStatus
  | SetPage
  | SetFilters
  | SetSortings
  | SetPageSize;

export const setPagesList = (data: Array<Page>): SetPagesList => ({
  type: SET_PAGES_LIST,
  data,
});

export const getPagesListSaga = (
): GetPagesListSaga => ({
  type: GET_PAGES_LIST_SAGA,
});

export const setPagesListCount = (count: number): SetPagesListCount => ({
  type: SET_PAGES_LIST_COUNT,
  count,
});

export const addSelectedPage = (selectedPage: string): AddSelectedPage => ({
  type: ADD_SELECTED_PAGE,
  selectedPage,
});

export const removeSelectedPage = (
  removedPage: string
): RemoveSelectedPage => ({
  type: REMOVE_SELECTED_PAGE,
  removedPage,
});

export const pagesListBulkDeleteSaga: (
  selectedPages: Array<string>
) => PagesListBulkDeleteSaga = (selectedPages) => ({
  type: PAGES_LIST_BULK_DELETE_SAGA,
  selectedPages,
});

export const activateDocumentSaga: (
  documentId: string
) => ActivateDocumentSaga = (documentId: string) => ({
  type: ACTIVATE_DOCUMENT_SAGA,
  documentId,
});

export const changeDocumentVersionStatus: (
  documentId: string,
  status: string
) => ChangeDocumentVersionStatus = (documentId: string, status: string) => ({
  type: CHANGE_DOCUMENT_VERSION_STATUS,
  documentId,
  status,
});

export const archivePageSaga: (documentId: string) => ArchivePageSaga = (
  documentId
) => ({
  type: ARCHIVE_PAGE_SAGA,
  documentId,
});

export const setPage: (number) => SetPage = (page) => ({
  type: PAGES_LIST_SET_PAGE,
  page,
});

export const setFilters: (filters: Filters) => SetFilters = (filters) => ({
  type: PAGES_LIST_SET_FILTERS,
  filters,
});

export const setSorting: (sorting: Sorting) => SetSortings = (sorting) => ({
  type: PAGES_LIST_SET_SORTING,
  sorting,
});

export const setPageSize: (pageSize: number) => SetPageSize = (pageSize) => ({
  type: PAGES_LIST_SET_PAGE_SIZE,
  pageSize,
});
