// @flow
/* eslint-disable import/max-dependencies, no-console */
import {
  call, put, takeEvery, select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table/actions";
import { BULK_DELETE_LINKING_SAGA, LINKING_LIST } from "../../helpers/constants";
import { getLinkingListSaga } from "../../actions/linkingList";

import linkingApi from "../../services/linkingApi";
import setLoading from "../../actions/loading";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(LINKING_LIST, true));
    const selected = yield select(getTableSelections, LINKING_LIST);

    yield call(linkingApi.bulkDeleteLinking, selected.map((i) => i.id));
    yield put(setTableSelection(LINKING_LIST, []));
    yield put(getLinkingListSaga());

    yield put(addNotification({ message: "Linking deleted", severity: "success" }));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(LINKING_LIST, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(BULK_DELETE_LINKING_SAGA, makeFetch);
}
