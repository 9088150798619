// @flow
/* eslint-disable import/max-dependencies */
import {
  throttle,
  put,
  call,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import type { Response } from "@fas/ui-framework/lib/services/request";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import {
  getPageType,
  getPageInfo,
  getPageResources,
  getPageVersions,
  getDocumentModifiers,
} from "../../selectors/page";
import {
  CREATE_PAGE_SAGA,
  PAGE_TYPES,
  SAVE_PAGE_LOADING,
} from "../../helpers/constants";
import type {
  PAGE_TYPES_OBJECT,
  PAGE_TYPES_KEY_TYPE,
  PAGE_TYPES_TITLE_TYPE,
} from "../../helpers/constants";
import setLoading from "../../actions/loading";
import DocumentApi from "../../services/documentApi";
import { getPageTypeModel } from "../../services/PageTypeFactory";
import PageType from "../../services/PageTypeFactory/PageType";
import type { PageLoadingTypes as LoadingTypes } from "../../actions/loading";
import type { State as PageInfoState } from "../../reducers/pageInfo";
import type {
  State as PageVersionsState,
} from "../../reducers/pageVersions";
import type { Resource } from "../../reducers/pageResources";
import type { PageToSaveType } from "../../containers/FloatingControlButtons/types/FloatingControlButtons.types";
import type { Modifier } from "../../reducers/pageModifiers/reducer";
import { getHashValue } from "../../services/jusCan";
import type { CreatePageSagaAction } from "../../actions/page";

export function* makeFetch({ navigate }: CreatePageSagaAction): Saga<void> {
  try {
    yield put(setLoading<LoadingTypes>(SAVE_PAGE_LOADING, true));

    const pageTypeKey: PAGE_TYPES_KEY_TYPE = yield select(getPageType);
    const pageTypeObject: PAGE_TYPES_OBJECT | void = PAGE_TYPES.find(
      (item: PAGE_TYPES_OBJECT): boolean => item.pageTypeKey === pageTypeKey
    );
    const pageTypeTitle: PAGE_TYPES_TITLE_TYPE = pageTypeObject
      ? pageTypeObject.pageTypeTitle
      : "Page";

    const pageInfo: PageInfoState = yield select(getPageInfo);
    const pageVersions: PageVersionsState = yield select(getPageVersions);
    const pageModifiers: Array<Modifier> = yield select(getDocumentModifiers);
    const pageResources: Array<Resource> = yield select(getPageResources);

    const PageTypeModel: PageType = yield call(getPageTypeModel, pageTypeTitle);
    yield call(
      [PageTypeModel, "preparePageData"],
      pageInfo,
      pageVersions,
      pageModifiers,
      pageResources
    );

    const pageData: PageToSaveType = yield call([PageTypeModel, "getPageData"]);

    const response: Response<
      mixed,
      { data: { pageId: string } }
    > = yield call(DocumentApi.createPage, pageData);
    const {
      data: {
        data: { pageId: newPageId },
      },
    }: Response<mixed, { data: { pageId: string } }> = response;

    yield put(addNotification({ message: "Successfully saved", severity: "success" }));

    navigate(`/pages/edit/${newPageId}?hashId=${getHashValue()}`);
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to save", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading<LoadingTypes>(SAVE_PAGE_LOADING, false));
  }
}

export default function* watch(): Saga<void> {
  yield throttle(1000, CREATE_PAGE_SAGA, makeFetch);
}
