// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import type { Redirect } from "../../containers/RedirectInfo/types/RedirectInfo.type";

type Order = "asc" | "desc";
type Filters = { [key: string]: string };
type Sorting = { [key: string]: Order };

export type Payload = {
  page: number,
  pageSize: number,
  filters: Filters,
  sorting: Sorting,
};

const qs = require("qs");

export const getRedirect = (id: string): PromiseResponse<mixed> => requestService
  .get(environment.endpoints.get.redirect.replace("{id}", id));

export const createRedirect = (data: Redirect): PromiseResponse<mixed> => requestService
  .post(environment.endpoints.post.redirect, data);

export const updateRedirect = (redirectId: string, data: Redirect): PromiseResponse<mixed> => requestService
  .put(environment.endpoints.put.redirect.replace("{id}", redirectId), { data: { ...data } });

export const getRedirectList = (options: Payload): PromiseResponse<mixed> => requestService
  .get(environment.endpoints.get.redirectList, {
    params: { ...options },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "brackets", encode: false }),
  });

export const deleteRedirect = (redirectId: string): PromiseResponse<mixed> => requestService
  .delete(environment.endpoints.delete.redirect, {
    data: {
      data: redirectId,
    },
  });
