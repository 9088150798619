/* eslint-disable import/max-dependencies */
// @flow
import { Container, Box } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Loader } from "@fas/ui-core/lib/Loader/Loader";
import { LoadingButton } from "@fas/ui-core";
import { useNavigate, type NavigateFunction } from "react-router";
import { Settings as SettingsForm } from "../../../components/LinkingInfoForm";
import {
  getSorting,
  getHeight,
  getWidth,
  getAlgorithm,
  getType as getLinkingType,
  getTagsGeneration,
} from "../../../selectors/linking";
import type { SettingsProps } from "../../../components/LinkingInfoForm";
import {
  setLinkingInfoSorting,
  setLinkingInfoWidth,
  setLinkingInfoAlgorithm,
  getLinkingInfoHeightSaga,
  saveLinkingInfoSaga,
} from "../../../actions/linkingList";
import type { GetLinkingInfoHeightSaga, SaveLinkingInfoSaga } from "../../../actions/linkingList";

import { getErrors } from "../../../selectors/errors";
import {
  getIsLinkedItemSavingLoading,
  getIsLinkedItemLoading,
  getIsLinkedItemHeightLoading,
} from "../../../selectors/loading";
import {
  getLinkingSortings,
  getLinkingAlgorithms,
} from "../../../selectors/accountModules";
import type {
  Sorting as LinkingInfoSorting,
  Algorithm as LinkingInfoAlgorithm,
} from "../../../components/LinkingInfoForm/types/LinkingInfoForm.types";

type OwnProps = $ReadOnly<{||}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  ...SettingsProps,
  linkedInfoLoading: boolean,
  tagsGeneration: Array<string>,
  getLinkingInfoHeight: (tag: Array<string>) => GetLinkingInfoHeightSaga,
  saveLoading: boolean,
  onSave: (navigate: NavigateFunction) => SaveLinkingInfoSaga,
  linkingSortings: Array<LinkingInfoSorting>,
  linkingAlgorithms: Array<LinkingInfoAlgorithm>,
|}>;

const mapStateToProps = (state) => ({
  type: getLinkingType(state),
  sorting: getSorting(state),
  width: getWidth(state),
  height: getHeight(state),
  errors: getErrors(state),
  algorithm: getAlgorithm(state),
  tagsGeneration: getTagsGeneration(state),
  isHeightLoading: getIsLinkedItemHeightLoading(state),
  linkingSortings: getLinkingSortings(state),
  linkingAlgorithms: getLinkingAlgorithms(state),
  linkedInfoLoading: getIsLinkedItemLoading(state),
  saveLoading: getIsLinkedItemSavingLoading(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    onWidthChange: setLinkingInfoWidth,
    onSortingChange: setLinkingInfoSorting,
    onAlgorithmChange: setLinkingInfoAlgorithm,
    getLinkingInfoHeight: getLinkingInfoHeightSaga,
    onSave: saveLinkingInfoSaga,
  },
  dispatch
);
function Settings({
  sorting,
  width,
  height,
  onWidthChange,
  onSortingChange,
  errors,
  linkedInfoLoading,
  onAlgorithmChange,
  algorithm,
  type,
  tagsGeneration,
  getLinkingInfoHeight,
  isHeightLoading,
  linkingSortings,
  linkingAlgorithms,
  saveLoading,
  onSave,
}: Props) {
  useEffect(() => {
    getLinkingInfoHeight(tagsGeneration);
  }, [getLinkingInfoHeight, tagsGeneration]);

  const navigate = useNavigate();
  return (
    <Container>
      <Loader loading={linkedInfoLoading}>
        <SettingsForm
          type={type}
          sorting={sorting}
          width={width}
          height={height}
          onWidthChange={onWidthChange}
          onSortingChange={onSortingChange}
          onAlgorithmChange={onAlgorithmChange}
          algorithm={algorithm}
          isHeightLoading={isHeightLoading}
          // $FlowFixMe
          errors={errors.toJS()}
          sortings={linkingSortings}
          algorithms={linkingAlgorithms}
        />
        <Box mt={3} display="flex" justifyContent="flex-end">
          <LoadingButton
            sx={{
              ml: 1,
            }}
            type="button"
            size="medium"
            variant="contained"
            color="primary"
            loading={saveLoading}
            onClick={() => onSave(navigate)}
            data-testid="next-button"
          >
            Save
          </LoadingButton>
        </Box>
      </Loader>
    </Container>
  );
}

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
