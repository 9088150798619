// @flow
import type { OutputSelector } from "reselect";
import { createSelector } from "reselect";
import type { State as LoadingState } from "../../reducers/loading";
import type { State } from "../../pages/AppStoreWrapper";
import {
  ACCOUNT_LIST,
  SAVE_REDIRECT_LOADING,
  GET_REDIRECT_LOADING,
  GET_LINKING_LOADING,
  SAVE_LINKING_LOADING,
  ACCOUNT_MODULES_LOADING,
  GET_PAGE_LOADING,
  SAVE_PAGE_LOADING,
  GET_PAGE_LIST_LOADING,
  GET_ACCOUNT_LOADING,
  SAVE_ACCOUNT_LOADING,
  PAGE_EXPORTING_LOADING,
  PAGE_IMPORTING_LOADING,
  PAGE_CLONING_LOADING,
  HEIGHT_LOADING,
  DOWNLOAD_PAGE_RESOURCES_LOADING,
} from "../../helpers/constants";

type CommonState = State;

export const getIsPageLoading: OutputSelector<CommonState, *, boolean> = createSelector(
  (state) => state.loading,
  (loading) => loading.get(GET_PAGE_LOADING, false)
);

export const getIsPageListLoading: OutputSelector<State, *, boolean> = createSelector(
  (state) => state.loading.get(GET_PAGE_LIST_LOADING, false),
  (loading) => loading
);

export const getIsSavePageLoading: OutputSelector<State, *, boolean> = createSelector(
  (state: State): LoadingState<string> => state.loading,
  (loading: LoadingState<string>): boolean => loading.get(SAVE_PAGE_LOADING, false)
);

export const getIsAccountLoading: OutputSelector<State, *, boolean> = createSelector(
  (state) => state.loading.get(GET_ACCOUNT_LOADING, false),
  (loading) => loading
);

export const getIsSaveAccountLoading: OutputSelector<State, *, boolean> = createSelector(
  (state) => state.loading.get(SAVE_ACCOUNT_LOADING, false),
  (loading) => loading
);

export const getIsRedirectLoading: OutputSelector<State, *, boolean> = createSelector(
  (state) => state.loading.get(GET_REDIRECT_LOADING, false),
  (loading) => loading
);

export const getIsSaveRedirectLoading: OutputSelector<State, *, boolean> = createSelector(
  (state) => state.loading.get(SAVE_REDIRECT_LOADING, false),
  (loading) => loading
);

export const getIsPageExporting: OutputSelector<State, *, boolean> = createSelector(
  (state) => state.loading.get(PAGE_EXPORTING_LOADING, false),
  (exporting) => exporting
);

export const getIsPageImporting: OutputSelector<State, *, boolean> = createSelector(
  (state) => state.loading.get(PAGE_IMPORTING_LOADING, false),
  (importing) => importing
);

export const getIsClonePageLoading: OutputSelector<State, *, boolean> = createSelector(
  (state) => state.loading.get(PAGE_CLONING_LOADING, false),
  (cloning) => cloning
);

export const getIsLinkedItemSavingLoading: OutputSelector<State, *, boolean> = createSelector(
  (state) => state.loading.get(SAVE_LINKING_LOADING, false),
  (linkingList) => linkingList
);

export const getIsLinkedItemLoading: OutputSelector<State, *, boolean> = createSelector(
  (state) => state.loading.get(GET_LINKING_LOADING, false),
  (linkingList) => linkingList
);

export const getIsLinkedItemHeightLoading: OutputSelector<State, *, boolean> = createSelector(
  (state) => state.loading.get(HEIGHT_LOADING, false),
  (loading) => loading
);

export const getIsAccountModulesLoading: OutputSelector<State, *, boolean> = createSelector(
  (state) => state.loading.get(ACCOUNT_MODULES_LOADING, false),
  (loading) => loading
);

export const getAccountsListLoading: OutputSelector<State, *, boolean> = createSelector(
  (state: State) => state.loading,
  (loadingList: LoadingState<string>) => loadingList.get(ACCOUNT_LIST, false)
);

export const getIsResourceDownloading: OutputSelector<State, *, boolean> = createSelector(
  (state: State) => state.loading,
  (loadingList: LoadingState<string>) => loadingList.get(DOWNLOAD_PAGE_RESOURCES_LOADING, false)
);
