// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type { State as ErrorsState } from "@fas/ui-framework/lib/redux/reducers/errors/reducer";

import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";
import type { State } from "../../pages/AppStoreWrapper";

export const getErrors: OutputSelector<State, *, ErrorsState> = createSelector(
  (state: State): ErrorsState => state.errors,
  (errors: ErrorsState): ErrorsState => errors
);

export const getErrorsObject: OutputSelector<State, *, ErrorsMapper> = createSelector(
  (state: State): ErrorsState => state.errors,
  // $FlowFixMe
  (errors: ErrorsMapper): ErrorsMapper => errors.toJS()
);
