// @flow
import React from "react";
import { SetupCard } from "@fas/ui-core";
import {
  FormControl,
  InputAdornment,
  TextField,
  Tooltip,
  Box,
  Grid,
  FormHelperText,
  Switch,
  FormControlLabel,
  Divider,
} from "@mui/material";
import { HelpOutline } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";

import type { TextField as TextFieldType } from "@mui/material";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";
import {
  hasError,
  getErrorMessage,
} from "@fas/ui-framework/lib/services/generators";
import CodeEditor from "../../CodeEditor";
import type { GeneralInfo as GeneralInfoType, Types } from "../types/LinkingInfoForm.types";
import TagsAutocomplete from "../../TagsAutocomplete";

export type Props = {|
  ...GeneralInfoType,
  onTypeChange: (val: string) => void,
  onNameChange: (val: string) => void,
  onTagsGenerationChange: (val: Array<string>) => void,
  onTagsDisplayChange: (val: Array<string>) => void,
  onTemplateCodeChange: (val: string) => void,
  onUsePaginationChange: (val: boolean) => void,
  errors: ErrorsMapper,
  tagsGeneration?: Array<string>,
  tagsDisplay?: Array<string>,
  types: Array<Types>,
|};

function GeneralInfo({
  type,
  onTypeChange,
  name,
  onNameChange,
  tagsGeneration,
  onTagsGenerationChange,
  tagsDisplay,
  onTagsDisplayChange,
  templateCode,
  onTemplateCodeChange,
  usePagination,
  onUsePaginationChange,
  errors,
  types,
}: Props) {
  return (
    <SetupCard title="General Info">
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            variant="outlined"
            error={hasError("type", errors)}
          >
            <Autocomplete
              id="type"
              options={types}
              value={type}
              size="small"
              getOptionLabel={(x) => x}
              onChange={(_, value) => onTypeChange(value)}
              renderInput={(params): TextFieldType => (
                <TextField
                  {...params}
                  label="Linking type"
                  variant="outlined"
                  margin="normal"
                  error={hasError("type", errors)}
                  InputProps={{
                    "data-testid": "type",
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Tooltip title="The type of your linking. Notice! You can
                            choose &quot;Linking Type&quot; only once with linking creation.
                            After linking creation, you can't change it."
                        >
                          <HelpOutline />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <FormHelperText>{getErrorMessage("type", errors)}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={hasError("name", errors)}>
            <TextField
              label="Name"
              margin="normal"
              variant="outlined"
              value={name}
              size="small"
              error={hasError("name", errors)}
              onChange={({ target: { value } }) => onNameChange(value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title="It's the name of your linking and the ID of your linking. Notice!
                      You can choose linking name only once with linking creation. If you change the linking name - your
                      linking on &quot;Template&quot; pages will be broked."
                    >
                      <HelpOutline />
                    </Tooltip>
                  </InputAdornment>
                ),
                "data-testid": "name",
              }}
            />
            <Box ml={2}>
              <FormHelperText>{getErrorMessage("name", errors)}</FormHelperText>
            </Box>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            variant="outlined"
            error={hasError("tagsGeneration", errors)}
            data-testid="generation-tags"
          >
            <TagsAutocomplete
              id="generation-tags"
              multiple
              label="Generation tags"
              tooltipText="Pages with &quot;Generation Tags&quot; will be linked to each other or
                will be displayed on pages with &quot;Display Tags&quot; for &quot;Linking Type&quot;
                &quot;List&quot;."
              value={tagsGeneration}
              onChange={(_, value) => onTagsGenerationChange(value)}
              error={hasError("tagsGeneration", errors)}
            />
            <FormHelperText>
              {getErrorMessage("tagsGeneration", errors)}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            variant="outlined"
            error={hasError("tagsGeneration", errors)}
            data-testid="display-tags"
          >
            <TagsAutocomplete
              id="display-tags"
              multiple
              label="Display Tags"
              value={tagsDisplay}
              tooltipText="This tag needs only for Linking Type &quot;List&quot;. Pages with &quot;Generation Tags&quot;
                will be displayed on pages with &quot;Display Tags&quot;."
              onChange={(_, value) => onTagsDisplayChange(value)}
              error={hasError("tagsDisplay", errors)}
              disabled={type === "matrix"}
            />
            <FormHelperText>
              {getErrorMessage("tagsDisplay", errors)}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            variant="outlined"
            label="Template"
            data-testid="html-editor-template-code"
          >
            <FormHelperText>Template code</FormHelperText>

            <CodeEditor
              height="350px"
              language="html"
              theme="dark"
              value={templateCode}
              onChange={onTemplateCodeChange}
              name="html"
            />
          </FormControl>
        </Grid>

        {type === "list" && (
          <Grid item xs={12} mt={2}>
            <Divider />

            <FormControl
              variant="outlined"
              data-testid="use-pagination-switch"
            >
              <FormControlLabel
                control={(
                  <Switch
                    color="primary"
                    checked={usePagination}
                    onClick={() => onUsePaginationChange(!usePagination)}
                  />
                )}
                label="Use Pagination"
                labelPlacement="start"
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
    </SetupCard>
  );
}

// $FlowFixMe
GeneralInfo.defaultProps = {
  tagsGeneration: [],
  tagsDisplay: [],
};

export default React.memo<Props>(GeneralInfo);
