// @flow
import {
  call,
  put,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table/actions";
import { ARCHIVE_PAGE_SAGA, PAGES_LIST } from "../../helpers/constants";
import type { ArchivePageSaga } from "../../actions/pages";
import { archivePage } from "../../services/pagesApi";
import { getPagesListSaga } from "../../actions/pages/actions";

export function* makeFetch(action: ArchivePageSaga): Saga<void> {
  try {
    const { documentId } = action;

    yield call(archivePage, documentId);

    yield put(addNotification({ message: "Page archived", severity: "success" }));
    yield put(getPagesListSaga());
    yield put(setTableSelection(PAGES_LIST, []));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to archive page", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(ARCHIVE_PAGE_SAGA, makeFetch);
}
