// @flow
import type { Saga } from "redux-saga";
import {
  call, put,
  takeEvery,
} from "redux-saga/effects";
import FileSaver from "file-saver";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { EXPORT_PAGE_SAGA, PAGE_EXPORTING_LOADING } from "../../helpers/constants";
import type { StartExportPage } from "../../actions/pageActions/actions";
import { exportPage } from "../../services/documentApi/document";
import { closeExportPageDialog } from "../../actions/pageActions";
import setLoading from "../../actions/loading/actions";
import type { PageExportingTypes as LoadingTypes } from "../../actions/loading/actions";

export function* makeFetch(action: StartExportPage): Saga<void> {
  try {
    yield put(setLoading<LoadingTypes>(PAGE_EXPORTING_LOADING, true));
    const response = yield call(exportPage, action.exportType, action.ids, action.pageType);

    const contentType: string = response.headers["content-type"] || response.headers["Content-Type"];

    const disposition = response.headers["content-disposition"] || response.headers["Content-Disposition"];
    const startIndex = disposition.indexOf("filename=\"") + "filename=\"".length;
    const endIndex = disposition.length - 1;
    const filename = disposition.substring(startIndex, endIndex);
    const blob = new Blob([response.data], { type: contentType });
    FileSaver.saveAs(blob, filename);

    yield put(closeExportPageDialog());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to export", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading<LoadingTypes>(PAGE_EXPORTING_LOADING, false));
  }
}

export default function* clonePageSaga(): Saga<void> {
  yield takeEvery(EXPORT_PAGE_SAGA, makeFetch);
}
