/* eslint-disable import/prefer-default-export */
// @flow
import environment from "environment";
import qs from "qs";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "./request";

export const getPagesList = async (options: {
  page: number,
  pageSize: number,
  filters: Object, // FIXME:  unified approach should be ensured: "filter" or "filters"; decide with backoffice
  sorting: Object,
// eslint-disable-next-line max-len
}): PromiseResponse<*, { data: { pagesList: Array<*>, count: number }}> => requestService.get(environment.endpoints.get.documentsList, {
  params: { ...options },
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "brackets", encode: false }),
});

export const archivePage = (documentId: string): PromiseResponse<mixed> => requestService
  .post(environment.endpoints.post.archivePage.replace("{documentId}", documentId), {});

export const bulkView = (previewData: Array<string>): PromiseResponse<mixed> => requestService
  .get(environment.endpoints.get.documentsViewAll, {
    params: { previewData },
  });

export const bulkDelete = (id: Array<string>): PromiseResponse<mixed> => requestService
  .delete(environment.endpoints.delete.documentsAll, {
    data: { id },
  });
