// @flow
import type { Saga } from "redux-saga";
import {
  call, put,
  takeEvery,
} from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { CLONE_PAGE_SAGA, PAGE_CLONING_LOADING } from "../../helpers/constants";
import type { StartClonePage } from "../../actions/pageActions/actions";
import { clonePage } from "../../services/documentApi/document";
import { getPagesListSaga } from "../../actions/pages/actions";
import { closeClonePageDialog } from "../../actions/pageActions";
import setLoading from "../../actions/loading";

export function* makeFetch(action: StartClonePage): Saga<void> {
  try {
    yield put(setLoading(PAGE_CLONING_LOADING, true));

    const data: {
      pageId: string,
      pageName: string,
      accountId: string,
      alias?: string,
    } = {
      pageId: action.pageId,
      pageName: action.pageName,
      accountId: action.accountId,
    };
    if (action.pageUrl) {
      data.alias = action.pageUrl;
    }

    yield call(clonePage, data);
    yield put(addNotification({ message: "Successfuly saved", severity: "success" }));
    yield put(setLoading(PAGE_CLONING_LOADING, false));

    yield put(closeClonePageDialog());

    yield put(getPagesListSaga());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to save", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
    yield put(setLoading(PAGE_CLONING_LOADING, false));
  }
}

export default function* clonePageSaga(): Saga<void> {
  yield takeEvery(CLONE_PAGE_SAGA, makeFetch);
}
