// @flow

import {
  Avatar, Grid, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, Typography,
} from "@mui/material";
import { ComboBox } from "@fas/ui-core";
import { Clear as ClearIcon } from "@mui/icons-material";
import React from "react";

const rolesList = ["owner", "manager", "coder"];
const capitalize = (str: string = "") => str.toLowerCase()
  .split(" ")
  .map((s) => s.charAt(0).toUpperCase())
  .join("");

type Props = {
  user: {
    id: string,
    username: string,
    role?: string,
  },
  onChangeRole: (string, string) => void,
  onRemoveUser: (string) => void,
  isError?: boolean
}

function User({
  user, onChangeRole, onRemoveUser, isError = false,
}: Props) {
  const { id, username, role = null } = user;
  const handleChangeRole = (e, value) => {
    onChangeRole(id, value);
  };
  const handleRemoveUser = () => {
    onRemoveUser(id);
  };
  return (
    <ListItem key={id} button>
      <ListItemAvatar>
        <Avatar alt={username}>{capitalize(username)}</Avatar>
      </ListItemAvatar>
      <Grid container direction="row">
        <Grid container item xs={9} alignItems="center">
          <Typography>{username}</Typography>
        </Grid>
        <Grid item xs={2}>
          <ComboBox
            id={`users-list-roles-${id}`}
            label="Role"
            options={rolesList}
            value={role}
            defaultValue={role}
            onChange={handleChangeRole}
            disableClearable
            data-testid="user-roles"
            error={{
              isError,
              helperText: "Select role",
            }}
          />
        </Grid>
      </Grid>
      <ListItemSecondaryAction>
        {
          role && (
            role.toLowerCase() !== "owner" && (
              <IconButton onClick={handleRemoveUser} data-testid="users-list-remove-button">
                <ClearIcon />
              </IconButton>
            )
          )
        }
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default User;
