/* eslint-disable import/max-dependencies */
// @flow
import { Container } from "@mui/material";
import React, { useEffect, type Node } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { bindActionCreators } from "redux";
import debounce from "lodash.debounce";
import { Loader } from "@fas/ui-core/lib/Loader/Loader";
import { getErrors } from "../../../selectors/errors";
import {
  getLinkingInfoSaga,
  setLinkingInfoName,
  setLinkingInfoType,
  setLinkingInfoTagsGeneration,
  setLinkingInfoTagsDisplay,
  setLinkingInfoTemplateCode,
  setLinkingInfoUsePagination,
} from "../../../actions/linkingList";
import type { GetLinkingInfoSaga } from "../../../actions/linkingList";
import { GeneralInfo as GeneralInfoForm } from "../../../components/LinkingInfoForm";
import type { GeneralInfoProps } from "../../../components/LinkingInfoForm";
import { setBackup, restoreBackup } from "../../../actions/backup";
import type { SetBackupAction, RestoreBackupAction } from "../../../actions/backup";
import {
  getName as getLinkingName,
  getType as getLinkingType,
  getTagsGeneration,
  getTagsDisplay,
  getTemplateCode,
  getLinkingInfoUsePagination,
} from "../../../selectors/linking";
import {
  getIsLinkedItemLoading,
  getIsAccountModulesLoading,
} from "../../../selectors/loading";
import { getAccountsModulesSaga } from "../../../actions/accountModules";
import { getLinkingTypes } from "../../../selectors/accountModules";
import type {
  Types as LinkingInfoTypes,
} from "../../../components/LinkingInfoForm/types/LinkingInfoForm.types";
import { linkingBackupStore } from "../../../pages/AppStoreWrapper/store";

type OwnProps = $ReadOnly<{||}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  ...GeneralInfoProps,
  getLinkingInfo: (id: string) => GetLinkingInfoSaga,
  linkedInfoLoading: boolean,
  accountModulesLoading: boolean,
  linkingTypes: Array<LinkingInfoTypes>,
  onSetBackup: (backup: Array<string>) => SetBackupAction,
  onRestoreBackup: () => RestoreBackupAction,
|}>;

const mapStateToProps = (state) => ({
  name: getLinkingName(state),
  type: getLinkingType(state),
  tagsGeneration: getTagsGeneration(state),
  tagsDisplay: getTagsDisplay(state),
  templateCode: getTemplateCode(state),
  errors: getErrors(state),
  linkedInfoLoading: getIsLinkedItemLoading(state),
  accountModulesLoading: getIsAccountModulesLoading(state),
  linkingTypes: getLinkingTypes(state),
  usePagination: getLinkingInfoUsePagination(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getLinkingInfo: getLinkingInfoSaga,
    onNameChange: setLinkingInfoName,
    onTypeChange: setLinkingInfoType,
    onTagsGenerationChange: setLinkingInfoTagsGeneration,
    onTagsDisplayChange: setLinkingInfoTagsDisplay,
    onTemplateCodeChange: setLinkingInfoTemplateCode,
    getAccountModules: getAccountsModulesSaga,
    onUsePaginationChange: setLinkingInfoUsePagination,
    onSetBackup: setBackup,
    onRestoreBackup: restoreBackup,
  },
  dispatch
);

function GeneralInfo({
  errors,
  getLinkingInfo,
  linkedInfoLoading,
  accountModulesLoading,
  name,
  onNameChange,
  onTagsDisplayChange,
  onTagsGenerationChange,
  onTemplateCodeChange,
  onTypeChange,
  tagsDisplay,
  tagsGeneration,
  templateCode,
  usePagination,
  onUsePaginationChange,
  type,
  linkingTypes,
  onSetBackup,
  onRestoreBackup,
}: Props): Node {
  const { id } = useParams();

  useEffect(() => {
    onSetBackup(linkingBackupStore);
    if (id) getLinkingInfo(id);
    return () => {
      onRestoreBackup();
    };
  }, [getLinkingInfo, id]);

  const debouncedCodeEditorChange = debounce(onTemplateCodeChange, 600);
  const handleCodeEditorChange: (string) => void = (value) => {
    debouncedCodeEditorChange(value);
  };

  return (
    <Container>
      <Loader loading={linkedInfoLoading || accountModulesLoading}>
        <GeneralInfoForm
          name={name}
          type={type}
          tagsGeneration={tagsGeneration}
          tagsDisplay={tagsDisplay}
          templateCode={templateCode}
          onNameChange={onNameChange}
          onTypeChange={onTypeChange}
          onTagsDisplayChange={onTagsDisplayChange}
          onTagsGenerationChange={onTagsGenerationChange}
          onTemplateCodeChange={handleCodeEditorChange}
          // $FlowFixMe
          errors={errors.toJS()}
          types={linkingTypes}
          usePagination={usePagination}
          onUsePaginationChange={onUsePaginationChange}
        />
      </Loader>
    </Container>
  );
}

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(GeneralInfo);
