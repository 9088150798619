// @flow
import { Record } from "immutable";
import type { RecordFactory, RecordOf } from "immutable";
import {
  OPEN_EXPORT_PAGE_DIALOG,
  CLOSE_EXPORT_PAGE_DIALOG,
} from "../../helpers/constants";
import type { Actions } from "../../actions/pageActions";
import type { PageTypes } from "../../components/ExportPageFd/types/ExportPageFd.types";

export type Page = { id: string, name: string, type: PageTypes };

export type DefaultState = {
  isOpen: boolean,
  isExporting: boolean,
  loading: boolean,
  selectedPage: Page,
}

const defaultValues: DefaultState = {
  isOpen: false,
  isExporting: false,
  loading: false,
  selectedPage: {
    id: "",
    name: "",
    type: "",
  },
};

export const makeState: RecordFactory<DefaultState> = Record(defaultValues);

export type State = RecordOf<DefaultState>;

export const initialState: State = makeState({});

export function initExportPageState(): State {
  return initialState;
}

// eslint-disable-next-line default-param-last
export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case OPEN_EXPORT_PAGE_DIALOG: {
      return state.withMutations((newState) => {
        newState.set("isOpen", true);
        newState.setIn(["selectedPage"], {
          id: action.pageId,
          name: action.pageName,
          type: action.pageType,
        });
      });
    }
    case CLOSE_EXPORT_PAGE_DIALOG: {
      return state.withMutations((newState) => {
        newState.set("isOpen", false);
        newState.setIn(["selectedPage"], {
          id: "",
          name: "",
          type: "",
        });
      });
    }
    default:
      return state;
  }
};
