// @flow
import PageType, * as PageTypeModels from "./PageType";

// eslint-disable-next-line import/prefer-default-export
export const getPageTypeModel: (
  pageType: string
) => PageType = (
  pageType: string
): PageType => {
  switch (pageType) {
    case "Page":
      return new PageTypeModels.Page();
    case "Custom":
      return new PageTypeModels.Custom();
    case "Template":
      return new PageTypeModels.Template();
    case "Pft":
      return new PageTypeModels.Pft();
    default:
      return new PageType();
  }
};
