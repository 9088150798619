/* eslint-disable react/require-default-props */
/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import {
  Table,
  SelectSearchComponent,
  ShowMoreItems,
} from "@fas/ui-core";
import { Link, useNavigate } from "react-router-dom";
import type { TableProps } from "@fas/ui-framework/lib/services/table";
import { useTable } from "@fas/ui-framework/lib/services/table";
import { useDispatch } from "react-redux";
import { PAGES_LIST } from "../../helpers/constants";
import PagesActions from "./PagesActions";
import {
  EditGeneralAction,
  PreviewAction,
  CloneAction,
  ExportAction,
  ActivateAction,
} from "./PagesActionsButton";
import { getPagesListSaga } from "../../actions/pages/actions";
import { getHashValue } from "../../services/jusCan";

const columns = [
  {
    field: "id",
    label: "ID",
    sortable: false,
    searchable: false,
    render: ({ id, documentId }) => (
      <Link to={`/pages/edit/${documentId}?hashId=${getHashValue()}`}>
        {id}
      </Link>
    ),
  },
  {
    field: "name",
    label: "Name",
    sortable: true,
    searchable: true,
  },
  {
    field: "url",
    label: "Url",
    sortable: false,
    searchable: false,
    render: (row) => {
      const last = row.aliasCollection[row.aliasCollection.length - 1];
      return last && last.alias;
    },
  },
  {
    field: "type",
    label: "Type",
    sortable: true,
    searchable: true,
    SearchComponent: (searchProps) => (
      <SelectSearchComponent
        {...searchProps}
        values={[
          { value: "", title: "Choose type" },
          { value: "template", title: "Template" },
          { value: "page", title: "Page" },
          { value: "pft", title: "PFT" },
          { value: "custom", title: "Custom" },
        ]}
      />
    ),
  },
  {
    field: "status",
    label: "Status",
    sortable: false,
    searchable: false,
    render: (row) => {
      const isNew = row.versionCollection.every(
        ({ version }) => version === "new"
      );
      return isNew ? "New" : "Active";
    },
  },
  {
    field: "version",
    label: "Version",
    sortable: false,
    searchable: false,
    render: (row) => {
      const versions = row.versionCollection.map(({ version }) => version);
      let version = "";
      if (
        (versions.includes("new") && versions.includes("current"))
        || versions.includes("new")
      ) {
        version = "Edit";
      }
      else if (versions.includes("current")) {
        version = "Published";
      }
      return version;
    },
  },
  {
    field: "tagCollection",
    label: "Tags",
    sortable: false,
    searchable: false,
    render: (row) => (<ShowMoreItems data={row.tagCollection.map((i) => i.tag)} />),
  },
  {
    field: "updatedAt",
    label: "Last edit",
    sortable: false,
    searchable: false,
  },
  {
    field: "author",
    label: "Author",
    sortable: false,
    searchable: false,
    render: (row) => {
      const item = row.versionCollection[row.versionCollection.length - 1];
      return (item && item.lastEditor) || "None";
    },
  },
  {
    field: "actions",
    label: "",
    sortable: false,
    searchable: false,
    render: (row) => (
      <>
        <EditGeneralAction item={row} />
        <PreviewAction item={row} />
        <CloneAction item={row} />
        <ExportAction item={row} />
        <ActivateAction item={row} />
      </>
    ),
  },
];

function PagesList() {
  const dispatch: <A>(A) => A = useDispatch();
  const navigate = useNavigate();

  const { onDeselectAll, ...tableProps }: TableProps = useTable(PAGES_LIST);
  const onLoad: () => mixed = () => dispatch(getPagesListSaga());
  const onCreate: () => mixed = () => navigate(`/pages/create?hashId=${getHashValue()}`);

  return (
    <Table
      {...tableProps}
      title="Pages"
      columns={columns}
      onLoad={onLoad}
      onCreate={onCreate}
      rowSelectAvailable={() => true}
      allSelectAvailable={() => true}
      Actions={<PagesActions />}
      onDeselectAll={() => onDeselectAll([])}
    />
  );
}

export default PagesList;
