// @flow
import type { Version } from "../reducers/pageVersions";
import type { Resource } from "../reducers/pageResources";

function resourcesNotExist(content: string, resources: Array<Resource>): Array<string> {
  const resourceLinks: Array<string> = content.match(/{{resource:(.*?)}}/g) || [];

  return resourceLinks.filter((resourceLink) => {
    const resourceName: string = resourceLink.replace("{{resource:", "").replace("}}", "");
    return !resources.find((resource) => resource.name === resourceName);
  });
}

export default function validateResources(
  version: Version,
  resources: Array<Resource>
): Array<string> {
  const validateBlocks = ["html", "css", "js"];

  return Object.keys(version).reduce((acc: Array<string>, key: string): Array<string> => {
    if (validateBlocks.includes(key)) {
      return [
        ...acc,
        ...resourcesNotExist(version[key], resources),
      ];
    }
    return acc;
  }, []);
}
