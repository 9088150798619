// @flow
import React, { useCallback } from "react";
import {
  Box, FormControl, Grid, TextField,
} from "@mui/material";
import { hasError } from "@fas/ui-framework/lib/services/generators";
import debounce from "lodash.debounce";
import Title from "./Title";
import type { RowRenderProps } from "./types/PageModifiers.types";

function SimpleInputRender({
  title,
  value,
  description,
  placeholder,
  modifier,
  errors,
  onChange,
  inputProps,
  multiline,
  dataTestId,
}: RowRenderProps) {
  const debouncedChange = useCallback(debounce(onChange, 600), [onChange]);
  return (
    <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
      <Grid item xs={12}>
        <Title
          title={title}
          description={description}
        />
        <Box width={1} mb={2}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              placeholder={placeholder}
              id={`mui-textfield-modifier-${modifier}`}
              variant="outlined"
              size="small"
              defaultValue={value}
              onChange={(e) => debouncedChange(modifier, e.target.value)}
              onBlur={(e) => {
                if (e.target.value !== value) {
                  debouncedChange.cancel();
                  onChange(modifier, e.target.value);
                }
              }}
              error={hasError(modifier, errors)}
              inputProps={inputProps}
              data-testid={dataTestId}
              multiline={multiline}
            />
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
}

export default SimpleInputRender;
