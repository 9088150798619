// @flow
import qs from "qs";
import environment from "environment";
import type { CancelToken } from "axios";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import type { LinkingInfo } from "../../components/LinkingInfoForm/types/LinkingInfoForm.types";

export const getLinkingList = (options: {
  filters: Object,
  sorting: Object,
}): PromiseResponse<mixed> => requestService.get(environment.endpoints.get.linkingList, {
  params: { ...options },
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "brackets", encode: false }),
});

export const getLinkingInfo = (id: string): PromiseResponse<mixed> => requestService
  .get(environment.endpoints.get.linking.replace("{linkingId}", id));

export default getLinkingList;
export const deleteLinking = (id: string): PromiseResponse<mixed> => requestService
  .delete(environment.endpoints.delete.linking.replace("{linkingId}", id));

export const bulkDeleteLinking = (deleteIds: Array<string>): PromiseResponse<mixed> => requestService
  .delete(environment.endpoints.delete.linkingBulk, {
    data: { deleteIds },
  });

export const createLinkingInfo = (item: LinkingInfo): PromiseResponse<mixed> => requestService
  .post(environment.endpoints.post.linking, item);

export const updateLinkingInfo = (id: string, item: LinkingInfo): PromiseResponse<mixed> => requestService
  .put(environment.endpoints.put.linking.replace("{linkingId}", id), item);

export const getTags = (
  // eslint-disable-next-line default-param-last
  name: string = "",
  cancelToken?: CancelToken
): PromiseResponse<mixed> => requestService.get(environment.endpoints.get.tags, {
  cancelToken,
  params: { filters: { name }, limit: 100 },
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "brackets", encode: false }),
});

export const getHeight = async (tag: Array<string>): PromiseResponse<mixed> => requestService
  .get(environment.endpoints.get.documentsList, {
    params: { filters: { tag, type: "pft" }, page: 1, pageSize: 1 },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "brackets", encode: false }),
  });
