// @flow
import {
  call,
  put,
  takeEvery,
} from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import type { Saga } from "redux-saga";
import { GET_ACCOUNT_SAGA, GET_ACCOUNT_LOADING } from "../../helpers/constants";
import setLoading from "../../actions/loading";
import { changeAccountName, changeAccountDomain } from "../../actions/accountInfo";
import { changeAccountUsers } from "../../actions/accountUsers/actions";
import { getAccount } from "../../services/accountApi/account";
import type { GetAccountSaga } from "../../actions/editAccount/actions";
import type { AccountLoadingTypes as LoadingTypes } from "../../actions/loading";

export function* makeFetch(action: GetAccountSaga): Saga<void> {
  try {
    yield put(setLoading<LoadingTypes>(GET_ACCOUNT_LOADING, true));
    const response = yield call(getAccount, action.accountId);
    const {
      data: {
        account: {
          name,
          domain,
          users,
        },
      },
    } = response.data;

    yield put(changeAccountName(name));
    yield put(changeAccountDomain(domain));
    yield put(changeAccountUsers(users.map((x) => {
      const { name: userName, ...rest } = x;
      return { ...rest, username: userName };
    })));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch an account", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading<LoadingTypes>(GET_ACCOUNT_LOADING, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_ACCOUNT_SAGA, makeFetch);
}
