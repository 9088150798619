// @flow
/* eslint-disable import/max-dependencies */
import {
  throttle,
  put,
  call,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import type { Response } from "@fas/ui-framework/lib/services/request";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import {
  getPageId,
  getPageType,
  getPageInfo,
  getPageResources,
  getPageVersions,
  getDocumentModifiers,
} from "../../selectors/page";
import {
  SAVE_PAGE_SAGA,
  PAGE_TYPES,
  GET_PAGE_VERSION_PREVIEW,
  SAVE_PAGE_LOADING,
} from "../../helpers/constants";
import type {
  PAGE_TYPES_OBJECT,
  PAGE_TYPES_KEY_TYPE,
  PAGE_TYPES_TITLE_TYPE,
} from "../../helpers/constants";
import setLoading from "../../actions/loading";
import { makeFetch as getPageVerisionPreview } from "../getPageVersionPreviewSaga";
import DocumentApi from "../../services/documentApi";
import { getPageTypeModel } from "../../services/PageTypeFactory";
import PageType from "../../services/PageTypeFactory/PageType";
import { activateDocumentSaga as activatePageAction } from "../../actions/pages";
import { makeFetch as activatePageSaga } from "../activatePageSaga/activatePageSaga";
import type { SavePageSagaAction } from "../../actions/page";
import type { PageLoadingTypes as LoadingTypes } from "../../actions/loading";
import type { State as PageInfoState } from "../../reducers/pageInfo";
import type {
  State as PageVersionsState,
  IncomingPageVersionState,
} from "../../reducers/pageVersions";
import type { Resource } from "../../reducers/pageResources";
import type { PageToSaveType } from "../../containers/FloatingControlButtons/types/FloatingControlButtons.types";
import type { Modifier } from "../../reducers/pageModifiers/reducer";
import validateResources from "../../helpers/validateCodeEditor";

export function* makeFetch(action: SavePageSagaAction): Saga<void> {
  try {
    yield put(setLoading<LoadingTypes>(SAVE_PAGE_LOADING, true));
    const { isPreviewEnabled, activateAfterSave }: SavePageSagaAction = action;
    const pageId: string = yield select(getPageId);

    const pageTypeKey: PAGE_TYPES_KEY_TYPE = yield select(getPageType);
    const pageTypeObject: PAGE_TYPES_OBJECT | void = PAGE_TYPES.find(
      (item: PAGE_TYPES_OBJECT): boolean => item.pageTypeKey === pageTypeKey
    );
    const pageTypeTitle: PAGE_TYPES_TITLE_TYPE = pageTypeObject
      ? pageTypeObject.pageTypeTitle
      : "Page";

    const pageInfo: PageInfoState = yield select(getPageInfo);
    const pageVersions: PageVersionsState = yield select(getPageVersions);
    const pageModifiers: Array<Modifier> = yield select(getDocumentModifiers);
    const pageResources: Array<Resource> = yield select(getPageResources);
    const notExistResources: Array<string> = yield call(
      validateResources, pageVersions[pageInfo.versionType], pageResources
    );

    if (notExistResources.length) {
      yield put(addNotification({ message: `${notExistResources.join(", ")} not exist in Resources`, severity: "error" }));
      throw new Error("Resource validation failed");
    }

    const PageTypeModel: PageType = yield call(getPageTypeModel, pageTypeTitle);
    yield call(
      [PageTypeModel, "preparePageData"],
      pageInfo,
      pageVersions,
      pageModifiers,
      pageResources
    );
    const pageData: PageToSaveType = yield call([PageTypeModel, "getPageData"]);

    yield call(DocumentApi.editPage, { data: pageData }, pageId);

    if (isPreviewEnabled) {
      const response: Response<
        mixed,
        { result: { versionCollection: IncomingPageVersionState[] } }
      > = yield call(DocumentApi.getPage, pageId);
      const {
        data: {
          result: { versionCollection },
        },
      }: Response<
        mixed,
        { result: { versionCollection: IncomingPageVersionState[] } }
      > = response;
      // $FlowFixMe
      const newVersion: IncomingPageVersionState = versionCollection.find(
        (v: IncomingPageVersionState): boolean => v.version === "new"
      );
      yield* getPageVerisionPreview({
        type: GET_PAGE_VERSION_PREVIEW,
        pageId,
        versionId: newVersion.id,
      });
    }

    if (activateAfterSave) {
      yield* activatePageSaga(activatePageAction(pageId));
    }

    yield put(addNotification({ message: "Successfully saved", severity: "success" }));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to save", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading<LoadingTypes>(SAVE_PAGE_LOADING, false));
  }
}

export default function* watch(): Saga<void> {
  yield throttle(1000, SAVE_PAGE_SAGA, makeFetch);
}
