// @flow

import {
  Skeleton, Card, CardActionArea, CardHeader, CardActions, Fade, IconButton,
} from "@mui/material";
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function AccountSkeletonCard() {
  return (
    <Fade in timeout={1000}>
      <Card
        variant="outlined"
        sx={{
          width: "220px",
          height: "180px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <CardActionArea>
          <CardHeader
            title={<Skeleton variant="text" sx={{ backgroundColor: "rgb(190 194 205 / 80%)" }} />}
            subheader={<Skeleton variant="text" width={150} height={20} sx={{ backgroundColor: "rgb(190 194 205 / 80%)" }} />}
          />
        </CardActionArea>
        <CardActions sx={{ justifyContent: "space-between" }}>
          <Skeleton variant="text" width={50} height={20} sx={{ backgroundColor: "rgb(190 194 205 / 80%)" }} />
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        </CardActions>
      </Card>
    </Fade>
  );
}
export default AccountSkeletonCard;
