// @flow
import React from "react";
import DocumentTitle from "react-document-title";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import EditAccountForm from "../../components/EditAccountForm";

const useStyles = makeStyles((theme) => ({
  mainBackground: {
    background: theme.palette.background.mainBg,
    minHeight: "calc(100% - 64px)",
  },
}));

function EditAccount() {
  const classes = useStyles();
  const params = useParams();
  const accountId = params && params.id;

  return (
    <DocumentTitle title={accountId ? "Update Account" : "Create Account"}>
      <Grid container spacing={0} className={classes.mainBackground}>
        <EditAccountForm />
      </Grid>
    </DocumentTitle>
  );
}

export default EditAccount;
