// @flow
import {
  SET_LOADING,
  ACCOUNT_LIST,
  PAGES_LIST,
  REDIRECT_LIST,
  LINKING_LIST,
  GET_LINKING_LOADING,
  SAVE_REDIRECT_LOADING,
  GET_REDIRECT_LOADING,
  SAVE_LINKING_LOADING,
  GET_PAGE_LIST_LOADING,
  GET_PAGE_LOADING,
  SAVE_PAGE_LOADING,
  ACCOUNT_MODULES_LOADING,
  GET_ACCOUNT_LOADING,
  SAVE_ACCOUNT_LOADING,
  PAGE_EXPORTING_LOADING,
  PAGE_IMPORTING_LOADING,
  HEIGHT_LOADING,
  GET_PAGE_VERSION_PREVIEW_LOADING,
  PAGE_CLONING_LOADING,
} from "../../helpers/constants";

export type AccountLoadingTypes = typeof GET_ACCOUNT_LOADING | typeof SAVE_ACCOUNT_LOADING;
export type AccountListLoadingTypes = typeof ACCOUNT_LIST;
export type PageLoadingTypes = typeof GET_PAGE_LOADING
| typeof GET_PAGE_VERSION_PREVIEW_LOADING | typeof SAVE_PAGE_LOADING | typeof GET_PAGE_LIST_LOADING;
export type PageViewLoadingTypes = typeof GET_PAGE_LOADING;
export type RedirectLoadingTypes = typeof GET_REDIRECT_LOADING | typeof SAVE_REDIRECT_LOADING | typeof REDIRECT_LIST;
export type PageExportingTypes = typeof PAGE_EXPORTING_LOADING;
export type PageImportingTypes = typeof PAGE_IMPORTING_LOADING;
export type PagesListLoading = typeof PAGES_LIST;
export type ClonePageLoadingType = typeof PAGE_CLONING_LOADING;
export type LinkingListLoadingType = typeof LINKING_LIST;
export type LinkingInfoLoadingTypes = typeof GET_LINKING_LOADING | typeof SAVE_LINKING_LOADING | typeof HEIGHT_LOADING;
export type AccountModulesLoadingTypes = typeof ACCOUNT_MODULES_LOADING;
export type PageLinkingLoadingTypes =
  | LinkingListLoadingType
  | AccountModulesLoadingTypes
  | LinkingInfoLoadingTypes
export type PageListLoadingTypes =
  | PageExportingTypes
  | PageImportingTypes
  | PagesListLoading
  | ClonePageLoadingType

export type SetLoadingAction<T> = {|
  type: typeof SET_LOADING,
  key: T,
  value: boolean,
|};

export type Actions<T> =
  SetLoadingAction<T>;

export default <T: string>(key: T, value: boolean): SetLoadingAction<T> => ({
  type: SET_LOADING,
  key,
  value,
});
