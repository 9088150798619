// @flow
import { Map } from "immutable";
import { SET_LOADING } from "../../helpers/constants";
import type { Actions, SetLoadingAction } from "../../actions/loading";

export type DefaultState<T> = {
  [key: T]: boolean,
};

export const initialState: <T: string>() => Map<T, boolean> = <T: string>(): Map<T, boolean> => Map({});
export type State<T> = Map<T, boolean>;

export const initLoadingState: <T: string>(
  loading?: DefaultState<T>
) => Map<T, boolean> = <T: string>(
  loading?: DefaultState<T>
): Map<T, boolean> => (
    loading
      ? Map(loading)
      : initialState()
  );

// eslint-disable-next-line default-param-last
export default <T: string>(state: State<T> = initialState(), action: Actions<T>): State<T> => {
  switch (action.type) {
    case SET_LOADING: {
      const { key, value }: SetLoadingAction<T> = action;
      return state.set(key, value);
    }
    default:
      return state;
  }
};
