// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import type { Account, Payload } from "../../pages/EditAccount";

export const getAccountList = (params?: Payload): PromiseResponse<*, {
  data: {
    accountsList: Array<*>,
    count: number,
  }
}> => requestService
  // $FlowFixMe
  .get(environment.endpoints.get.accountList, { params });

export const getAccount = (accountId: string): PromiseResponse<mixed> => requestService
  .get(environment.endpoints.get.account.replace("{id}", accountId));

export const createAccount = (data: Account): PromiseResponse<mixed> => requestService
  .post(environment.endpoints.post.account, data);

export const updateAccount = (accountId: string, data: Account): PromiseResponse<mixed> => requestService
  .put(environment.endpoints.put.account.replace("{id}", accountId), data);
