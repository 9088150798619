// @flow
import type { Permission } from "@fas/ui-framework/lib/services/user";
import { userService } from "./user";

export const getHashValue = (): string => new URLSearchParams(window.location.search).get("hashId") || "";

export default (
  permissions: Array<Permission>,
  accountId?: string = getHashValue()
): boolean => {
  const permissionsWithAccId = permissions.map(
    (x: Permission) => `${accountId}.${x.toString()}`
  );
  return userService.can(permissionsWithAccId);
};
