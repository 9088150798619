// @flow
import React, { useEffect, useState } from "react";
import {
  InputAdornment,
  TextField,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Autocomplete from "@mui/material/Autocomplete";
import type { AutocompleteProps } from "@mui/material/Autocomplete";
import get from "lodash.get";
import debounce from "lodash.debounce";
import type { TextField as TextFieldType } from "@mui/material";
import isEqual from "lodash.isequal";
import axios from "axios";
import linkingApi from "../../services/linkingApi";

type Props = {|
  ...AutocompleteProps,
  label: string,
  tooltipText?: string,
  error: boolean,
  disabled?: boolean,
|};

function TagsAutocomplete(props: Props) {
  const [options, setOptions] = useState([]);
  const [autocompleteInput, setAutocompleteInput] = useState("");
  const [loading, setLoading] = useState(false);

  const debouncedAutocompleteInput = debounce(setAutocompleteInput, 400);

  const {
    label,
    tooltipText,
    error,
    disabled,
    options: optionsIgnored, // * omit passed options to prevent conflicts
    ...restProps
  } = props;
  useEffect((): (() => void) => {
    const source = axios.CancelToken.source();

    if (!disabled && autocompleteInput.length > 1) {
      setLoading(true);
      linkingApi
        .getTags(autocompleteInput, source.token)
        .then((response) => {
          setOptions(get(response, "data.data.data", []));
          setLoading(false);
        })
        .catch((fetchError) => {
          if (!axios.isCancel(fetchError)) {
            setLoading(false);
            setOptions([]);
            setAutocompleteInput("");
          }
        });
    }
    return () => {
      source.cancel();
    };
  }, [autocompleteInput, disabled]);

  return (
    <Autocomplete
      {...restProps}
      options={options}
      loading={loading}
      disabled={disabled}
      autoSelect
      size="small"
      renderInput={(params): TextFieldType => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          margin="normal"
          error={error}
          onChange={({ target: { value } }) => {
            debouncedAutocompleteInput(value);
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                { tooltipText
                  && (
                    <InputAdornment position="start">
                      <Tooltip title={tooltipText}>
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                  )}
                {params.InputProps.startAdornment}
              </>
            ),
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

// $FlowFixMe
TagsAutocomplete.defaultProps = {
  disabled: false,
  tooltipText: "",
};

export default React.memo<Props>(TagsAutocomplete, isEqual);
