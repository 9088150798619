// @flow
import {
  ACCOUNT_GENERAL_INFO_CHANGE_NAME,
  ACCOUNT_GENERAL_INFO_CHANGE_DOMAIN,
} from "../../helpers/constants";

export type ChangeNameAction = {|
  type: typeof ACCOUNT_GENERAL_INFO_CHANGE_NAME,
  value: string,
|};

export type ChangeDomainAction = {|
  type: typeof ACCOUNT_GENERAL_INFO_CHANGE_DOMAIN,
  value: string,
|};

export type Actions =
  ChangeNameAction
  | ChangeDomainAction;

export const changeAccountName = (
  value: string
): ChangeNameAction => ({
  type: ACCOUNT_GENERAL_INFO_CHANGE_NAME,
  value,
});

export const changeAccountDomain = (
  value: string
): ChangeDomainAction => ({
  type: ACCOUNT_GENERAL_INFO_CHANGE_DOMAIN,
  value,
});
