// @flow
import { Record } from "immutable";
import type { RecordOf, RecordFactory } from "immutable";
import {
  SET_PAGE_RESOURCES,
  ADD_PAGE_RESOURCE,
  REMOVE_PAGE_RESOURCE,
  REMOVE_ALL_PAGE_RESOURCE,
} from "../../helpers/constants";
import type { Actions } from "../../actions/pageResources";

export type IncominResource = {
  resourceName: string,
  resourceId: string,
  extension: string,
  updatedAt: string,
  previewUrl: string,
}

export type Resource = {
  base64URL?: null| string | ArrayBuffer,
  resourceId?: string,
  name: string,
  size?: string,
  type: string,
  lastModified: string | number,
}

export type DefaultStateType = {
  resources: Array<Resource>,
};

const defaultState: DefaultStateType = {
  resources: [],
};

export const makeState: RecordFactory<DefaultStateType> = Record(defaultState);

export type State = RecordOf<DefaultStateType>;

export const initialState: State = makeState({});

export const initPageResourcesState = () => initialState;

// eslint-disable-next-line default-param-last
export default (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case SET_PAGE_RESOURCES: {
      const { payload } = action;
      return state.withMutations((newState) => {
        newState.setIn(["resources"], payload.map(({
          resourceName: name,
          resourceId,
          extension: type,
          updatedAt: lastModified,
          previewUrl,
        }) => ({
          resourceId,
          name,
          type,
          lastModified,
          previewUrl,
        })));
      });
    }
    case ADD_PAGE_RESOURCE: {
      const { content } = action;
      return state.updateIn(["resources"], (resources) => [...resources, content]);
    }
    case REMOVE_PAGE_RESOURCE: {
      const { index } = action;
      return state.deleteIn(["resources", index]);
    }
    case REMOVE_ALL_PAGE_RESOURCE: {
      return state.setIn(["resources"], []);
    }
    default:
      return state;
  }
};
