// @flow

import environment from "environment";
import qs from "qs";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "./request";
import type { ModuleNames } from "../actions/accountModules";

const getAccountModules = (modules: Array<ModuleNames>): PromiseResponse<mixed> => requestService
  .get(environment.endpoints.get.accountModules, {
    params: { modules },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "brackets", encode: false }),
  });

export default getAccountModules;
