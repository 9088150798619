// @flow
import React, { useCallback, useState } from "react";
import {
  Avatar,
  Box,
  FormControlLabel,
  Grid, IconButton,
  Switch, Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Dropzone from "react-dropzone";
import { DeleteOutline } from "@mui/icons-material";
import FormDialog from "../FormDialog";
import type { ImportFileType } from "./types/ImportPageFd.types";

type Theme = {
  borderRadius: string,
  palette: {
    borderColor: string,
    text: {
      titleColor: string,
    },
    common: {
      white: string,
    },
  },
};

interface Props {
  open: boolean,
  importing: boolean,
  onApply: (file: ImportFileType, type: string, activate: boolean) => void,
  onClose: () => void,
}

const useStyles = makeStyles((theme: Theme) => ({
  importPageDropzoneDialog: {
    width: "100%",
    border: "3px dotted",
    borderColor: theme.palette.borderColor,
    borderRadius: theme.borderRadius,
    textAlign: "center",
    cursor: "pointer",
    transition: "background .3s ease-in-out, border .3s ease-in-out, border-color .3s ease-in-out",
    "&:hover": {
      background: theme.palette.borderColor,
      border: "3px solid",
      borderColor: theme.palette.common.white,
    },
    "&:focus": {
      outline: "none",
    },
  },
  importPageDropzoneDialogBrowseBtn: {
    padding: "20px 0",
    "& span": {
      color: theme.palette.text.titleColor,
    },
  },
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 0",
  },
}));

function ImportPageFd({
  open,
  importing,
  onApply,
  onClose,
}: Props) {
  const classes = useStyles();
  const [activated, setActivated] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const resetDialog = () => {
    setSelectedFile(({}: ImportFileType));
    setSelectedFileName("");
    setSelectedType("");
    setActivated(false);
  };
  const handleRemoveFile = () => {
    resetDialog();
  };
  const handleClose = () => {
    onClose();
    resetDialog();
  };
  const handleApply = () => {
    onApply(selectedFile, selectedType, activated);
    resetDialog();
  };
  const onDrop = useCallback((acceptedFiles: Array<ImportFileType>) => acceptedFiles.forEach((file: ImportFileType) => {
    const {
      name,
    } = file;
    const typeFormated = name.split(".").slice(-1).pop();

    setSelectedFile(file);
    setSelectedFileName(name);
    setSelectedType(typeFormated);
  }), []);

  return (
    <FormDialog
      open={open}
      loading={importing}
      title="Import CSV"
      hideApply={!selectedFile}
      onApply={handleApply}
      onClose={handleClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            data-testid="import-page-dialog-activate-page-switch-container"
            control={(
              <Switch
                checked={activated}
                onChange={(e) => setActivated(e.target.checked)}
                name="activatePage"
                color="primary"
                data-testid="import-page-dialog-activate-page-switch"
              />
            )}
            label="Activate"
          />
        </Grid>
        <Grid item xs={12}>
          <Dropzone
            onDrop={onDrop}
            accept={[".csv"]}
          >
            {({ getRootProps, getInputProps }) => (
              <Box mb={2} data-testid="import-page-dropzone-dialog" {...getRootProps({ className: classes.importPageDropzoneDialog })}>
                <input {...getInputProps()} />
                <p className={classes.importPageDropzoneDialogBrowseBtn}>
                  Drag files here or
                  &nbsp;
                  <span>Browse</span>
                </p>
              </Box>
            )}
          </Dropzone>
          {
            selectedFile.name && (
              <Box px={1} width={1}>
                <Grid container mb={1} classes={{ root: classes.root }}>
                  <Grid item xs={1}>
                    <Avatar variant="rounded">
                      {selectedType}
                    </Avatar>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      noWrap
                      color="textPrimary"
                      title="File name"
                      variant="body2"
                    >
                      {selectedFileName}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => handleRemoveFile()}
                      title="Remove file"
                      data-testid="import-page-dropzone-dialog-file-remove-btn"
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            )
          }
        </Grid>
      </Grid>
    </FormDialog>
  );
}

ImportPageFd.defaultProps = {
  open: false,
  importing: false,
};

export default ImportPageFd;
