// @flow
export const defaultHtml = `<html>

<head>  
<meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, viewport-fit=cover" />
<link rel='stylesheet' type='text/css' media='all' href='{{document:css}}'>  
<script src='{{document:js}}'></script>
</head>

<body>

</body>

</html>`;

export const defaultCss = "";
