// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type { State as AccountModulesReducerState } from "../../reducers/accountModules";
import type { State } from "../../pages/AppStoreWrapper";
import type {
  Types as LinkingInfoTypes,
  Sorting as LinkingInfoSorting,
  Algorithm as LinkingInfoAlgorithm,
} from "../../components/LinkingInfoForm/types/LinkingInfoForm.types";
import type { ResourceFileFormats, DocumentAliasMode } from "../../actions/accountModules";

export const getLinkingAlgorithms: OutputSelector<
  State,
  *,
  Array<LinkingInfoAlgorithm>
> = createSelector(
  (state: State): AccountModulesReducerState => state.account.accountModules,
  (accountModules: AccountModulesReducerState): Array<LinkingInfoAlgorithm> => accountModules.getIn(["linking", "algorithm"], [])
);

export const getLinkingSortings: OutputSelector<
  State,
  *,
  Array<LinkingInfoSorting>
> = createSelector(
  (state: State): AccountModulesReducerState => state.account.accountModules,
  (accountModules: AccountModulesReducerState): Array<LinkingInfoSorting> => accountModules.getIn(["linking", "sorting"], [])
);

export const getLinkingTypes: OutputSelector<
  State,
  *,
  Array<LinkingInfoTypes>
> = createSelector(
  (state: State): AccountModulesReducerState => state.account.accountModules,
  (accountModules: AccountModulesReducerState): Array<LinkingInfoTypes> => accountModules.getIn(["linking", "type"], [])
);

export const getResourceTypes: OutputSelector<
  State,
  *,
  Array<ResourceFileFormats>
> = createSelector(
  (state: State): AccountModulesReducerState => state.account.accountModules,
  // $FlowFixMe
  (accountModules: AccountModulesReducerState): Array<ResourceFileFormats> => accountModules.getIn(["resource", "fileFormat"], [])
);

export const getDocumentAliasMode: OutputSelector<
  State,
  *,
  DocumentAliasMode
> = createSelector(
  (state: State): AccountModulesReducerState => state.account.accountModules,
  // $FlowFixMe
  (accountModules: AccountModulesReducerState): string => accountModules.getIn(["document", "documentAliasMode"], "")
);
