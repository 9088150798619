// @flow
/* eslint-disable import/max-dependencies */
import {
  put, call, debounce, select,
} from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import type { Response } from "@fas/ui-framework/lib/services/request";
import type { Saga } from "redux-saga";
import { changeTableItemsTotalAmount, setTableData } from "@fas/ui-framework/lib/redux/actions/table/actions";
import {
  getTableFilters,
  getTablePage,
  getTablePageSize,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import { GET_REDIRECT_LIST_SAGA, REDIRECT_LIST } from "../../helpers/constants";
import RedirectApi from "../../services/redirectApi";
import setLoading from "../../actions/loading";
import type { Redirect } from "../../reducers/redirectList";

type Order = "asc" | "desc";
type Filters = { [key: string]: string };
type Sorting = { [key: string]: Order };

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(REDIRECT_LIST, true));
    const page: number = yield select(getTablePage, REDIRECT_LIST);
    const pageSize: number = yield select(getTablePageSize, REDIRECT_LIST);
    const filters: Filters = yield select(getTableFilters, REDIRECT_LIST);
    const sorting: Sorting = yield select(getTableSorting, REDIRECT_LIST);

    const response: Response<mixed, { data: { redirectList: Redirect[], count: number } }> = yield call(
      RedirectApi.getRedirectList,
      {
        page,
        pageSize,
        filters,
        sorting,
      }
    );
    const { data: { redirectList, count } } = response.data;
    yield put(setTableData(REDIRECT_LIST, redirectList));
    yield put(changeTableItemsTotalAmount(REDIRECT_LIST, count));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
    yield put(setTableData(REDIRECT_LIST, []));
  }
  finally {
    yield put(setLoading(REDIRECT_LIST, false));
  }
}

export default function* watch(): Saga<void> {
  // $FlowFixMe
  yield debounce(1000, GET_REDIRECT_LIST_SAGA, makeFetch);
}
