// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import {
  Table,
} from "@fas/ui-core";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import type { TableProps } from "@fas/ui-framework/lib/services/table";
import { useTable } from "@fas/ui-framework/lib/services/table";
import { makeStyles } from "@mui/styles";
import type { Props } from "./types/RedirectList.types";
import { REDIRECT_LIST } from "../../helpers/constants";
import { getRedirectList } from "../../actions/redirectList/actions";
import RedirectListActions from "./RedirectListActions";
import { getHashValue } from "../../services/jusCan";

type Classes = {
  fixedSizeColumn: *,
};
const useStyles: () => Classes = makeStyles((): {[$Keys<Classes>]: *} => ({
  fixedSizeColumn: {
    width: "20%",
  },
}));

function RedirectList({}: Props) {
  const dispatch: <A>(A) => A = useDispatch();
  const navigate = useNavigate();
  const { fixedSizeColumn }: Classes = useStyles();
  const tableProps: TableProps = useTable(REDIRECT_LIST);

  const onCreate: () => mixed = () => navigate(`/redirects/create?hashId=${getHashValue()}`);

  const columns = [
    {
      field: "pageUrl",
      label: "Page URL",
      searchable: true,
      sortable: true,
      className: fixedSizeColumn,
    },
    {
      field: "statusCode",
      label: "Status code",
      searchable: false,
      sortable: false,
      className: fixedSizeColumn,
    },
    {
      field: "redirectUrl",
      label: "Redirect URL",
      searchable: true,
      sortable: true,
      className: fixedSizeColumn,
    },
    {
      field: "updatedAt",
      label: "Last update",
      searchable: false,
      sortable: false,
      className: fixedSizeColumn,
    },
    {
      field: "creator",
      label: "Author",
      searchable: false,
      sortable: false,
      className: fixedSizeColumn,
    },
  ];
  const onLoad = () => dispatch(getRedirectList());

  return (
    <Table
      {...tableProps}
      title="Redirects"
      columns={columns}
      onLoad={onLoad}
      onCreate={onCreate}
      rowSelectAvailable={() => true}
      allSelectAvailable={() => true}
      Actions={<RedirectListActions />}
    />
  );
}

export default RedirectList;
