// @flow
import type { Saga } from "redux-saga";
import {
  call, debounce, put, select,
} from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import {
  getTableFilters,
  getTablePage,
  getTablePageSize,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import {
  changeTableItemsTotalAmount,
  setTableData,
  setTableSelection,
} from "@fas/ui-framework/lib/redux/actions/table/actions";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import setLoading from "../../actions/loading";
import { GET_LINKING_LIST_SAGA, LINKING_LIST } from "../../helpers/constants";
import LinkingApi from "../../services/linkingApi";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(LINKING_LIST, true));
    const filters = yield select(getTableFilters, LINKING_LIST);
    const sorting = yield select(getTableSorting, LINKING_LIST);
    const page: number = yield select(getTablePage, LINKING_LIST);
    const pageSize: number = yield select(getTablePageSize, LINKING_LIST);

    const {
      data: {
        data: { count, data: linkingList },
      },
    } = yield call(LinkingApi.getLinkingList, {
      filters,
      sorting,
      page,
      pageSize,
    });

    yield put(setTableData(LINKING_LIST, linkingList));
    yield put(changeTableItemsTotalAmount(LINKING_LIST, count));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
    yield put(changeTableItemsTotalAmount(LINKING_LIST, 0));
    yield put(setTableData(LINKING_LIST, []));
    yield put(setTableSelection(LINKING_LIST, []));
  }
  finally {
    yield put(setLoading(LINKING_LIST, false));
  }
}

export default function* watch(): Saga<void> {
  yield debounce(1000, GET_LINKING_LIST_SAGA, (makeFetch: Function));
}
