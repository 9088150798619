// @flow
import {
  OPEN_CLONE_PAGE_DIALOG,
  CLOSE_CLONE_PAGE_DIALOG,
  CLONE_PAGE_SAGA,
  EXPORT_PAGE_SAGA,
  CLOSE_EXPORT_PAGE_DIALOG,
  OPEN_EXPORT_PAGE_DIALOG,
  CLOSE_IMPORT_PAGE_DIALOG,
  IMPORT_PAGE_SAGA,
  OPEN_IMPORT_PAGE_DIALOG,
} from "../../helpers/constants";
import type { ExportType, PageTypes } from "../../components/ExportPageFd/types/ExportPageFd.types";

export type Actions = OpenImportPageDialog
  | OpenClonePageDialog
  | CloseClonePageDialog
  | StartClonePage
  | CloseImportPageDialog
  | StartImportPage
  | OpenExportPageDialog
  | CloseExportPageDialog
  | StartExportPage;

export type OpenClonePageDialog = {|
  type: typeof OPEN_CLONE_PAGE_DIALOG,
  pageId: string,
  pageName: string,
  pageUrl: string,
  pageType: PageTypes,
|}

export type CloseClonePageDialog = {|
  type: typeof CLOSE_CLONE_PAGE_DIALOG,
|}

export type StartClonePage = {|
  type: typeof CLONE_PAGE_SAGA,
  pageId: string,
  pageName: string,
  pageUrl: string,
  accountId: string
|}

// eslint-disable-next-line max-len
export const openClonePageDialog = (pageId: string, pageName: string, pageType:PageTypes, pageUrl: string): OpenClonePageDialog => ({
  type: OPEN_CLONE_PAGE_DIALOG,
  pageId,
  pageName,
  pageType,
  pageUrl,
});

export const closeClonePageDialog = (): CloseClonePageDialog => ({
  type: CLOSE_CLONE_PAGE_DIALOG,
});

// eslint-disable-next-line max-len
export const startClonePage = (pageId: string, pageName: string, accountId: string, pageUrl: string): StartClonePage => ({
  type: CLONE_PAGE_SAGA,
  pageId,
  pageName,
  accountId,
  pageUrl,
});

export type OpenExportPageDialog = {|
  type: typeof OPEN_EXPORT_PAGE_DIALOG,
  pageId: string,
  pageName: string,
  pageType: PageTypes,
|}

export type CloseExportPageDialog = {|
  type: typeof CLOSE_EXPORT_PAGE_DIALOG,
|}

export type StartExportPage = {|
  type: typeof EXPORT_PAGE_SAGA,
  exportType: ExportType,
  ids: Array<string>,
  pageType: PageTypes,
|}

export const openExportPageDialog = (pageId: string, pageName: string, pageType:PageTypes): OpenExportPageDialog => ({
  type: OPEN_EXPORT_PAGE_DIALOG,
  pageId,
  pageName,
  pageType,
});

export const closeExportPageDialog = (): CloseExportPageDialog => ({
  type: CLOSE_EXPORT_PAGE_DIALOG,
});

export const startExportPage = (exportType: ExportType, ids: Array<string>, pageType:PageTypes): StartExportPage => ({
  type: EXPORT_PAGE_SAGA,
  exportType,
  ids,
  pageType,
});

export type OpenImportPageDialog = {|
  type: typeof OPEN_IMPORT_PAGE_DIALOG,
|}

export type CloseImportPageDialog = {|
  type: typeof CLOSE_IMPORT_PAGE_DIALOG,
|}

export type StartImportPage = {|
  type: typeof IMPORT_PAGE_SAGA,
  file: File,
  fileType: string,
  activate: boolean,
|}

export const openImportPageDialog = (): OpenImportPageDialog => ({
  type: OPEN_IMPORT_PAGE_DIALOG,
});

export const closeImportPageDialog = (): CloseImportPageDialog => ({
  type: CLOSE_IMPORT_PAGE_DIALOG,
});

export const startImportPage = (file: File, fileType: string, activate: boolean): StartImportPage => ({
  type: IMPORT_PAGE_SAGA,
  file,
  fileType,
  activate,
});
