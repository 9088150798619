// @flow
import React from "react";
import { Delete, Edit } from "@mui/icons-material";
import {
  SimpleTableActionButton, TableActions, TableActionGroup,
  ConfirmTableActionButton,
} from "@fas/ui-core";
import { useDispatch, useSelector } from "react-redux";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { REDIRECT_LIST } from "../../helpers/constants";
import externalRedirect from "../../helpers/ExternalRedirect";
import { deleteRedirect } from "../../actions/redirectList/actions";
import { getHashValue } from "../../services/jusCan";

type Props = {}

function RedirectListActions({}: Props) {
  const items = useSelector(
    (state) => getTableSelections(state, REDIRECT_LIST)
  );
  const dispatch: <A>(A) => A = useDispatch();

  const handleEdit = (id) => {
    externalRedirect({ to: `/redirects/edit/${id}?hashId=${getHashValue()}`, target: "_blank" });
  };

  return (
    <TableActions>
      <TableActionGroup>
        <SimpleTableActionButton
          tooltip="Edit"
          disabled={items.length > 1}
          onClick={() => handleEdit(items[0].id)}
          Icon={<Edit />}
        />
        <ConfirmTableActionButton
          tooltip="Delete"
          items={items}
          viewPath={`/redirects/edit/:id?hashId=${getHashValue()}`}
          confirmTitle="Deleting items"
          confirmText="Are you sure you want to delete"
          onClick={(): mixed => dispatch(deleteRedirect())}
          Icon={<Delete />}
        />
      </TableActionGroup>
    </TableActions>
  );
}

export default RedirectListActions;
