// @flow
import React, { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

type Props = {
  handleSearch: ({
    nameFilter: string,
    domainFilter: string,
    userFilter: string,
  }) => void,
  isAccountsListLoading: boolean,
}
function Search({ handleSearch, isAccountsListLoading }: Props) {
  const [nameFilter, setNameFilter] = useState("");
  const [domainFilter, setDomainFilter] = useState("");
  const [userFilter, setUserFilter] = useState("");

  const handleChangeNameFilter = (event) => {
    const { value } = event.target;
    setNameFilter(value);
  };
  const handleChangeDomainFilter = (event) => {
    const { value } = event.target;
    setDomainFilter(value);
  };
  const handleChangeUserFilter = (event) => {
    const { value } = event.target;
    setUserFilter(value);
  };

  return (
    <Box display="flex" justifyContent="space-between" maxWidth="700px" m="auto" mb={2}>
      <TextField
        disabled={isAccountsListLoading}
        sx={{ backgroundColor: "#FFF" }}
        label="Name"
        name="nameFilter"
        size="small"
        value={nameFilter}
        onChange={handleChangeNameFilter}
        data-testid="name-input"
      />
      <TextField
        disabled={isAccountsListLoading}
        sx={{ backgroundColor: "#FFF" }}
        label="Domain"
        name="domainFilter"
        size="small"
        value={domainFilter}
        onChange={handleChangeDomainFilter}
        data-testid="domain-input"
      />
      <TextField
        disabled={isAccountsListLoading}
        sx={{ backgroundColor: "#FFF" }}
        label="User"
        name="userFilter"
        size="small"
        value={userFilter}
        onChange={handleChangeUserFilter}
        data-testid="user-input"
      />
      <Button
        onClick={() => handleSearch({ nameFilter, domainFilter, userFilter })}
        startIcon={<RefreshIcon />}
        variant="contained"
        disabled={isAccountsListLoading}
        data-testid="search-button"
      >
        Search
      </Button>
    </Box>
  );
}

export default Search;
