// @flow
import React, { useCallback } from "react";
import { Box, Grid } from "@mui/material";
import debounce from "lodash.debounce";
import CodeEditor from "../CodeEditor";
import Title from "./Title";
import type { RowRenderProps } from "./types/PageModifiers.types";

function PageCrossingRender({
  title,
  value,
  description,
  placeholder,
  modifier,
  onChange,
  dataTestId,
}: RowRenderProps) {
  const debouncedChange = useCallback(debounce(onChange, 600), [onChange]);
  return (
    <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
      <Grid item xs={12}>
        <Title
          title={title}
          description={description}
        />
        <Box width={1} mb={2} data-testid={dataTestId}>
          <CodeEditor
            placeholder={placeholder}
            height="150px"
            language="html"
            theme="dark"
            value={value}
            onChange={(newValue: string) => {
              debouncedChange(modifier, newValue);
            }}
            onBlur={(newValue: string) => {
              if (newValue !== value) {
                debouncedChange.cancel();
                onChange(modifier, newValue);
              }
            }}
            options={{ minimap: { enabled: false } }}
            name={modifier}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default PageCrossingRender;
