// @flow
import { put, select, takeEvery } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { CHANGE_PAGE_TYPE_SAGA } from "../../helpers/constants";
import { changePagePftTemplate, changePageType } from "../../actions/pageInfo";
import type { ChangePageTypeSaga } from "../../actions/pageInfo";
import { changePageVersionHtml } from "../../actions/pageVersions";
import { defaultHtml } from "../../helpers/pageDefaults";
import { getPageInfo } from "../../selectors/page";
import { resetPageModifiers } from "../../actions/pageModifiers";

export function* applyAction(action: ChangePageTypeSaga): Saga<void> {
  const { pageType: nextPageType }: ChangePageTypeSaga = action;

  const prevPageType = (yield select(getPageInfo)).get("pageType");

  yield put(changePageType(nextPageType));
  yield put(changePagePftTemplate("", ""));
  yield put(resetPageModifiers());

  if (nextPageType === "pft") {
    yield put(changePageVersionHtml("new", ""));
  }
  if (prevPageType === "pft") {
    yield put(changePageVersionHtml("new", defaultHtml));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(CHANGE_PAGE_TYPE_SAGA, applyAction);
}
