// @flow
/* eslint-disable import/max-dependencies */
import {
  takeEvery, select, call, put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import type { Response } from "@fas/ui-framework/lib/services/request";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { SAVE_LINKING_ITEM_SAGA, SAVE_LINKING_LOADING } from "../../helpers/constants";
import { getLinkingInfo } from "../../selectors/linking";
import LinkingApi from "../../services/linkingApi";
import setLoading from "../../actions/loading";
import { getHashValue } from "../../services/jusCan";
import { getLinkingSortings } from "../../selectors/accountModules";
import { schema } from "./schema";
import errorsTransform from "../../helpers/errorsTransform";
import type { SaveLinkingInfoSaga } from "../../actions/linkingList";

export function* makeFetch({ navigate }: SaveLinkingInfoSaga): Saga<void> {
  const linkingInfo = yield select(getLinkingInfo);
  const sortings = yield select(getLinkingSortings);
  const mainSchema = schema(sortings);

  const validationResult = mainSchema.validate(linkingInfo);
  const validationErrors = errorsTransform(validationResult);

  yield put(setErrors(validationErrors));
  if (Object.keys(validationErrors).length) {
    return;
  }

  yield put(setLoading(SAVE_LINKING_LOADING, true));

  try {
    if (linkingInfo.id) {
      yield call(
        LinkingApi.updateLinkingInfo,
        linkingInfo.id,
        linkingInfo
      );
      yield put(addNotification({ message: "Successfully saved", severity: "success" }));
    }
    else {
      const response: Response<
        mixed,
        { data: { id: string } }
      > = yield call(LinkingApi.createLinkingInfo, linkingInfo);
      const {
        data: {
          data: { id: linkingId },
        },
      }: Response<mixed, { data: { id: string } }> = response;

      yield put(addNotification({ message: "Successfully created", severity: "success" }));
      navigate(`/linking/edit/${linkingId}?hashId=${getHashValue()}`);
    }
  }
  catch (error) {
    if (error.errorMessage && (
      error.errorMessage === "Error DB save." || error.errorMessage.endsWith("is not unique for current account.")
    )) {
      yield put(addNotification({ message: "Linking name already exists", severity: "error" }));
    }
    else {
      yield put(addNotification({ message: error.errorMessage || "Failed to save", severity: "error" }));
    }
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(SAVE_LINKING_LOADING, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(SAVE_LINKING_ITEM_SAGA, (makeFetch: Function));
}
