// @flow
import joi from "joi";

// eslint-disable-next-line import/prefer-default-export
export const validateSchema = joi
  .object({
    name: joi
      .string()
      .required()
      .regex(/\S/)
      .regex(/^[ A-Za-z0-9_@./#&+-]*$/)
      .min(1)
      .max(128)
      .label("Name")
      .error((x) => x.map((errorItem) => {
        switch (errorItem.code) {
          case "string.pattern.base":
            // eslint-disable-next-line no-param-reassign
            errorItem.message = "Invalid characters, allowed only A-Za-z0-9_@./#&+-";
            return errorItem;
          default:
            return errorItem;
        }
      })),
    domain: joi
      .string()
      .domain()
      .optional()
      .label("Domain"),
    users: joi
      .array()
      .items(
        joi.object({ id: joi.string(), name: joi.string(), role: joi.string().required().label("Role") }).error((x) => x.map((errorItem) => {
          switch (errorItem.code) {
            case "any.required":
              // eslint-disable-next-line no-param-reassign
              errorItem.message = "Select role";
              return errorItem;
            default:
              return errorItem;
          }
        }))
      )
      .min(1)
      .label("Users")
    ,
  })
  .options({ abortEarly: false, allowUnknown: true });
