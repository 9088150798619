// @flow
import { Record } from "immutable";
import type { RecordOf, RecordFactory } from "immutable";
import { SET_ACCOUNTS_MODULES } from "../../helpers/constants";
import type {
  Actions,
  LinkingSettings,
  ResourceSettings,
  SitemapSettings,
  DocumentSettings,
} from "../../actions/accountModules";

type DefaultStateType = {
  linking: LinkingSettings,
  resource: ResourceSettings,
  sitemap: SitemapSettings,
  document: DocumentSettings,
};

const defaultState: DefaultStateType = {
  linking: {},
  resource: {},
  sitemap: {},
  document: {},
};

export const makeState: RecordFactory<DefaultStateType> = Record(defaultState);

export type State = RecordOf<DefaultStateType>;

export const initialState: State = makeState({});
export const initAccountModulesState: () => State = (): State => initialState;

// eslint-disable-next-line default-param-last
export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_ACCOUNTS_MODULES: {
      const {
        accountModule: { name, settings },
      } = action;
      // $FlowFixMe
      return state.set(name, settings);
    }
    default:
      return state;
  }
};
