// @flow
import { type NavigateFunction } from "react-router";
import {
  GET_REDIRECT_SAGA,
  SAVE_REDIRECT_SAGA,
} from "../../helpers/constants";
import type { Redirect } from "../../containers/RedirectInfo/types/RedirectInfo.type";

export type GetRedirectSagaAction = {|
  type: typeof GET_REDIRECT_SAGA,
  id: string,
|};

export type SaveRedirectSagaAction = {|
  type: typeof SAVE_REDIRECT_SAGA,
  redirectId?: string,
  redirect: Redirect,
  navigate: NavigateFunction,
|};

export type Actions =
  GetRedirectSagaAction
  | SaveRedirectSagaAction;

export const getRedirectSaga = (id: string): GetRedirectSagaAction => ({
  type: GET_REDIRECT_SAGA,
  id,
});

export const saveRedirect = (redirectId?: string, redirect: Redirect, navigate: NavigateFunction)
: SaveRedirectSagaAction => ({
  type: SAVE_REDIRECT_SAGA,
  redirectId,
  redirect,
  navigate,
});
