// @flow
/* eslint-disable import/max-dependencies */
import React, { useState } from "react";
import type { Node } from "react";
import { ComboBox, SetupCard, GridUc } from "@fas/ui-core";
import {
  Grid,
  Box,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import {
  hasError,
  getErrorMessage,
} from "@fas/ui-framework/lib/services/generators";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";
import type { PAGE_TYPES_TITLE_TYPE } from "../../helpers/constants";
import type { PageType } from "../../reducers/pageInfo";
import type {
  ChangePageNameAction,
  ChangePageTypeSaga,
  ChangePageUrlAction,
  ChangePageTags,
} from "../../actions/pageInfo";
import type { DocumentAliasMode } from "../../actions/accountModules";
import TagsAutocomplete from "../TagsAutocomplete";
import { getHashValue } from "../../services/jusCan";

type Props = {
  documentAliasMode: DocumentAliasMode,
  pageTypesList: Array<PAGE_TYPES_TITLE_TYPE>,
  pageType: PageType,
  pageId: ?string,
  name: string,
  url: string,
  template: {
    templateId: string,
    templateName: string,
  },
  templateList: Array<string>,
  isTemplateListLoading: boolean,
  tags: Array<string>,
  errors: ErrorsMapper,
  onChangeType: (type: PageType) => ChangePageTypeSaga,
  onChangeName: (name: string) => ChangePageNameAction,
  onChangeUrl: (url: string) => ChangePageUrlAction,
  onChangePftTemplate: (template: string) => void,
  onInputChangePftTemplate: (value: string) => void,
  onChangeTags: (tags: Array<string>) => ChangePageTags,
};

function PageInfo({
  documentAliasMode,
  pageTypesList,
  pageType,
  name,
  pageId,
  url,
  template,
  templateList,
  isTemplateListLoading,
  tags,
  errors,
  onChangeType,
  onChangeName,
  onChangeUrl,
  onChangePftTemplate,
  onInputChangePftTemplate,
  onChangeTags,
}: Props) {
  const [inputText, setInputText] = useState("");
  const hashId = getHashValue();

  return (
    <SetupCard title="General Info">
      <>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={3}
        >
          <GridUc item xs={3} display="flex" pt="0px !important">
            <Typography>Type</Typography>
          </GridUc>
          <Grid item xs={9}>
            <Box width={1} mb={2}>
              <FormControl variant="filled" fullWidth>
                <ComboBox
                  disabled={Boolean(pageId)}
                  id="mui-autocomplete-page-type"
                  label="Type"
                  options={pageTypesList}
                  value={pageTypesList.find(
                    (item) => item.toLocaleLowerCase() === pageType
                  )}
                  defaultValue={pageType}
                  isOptionEqualToValue={(option, val) => option === val}
                  getOptionLabel={(option) => option}
                  error={{
                    isError: hasError("pageType", errors),
                    helperText: getErrorMessage("pageType", errors),
                  }}
                  onChange={(
                    e: SyntheticInputEvent<HTMLInputElement>,
                    value
                  ) => {
                    onChangeType(value.toLocaleLowerCase());
                  }}
                  disableClearable
                  data-testid="page-type-combobox"
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        {pageType && pageType === "pft" && (
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={3}
          >
            <GridUc item xs={3} display="flex" pt="0px !important">
              <Typography>Template</Typography>
            </GridUc>
            <Grid item xs={9}>
              <Box width={1} mb={2}>
                <FormControl variant="filled" fullWidth>
                  <ComboBox
                    disabled={Boolean(pageId)}
                    id="mui-autocomplete-page-template"
                    label="Template"
                    loading={isTemplateListLoading}
                    options={templateList}
                    value={template.templateName}
                    error={{
                      isError: hasError("template", errors),
                      helperText: getErrorMessage("template", errors),
                    }}
                    defaultValue={template.templateName}
                    isOptionEqualToValue={(option, val) => option === val}
                    getOptionLabel={(option) => option || ""}
                    renderInput={(params): Node => (
                      <TextField
                        {...params}
                        data-testid="mui-autocomplete-page-template"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              <InputAdornment
                                position="start"
                              >
                                { template.templateName && <Button component="a" target="blank" href={`/pages/edit/${template.templateId}?hashId=${hashId}`}>View template</Button>}
                              </InputAdornment>
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    onChange={(
                      e: SyntheticInputEvent<HTMLInputElement>,
                      value: string
                    ) => {
                      onChangePftTemplate(value);
                    }}
                    onInputChange={(
                      e: SyntheticInputEvent<HTMLInputElement>,
                      value: string
                    ) => {
                      setInputText(value);
                      onInputChangePftTemplate(value);
                    }}
                    disableClearable
                    data-testid="page-template-combobox"
                    noOptionsText={
                      inputText.length > 2
                        ? "No options"
                        : "Enter template name"
                    }
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={3}
        >
          <GridUc item xs={3} display="flex" pt="0px !important">
            <Typography>Name</Typography>
          </GridUc>
          <Grid item xs={9}>
            <Box width={1} mb={2}>
              <FormControl variant="filled" fullWidth>
                <TextField
                  id="mui-textfield-page-name"
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={(
                    e: SyntheticInputEvent<HTMLInputElement>
                  ): ChangePageNameAction => onChangeName(e.target.value)}
                  error={hasError("name", errors)}
                  data-testid="page-name-text-field"
                  helperText={
                    hasError("name", errors) && getErrorMessage("name", errors)
                  }
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        {pageType && pageType !== "template" && (
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={3}
          >
            <GridUc item xs={3} display="flex" pt="0px !important">
              <Typography>URL</Typography>
            </GridUc>
            <Grid item xs={9}>
              <Box width={1} mb={2}>
                <FormControl variant="filled" fullWidth>
                  <TextField
                    id="mui-textfield-page-url"
                    variant="outlined"
                    size="small"
                    value={url}
                    onChange={(
                      e: SyntheticInputEvent<HTMLInputElement>
                    ): ChangePageUrlAction => onChangeUrl(e.target.value)}
                    error={hasError("url", errors)}
                    data-testid="page-url-text-field"
                    disabled={!documentAliasMode || documentAliasMode === "openId"}
                    helperText={
                      hasError("url", errors) && getErrorMessage("url", errors)
                    }
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={3}
        >
          <GridUc item xs={3} display="flex" pt="0px !important">
            <Typography>Tags</Typography>
          </GridUc>
          <Grid item xs={9}>
            <Box width={1} mb={2} data-testid="tags-multi-select-container">
              <TagsAutocomplete
                id="tags-multi-select"
                value={tags}
                onChange={(_, value) => onChangeTags(value)}
                error={hasError("tags", errors)}
                multiple
                freeSolo
              />
            </Box>
          </Grid>
        </Grid>
      </>
    </SetupCard>
  );
}

export default PageInfo;
