// @flow
import { Record } from "immutable";
import type { RecordOf, RecordFactory } from "immutable";
import {
  CHANGE_REDIRECT_PAGE_URL,
  CHANGE_REDIRECT_STATUS_CODE,
  CHANGE_REDIRECT_REDIRECT_URL,
  SET_REDIRECT,
} from "../../helpers/constants";
import type {
  Actions,
} from "../../actions/redirectInfo";

export type DefaultStateType = {
  id: string,
  pageUrl: string,
  statusCode: string,
  redirectUrl: string,
}

const defaultState: DefaultStateType = {
  id: "",
  pageUrl: "",
  statusCode: "301",
  redirectUrl: "",
};

export const makeState: RecordFactory<DefaultStateType> = Record(defaultState);

export type State = RecordOf<DefaultStateType>;

export const initialState: State = makeState({});

export const initRedirectInfoState = () => initialState;

export type IncomingRedirectInfoState = {
  id: string,
  pageUrl: string,
  redirectUrl: string,
  statusCode: number,
}
// eslint-disable-next-line no-restricted-exports,default-param-last
export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case CHANGE_REDIRECT_PAGE_URL: {
      return state.set("pageUrl", action.pageUrl);
    }
    case CHANGE_REDIRECT_STATUS_CODE: {
      return state.set("statusCode", action.statusCode);
    }
    case CHANGE_REDIRECT_REDIRECT_URL: {
      return state.set("redirectUrl", action.redirectUrl);
    }
    case SET_REDIRECT: {
      const { payload } = action;
      const {
        id,
        pageUrl,
        redirectUrl,
        statusCode,
      } = payload;
      return state.withMutations((newState) => {
        newState.set("id", id);
        newState.set("pageUrl", pageUrl);
        newState.set("redirectUrl", redirectUrl);
        newState.set("statusCode", String(statusCode));
      });
    }
    default:
      return state;
  }
};
