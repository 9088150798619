// @flow
/* eslint-disable import/max-dependencies */
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";

import getAccountListSaga from "../../sagas/getAccountListSaga";
import saveAccountSaga from "../../sagas/saveAccountSaga/saveAccountSaga";
import getAccountSaga from "../../sagas/getAccountSaga/getAccountSaga";

import getLinkingListSaga from "../../sagas/getLinkingListSaga";
import bulkDeleteLinkingSaga from "../../sagas/bulkDeleteLinkingSaga";
import getLinkingInfo from "../../sagas/getLinkingInfo";
import saveLinkingInfoSaga from "../../sagas/saveLinkingInfo";
import getLinkingInfoHeightSaga from "../../sagas/getLinkingInfoHeightSaga";
import getAccountModulesSaga from "../../sagas/getAccountModulesSaga";

import clonePageSaga from "../../sagas/clonePageSaga";
import exportPageSaga from "../../sagas/exportPageSaga";
import importPageSaga from "../../sagas/importPageSaga";
import bulkDelete from "../../sagas/pageListBulkDeleteSaga";
import activatePageSaga from "../../sagas/activatePageSaga";
import archivePageSaga from "../../sagas/archivePageSaga";
import getPagesListSaga from "../../sagas/getAllPagesSaga";
import getPageVerisionPreview from "../../sagas/getPageVersionPreviewSaga";

import watchGetPageSaga from "../../sagas/getPageSaga";
import watchSavePageSaga from "../../sagas/savePageSaga";
import watchCreatePageSaga from "../../sagas/createPageSaga";
import changePageTypeSaga from "../../sagas/changePageTypeSaga";
import watchSaveModifierFormSaga from "../../sagas/saveModifierForm";
import downloadPageResourcesSaga from "../../sagas/downloadPageResourcesSaga";

import getRedirectListSaga from "../../sagas/getRedirectListSaga";
import deleteRedirectSaga from "../../sagas/deleteRedirectSaga";
import watchGetRedirectSaga from "../../sagas/getRedirectSaga";
import watchSaveRedirectSaga from "../../sagas/saveRedirectSaga";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(getAccountListSaga),
    call(saveAccountSaga),
    call(getAccountSaga),

    call(getLinkingListSaga),
    call(bulkDeleteLinkingSaga),
    call(getLinkingInfo),
    call(saveLinkingInfoSaga),
    call(getLinkingInfoHeightSaga),
    call(getAccountModulesSaga),

    call(getPagesListSaga),
    call(getPageVerisionPreview),
    call(clonePageSaga),
    call(exportPageSaga),
    call(importPageSaga),
    call(bulkDelete),
    call(activatePageSaga),
    call(archivePageSaga),

    call(watchGetPageSaga),
    call(watchSavePageSaga),
    call(watchCreatePageSaga),
    call(changePageTypeSaga),
    call(watchSaveModifierFormSaga),
    call(downloadPageResourcesSaga),

    call(getRedirectListSaga),
    call(deleteRedirectSaga),
    call(watchGetRedirectSaga),
    call(watchSaveRedirectSaga),
  ]);
}
