// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import { connect } from "react-redux";
import { Link } from "@mui/material";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";
import ModifierForm from "../../components/ModifierForm";
import { getErrorsObject } from "../../selectors/errors";
import { getModifierFormData, getModifierFormIsExpand } from "../../selectors/modifierForm";
import { saveModifierFormSaga, setModifierFormFields, setModifierFormIsExpand } from "../../actions/modifierForm";
import type { Modifier } from "../../reducers/pageModifiers/reducer";

type OwnProps = $ReadOnly<{||}>;
type StateToProps = $ReadOnly<{|
  modifier: Modifier,
  isExpand: boolean,
  errors: ErrorsMapper,
|}>;

type DispatchToProps = $ReadOnly<{|
  onSetModifierForm: typeof setModifierFormFields,
  onSetIsExpand: typeof setModifierFormIsExpand,
  onSave: typeof saveModifierFormSaga,
  onSetErrors: typeof setErrors,
|}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateToProps,
  ...DispatchToProps,
|}>;

function mapStateToProps(state): StateToProps {
  return {
    modifier: getModifierFormData(state),
    isExpand: getModifierFormIsExpand(state),
    errors: getErrorsObject(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>): DispatchToProps {
  return bindActionCreators({
    onSetModifierForm: setModifierFormFields,
    onSetIsExpand: setModifierFormIsExpand,
    onSave: saveModifierFormSaga,
    onSetErrors: setErrors,
  }, dispatch);
}

function ModifierFormContainer({
  modifier,
  isExpand,
  errors,
  onSetModifierForm,
  onSetIsExpand,
  onSave,
  onSetErrors,
}: Props) {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isExpand ? (
        <ModifierForm
          description={modifier.description}
          anchor={modifier.name}
          type={modifier.type}
          types={["input", "textarea", "html"]}
          errors={errors}
          onTypeChange={(value: string) => onSetModifierForm({ type: value })}
          onDescriptionChange={(value: string) => onSetModifierForm({ description: value })}
          onAnchorChange={(value: string) => onSetModifierForm({ name: value })}
          onApply={() => onSave(modifier)}
          onCancel={() => {
            onSetModifierForm({
              name: "",
              description: "",
              type: "textarea",
            });
            onSetIsExpand(false);
            onSetErrors({});
          }}
        />
      ) : (
        <Link
          variant="button"
          component="button"
          onClick={() => onSetIsExpand(true)}
          data-testid="addNewButton"
        >
          + Add New Modifier for this template
        </Link>
      )}
    </>
  );
}

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(ModifierFormContainer);
