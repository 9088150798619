// @flow
/* eslint-disable import/max-dependencies */
import {
  takeEvery, call, put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import type { Response } from "@fas/ui-framework/lib/services/request";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { GET_PAGE_VERSION_PREVIEW, GET_PAGE_VERSION_PREVIEW_LOADING } from "../../helpers/constants";
import DocumentApi from "../../services/documentApi";
import type { GetPageVerisionPreviewSagaAction } from "../../actions/page";
import setLoading from "../../actions/loading";
import type { PageLoadingTypes as LoadingTypes } from "../../actions/loading";

export function* makeFetch(action: GetPageVerisionPreviewSagaAction): Saga<void> {
  try {
    yield put(setLoading<LoadingTypes>(GET_PAGE_VERSION_PREVIEW_LOADING, true));
    const { pageId, versionId }: GetPageVerisionPreviewSagaAction = action;
    const response: Response<mixed, { data: { result: { url: string } } }> = yield call(
      DocumentApi.getPageVersionPreview,
      pageId,
      versionId
    );
    const {
      data: {
        data: {
          result: preview,
        },
      },
    }: Response<mixed, { data: { result: { url: string } } }> = response;
    const { url } = preview;

    window.open(url, "_blank");
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch preview", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading<LoadingTypes>(GET_PAGE_VERSION_PREVIEW_LOADING, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_PAGE_VERSION_PREVIEW, makeFetch);
}
