// @flow
import {
  SET_MODIFIER_FORM_IS_EXPAND,
  SET_MODIFIER_FORM_FIELDS,
  SAVE_MODIFIER_FORM_SAGA,
} from "../../helpers/constants";
import type { Modifier } from "../../reducers/pageModifiers/reducer";

export type SetModifierFormIsExpandAction = {
  type: typeof SET_MODIFIER_FORM_IS_EXPAND,
  value: boolean,
}

export type SetModifierFormFieldsAction = {
  type: typeof SET_MODIFIER_FORM_FIELDS,
  data: $Shape<Modifier>,
}

export type SaveModifierFormSagaAction = {
  type: typeof SAVE_MODIFIER_FORM_SAGA,
  data: Modifier,
}

export type Actions = SetModifierFormIsExpandAction
| SetModifierFormFieldsAction
| SaveModifierFormSagaAction

export function setModifierFormIsExpand(value: boolean): SetModifierFormIsExpandAction {
  return {
    type: SET_MODIFIER_FORM_IS_EXPAND,
    value,
  };
}

export function setModifierFormFields(data: $Shape<Modifier>): SetModifierFormFieldsAction {
  return {
    type: SET_MODIFIER_FORM_FIELDS,
    data,
  };
}

export function saveModifierFormSaga(data: Modifier): SaveModifierFormSagaAction {
  return {
    type: SAVE_MODIFIER_FORM_SAGA,
    data,
  };
}
