// @flow
import { useCookies } from "react-cookie";
import { createSearchParams, useLocation } from "react-router-dom";

const useHashId: () => {
  hashId: string | null,
  hashIdCookie: string | null,
  setHashId: (string) => void,
  removeHashId: () => void,
} = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["hashId"]);
  const hashIdCookie = cookies.hashId;
  const params = useLocation();
  const hashId = createSearchParams(params.search).get("hashId");
  const removeHashId = () => removeCookie("hashId", { path: "/" });
  const setHashId = (id: string) => setCookie("hashId", id, { path: "/" });

  return {
    hashId,
    hashIdCookie,
    setHashId,
    removeHashId,
  };
};

export default useHashId;
