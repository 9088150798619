// @flow
import React from "react";
import {
  Grid,
  Box,
  Button,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { GridUc, SelectCustom, SetupCard } from "@fas/ui-core";
import type { VersionType } from "../../reducers/pageVersions";
import type {
  GetPageVerisionPreviewSagaAction,
} from "../../actions/page";

type Props = {
  pageVersionType: VersionType,
  pageVersionUpdatedAt: string,
  pageVersionTypesValues: Array<{ value: VersionType, title: string }>,
  onSetPageVersion: (versionType: VersionType) => void,
  onGetPageVersionPreview: () => GetPageVerisionPreviewSagaAction,
  editPathName: string,
};

function PageVersionActions({
  pageVersionUpdatedAt,
  pageVersionType,
  pageVersionTypesValues,
  onSetPageVersion,
  onGetPageVersionPreview,
  editPathName,
}: Props) {
  return (
    <SetupCard title="Page Version Control">
      <GridUc
        mt={2}
        container
        direction="row"
        spacing={3}
      >
        <Grid item xs={4}>
          <Box width="100%">
            <SelectCustom
              variant="outlined"
              size="small"
              value={pageVersionType}
              title={pageVersionUpdatedAt}
              values={pageVersionTypesValues}
              onTypeChange={(e): void => onSetPageVersion(e.target.value)}
              dataTestid="pages-page-view-header-version-select"
            />
          </Box>
        </Grid>
        <Grid item xs={8} align="right" display="flex" justifyContent="flex-end">
          <Box display="flex" justifyContent="center" alignItems="center">
            <Tooltip
              title="Preview page"
            >
              <Button
                data-testid="pages-page-view-header-version-preview"
                aria-controls="pages-page-view-header-version-preview"
                color="primary"
                size="large"
                onClick={onGetPageVersionPreview}
              >
                Preview page
              </Button>
            </Tooltip>
          </Box>
          <Box display="flex" height="100%" justifyContent="center" alignItems="center">
            <Tooltip
              title="Edit page"
            >
              <Link to={editPathName}>
                <Button
                  data-testid="pages-page-view-header-version-edit"
                  aria-controls="pages-page-view-header-version-edit"
                  color="primary"
                  size="large"
                >
                  Edit page
                </Button>
              </Link>
            </Tooltip>
          </Box>
        </Grid>
      </GridUc>
    </SetupCard>
  );
}

export default PageVersionActions;
