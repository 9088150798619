// @flow
/* eslint-disable import/max-dependencies */
import React, { type Node } from "react";
import { useSelector } from "react-redux";
import DocumentTitle from "react-document-title";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PageSettings from "../../containers/PageSettings";
import FloatingControlButtons from "../../containers/FloatingControlButtons";
import { getPageInfo } from "../../selectors/page";

const useStyles = makeStyles((theme) => ({
  mainBackground: {
    background: theme.palette.background.mainBg,
    minHeight: "calc(100% - 64px)",
  },
}));

function PageEdit(): Node {
  const classes = useStyles();
  const { name } = useSelector((state) => getPageInfo(state));

  return (
    <DocumentTitle title={`Edit page: ${name}`}>
      <>
        <Grid container direction="column" className={classes.mainBackground}>
          <Grid item>
            <PageSettings />
          </Grid>
        </Grid>
        <Grid item>
          <FloatingControlButtons />
        </Grid>
      </>
    </DocumentTitle>
  );
}

export default PageEdit;
