// @flow
import { Record } from "immutable";
import type { RecordFactory, RecordOf } from "immutable";
import {
  SET_LINKING_ITEM,
  SET_LINKING_ITEM_NAME,
  SET_LINKING_ITEM_TYPE,
  SET_LINKING_ITEM_TAGS_DISPLAY,
  SET_LINKING_ITEM_TAGS_GENERATION,
  SET_LINKING_ITEM_TEMPLATE_CODE,
  SET_LINKING_ITEM_SORTING,
  SET_LINKING_ITEM_WIDTH,
  SET_LINKING_ITEM_HEIGHT,
  SET_LINKING_ITEM_USE_PAGINATION,
} from "../../helpers/constants";
import type { Actions } from "../../actions/linkingList";
import type { LinkingInfo } from "../../components/LinkingList/types/LinkingList.types";

export type { LinkingInfo };

export const defaultValue: LinkingInfo = {
  id: "",
  accountId: "",
  name: "",
  type: "matrix",
  tagsGeneration: [],
  tagsDisplay: [],
  templateCode: "",
  sorting: "sort oldest to newest",
  algorithm: "",
  width: 0,
  height: 0,
  usePagination: false,
  createdAt: "",
  updatedAt: "",
  lastEditor: "",
};

export const makeState: RecordFactory<LinkingInfo> = Record(defaultValue);

export type State = RecordOf<LinkingInfo>;

export const initialState: State = makeState({});

export function initLinkingInfoState(): State {
  return initialState;
}

// eslint-disable-next-line complexity,default-param-last
export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_LINKING_ITEM: {
      const { linkingInfo } = action;
      return state.merge(linkingInfo);
    }
    case SET_LINKING_ITEM_NAME: {
      const { name } = action;
      return state.set("name", name);
    }
    case SET_LINKING_ITEM_TYPE: {
      const { linkingInfoType } = action;
      if (linkingInfoType === "list") {
        return state.set("type", linkingInfoType).set("algorithm", "circle");
      }
      if (linkingInfoType === "matrix") {
        return state
          .set("type", linkingInfoType)
          .set("algorithm", "")
          .set("tagsDisplay", [])
          .set("usePagination", false);
      }
      return state;
    }
    case SET_LINKING_ITEM_TAGS_GENERATION: {
      const { tagsGeneration } = action;
      return state.set("tagsGeneration", tagsGeneration);
    }
    case SET_LINKING_ITEM_TAGS_DISPLAY: {
      const { tagsDisplay } = action;
      return state.set("tagsDisplay", tagsDisplay);
    }
    case SET_LINKING_ITEM_TEMPLATE_CODE: {
      const { templateCode } = action;
      return state.set("templateCode", templateCode);
    }
    case SET_LINKING_ITEM_SORTING: {
      const { sorting } = action;
      return state.set("sorting", sorting);
    }
    case SET_LINKING_ITEM_WIDTH: {
      const { width } = action;
      if (isNaN(Number(width))) {
        return state;
      }
      return state.set("width", Number(width));
    }
    case SET_LINKING_ITEM_HEIGHT: {
      const { height } = action;
      return state.set("height", Number(height));
    }
    case SET_LINKING_ITEM_USE_PAGINATION: {
      return state.set("usePagination", action.usePagination);
    }
    default:
      return state;
  }
};
