// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type { State } from "../../reducers/redirectInfo";

import type { State as RedirectStoreState } from "../../pages/AppStoreWrapper";

export const getRedirectId: OutputSelector<RedirectStoreState, *, string> = createSelector(
  (state: RedirectStoreState): State => state.redirect,
  (redirectInfo: State): string => redirectInfo.get("id")
);

export const getRedirectInfo: OutputSelector<RedirectStoreState, *, State> = createSelector(
  (state: RedirectStoreState): State => state.redirect,
  (redirectInfo: State): State => redirectInfo
);
