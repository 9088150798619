// @flow
import {
  debounce, call, put, select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import {
  getTableFilters,
  getTablePage,
  getTablePageSize,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import { changeTableItemsTotalAmount, setTableData } from "@fas/ui-framework/lib/redux/actions/table/actions";
import { PAGES_LIST, GET_PAGES_LIST_SAGA } from "../../helpers/constants";
import { getPagesList } from "../../services/pagesApi";
import setLoading from "../../actions/loading";
import type { PagesListLoading as LoadingTypes } from "../../actions/loading";
import type { Filters, Sorting } from "../../pages/AppStoreWrapper";

export function* getAllPages(): Saga<void> {
  try {
    yield put(setLoading<LoadingTypes>(PAGES_LIST, true));

    const page: number = yield select(getTablePage, PAGES_LIST);
    const pageSize: number = yield select(getTablePageSize, PAGES_LIST);
    const filters: Filters = yield select(getTableFilters, PAGES_LIST);
    const sorting: Sorting = yield select(getTableSorting, PAGES_LIST);

    const response = yield call(getPagesList, {
      page, pageSize, filters, sorting,
    });
    const { data: { pagesList, count } } = response.data;

    yield put(setTableData(PAGES_LIST, pagesList.map((item) => ({
      ...item,
      id: item.openId,
    }))));
    yield put(changeTableItemsTotalAmount(PAGES_LIST, count));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
    yield put(setTableData(PAGES_LIST, []));
    yield put(changeTableItemsTotalAmount(PAGES_LIST, 0));
  }
  finally {
    yield put(setLoading<LoadingTypes>(PAGES_LIST, false));
  }
}

export default function* watch(): Saga<void> {
  yield debounce(1000, GET_PAGES_LIST_SAGA, (getAllPages:Function));
}
