// @flow
import { SetupCard } from "@fas/ui-core";
import type { StatelessFunctionalComponent, Node } from "react";
import React from "react";
import {
  Box, IconButton, Tooltip, Typography,
} from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import SimpleInputRender from "./SimpleInputRender";
import type { DocumentModifierProps as Props, RowRenderProps } from "./types/PageModifiers.types";
import ChipsInputRender from "./ChipsInputRender";
import PageCrossingRender from "./PageCrossingRender";
import type { Modifier } from "../../reducers/pageModifiers/reducer";

function getRenderComponent(name: string, type: string): StatelessFunctionalComponent<RowRenderProps> {
  const componentNameMap: {[string]: StatelessFunctionalComponent<RowRenderProps>} = {
    meta_keywords: ChipsInputRender,
  };
  const componentTypeMap: {[string]: StatelessFunctionalComponent<RowRenderProps>} = {
    input: SimpleInputRender,
    textarea: (props: RowRenderProps): Node => <SimpleInputRender {...props} multiline />,
    html: PageCrossingRender,
    pageCrossing: PageCrossingRender,
  };

  return componentNameMap[name] || componentTypeMap[type] || SimpleInputRender;
}

function PageModifiers({
  errors,
  onSetModifierByName,
  pageModifiers,
  defaultPageModifiersName,
  onRemoveModifier,
}: Props) {
  return (
    <SetupCard title="Document Modifiers">
      {
        !pageModifiers.length ? (
          <Typography color="textSecondary">No new modifiers have been added yet</Typography>
        ) : (
          pageModifiers.map(({
            name, content, description, type,
          }: Modifier): Node => {
            const Component: StatelessFunctionalComponent<RowRenderProps> = getRenderComponent(name, type);
            const cantRemoveModifier: boolean = defaultPageModifiersName.includes(name);
            return (
              <Box key={name} width={1} display="flex" justifyContent="space-between" alignItems="center">
                <Box width={1}>
                  <Component
                    title={`{{${name}}}`}
                    value={content}
                    modifier={name}
                    description={description}
                    errors={errors}
                    onChange={onSetModifierByName}
                    inputProps={{ maxLength: "255" }}
                    dataTestId={`modifier-${name}-field`}
                  />
                </Box>
                {onRemoveModifier && (
                  <Box pt={1}>
                    <Tooltip title={`Remove modifier {{${name}}}`} disabled={cantRemoveModifier}>
                      <IconButton
                        component={cantRemoveModifier ? "div" : undefined}
                        onClick={() => !cantRemoveModifier && onRemoveModifier(name)}
                        data-testid={`modifier-remove-${name}`}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Box>
            );
          })
        )
      }
    </SetupCard>
  );
}

export default PageModifiers;
