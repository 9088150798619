// @flow
/* eslint-disable import/max-dependencies */
import {
  takeEvery, put, call, select,
} from "redux-saga/effects";
import type { Response } from "@fas/ui-framework/lib/services/request";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import errorsTransform from "../../helpers/errorsTransform";
import { SAVE_REDIRECT_SAGA, SAVE_REDIRECT_LOADING } from "../../helpers/constants";
import setLoading from "../../actions/loading";
import RedirectApi from "../../services/redirectApi";
import type { SaveRedirectSagaAction } from "../../actions/redirect";
import type { RedirectLoadingTypes as LoadingTypes } from "../../actions/loading";
import { getHashValue } from "../../services/jusCan";
import { schema } from "./schema";

export function* makeFetch(action: SaveRedirectSagaAction): Saga<void> {
  const state:any = yield select();

  const validationResult = schema.validate({
    redirectInfo: state.redirect.toJS(),
  });
  const validationErrors = errorsTransform(validationResult);

  yield put(setErrors(validationErrors));
  if (Object.keys(validationErrors).length) {
    return;
  }

  try {
    yield put(setLoading<LoadingTypes>(SAVE_REDIRECT_LOADING, true));
    const { redirectId, redirect, navigate }: SaveRedirectSagaAction = action;

    if (redirectId) {
      yield call(RedirectApi.updateRedirect, redirectId, redirect);
      yield put(addNotification({ message: "Successfully saved", severity: "success" }));
    }
    else {
      const response: Response<
        mixed,
        { result: { id: string } }
      > = yield call(RedirectApi.createRedirect, redirect);

      const {
        data: {
          result: { id: newRedirectId },
        },
      }: Response<mixed, { result: { id: string } }> = response;

      yield put(addNotification({ message: "Successfully created", severity: "success" }));
      navigate(`/redirects/edit/${newRedirectId}?hashId=${getHashValue()}`);
    }
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to save", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading<LoadingTypes>(SAVE_REDIRECT_LOADING, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(SAVE_REDIRECT_SAGA, makeFetch);
}
