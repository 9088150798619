// @flow
import React from "react";
import DocumentTitle from "react-document-title";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Grid,
} from "@mui/material";
import {
  GeneralInfo,
  Settings,
} from "../../containers/LinkingInfoForm";

const useStyles = makeStyles((theme) => ({
  mainBackground: {
    background: theme.palette.background.mainBg,
    minHeight: "calc(100% - 64px)",
  },
}));

function Linking() {
  const { id: linkingId } = useParams();
  const classes = useStyles();

  return (
    <DocumentTitle title={linkingId ? "Update linking" : "Create linking"}>
      <Grid container direction="column" className={classes.mainBackground}>
        <Grid item>
          <GeneralInfo />
        </Grid>
        <Grid item>
          <Settings />
        </Grid>
      </Grid>
    </DocumentTitle>
  );
}

export default Linking;
