// @flow
import React from "react";
import { Box, Grid } from "@mui/material";
import { Multiselect } from "@fas/ui-core";
import { getErrorMessage, hasError } from "@fas/ui-framework/lib/services/generators";
import type { Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import Title from "./Title";
import type { RowRenderProps } from "./types/PageModifiers.types";

function ChipsInputRender({
  title,
  value,
  description,
  placeholder,
  modifier,
  errors,
  onChange,
  dataTestId,
}: RowRenderProps) {
  const values: Option[] = (value)
    .split(",")
    .map((i: string): string => i.trim())
    .filter((i: string): boolean => !!i)
    .map((i: string): Option => ({
      label: i,
      value: i,
    }));

  return (
    <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
      <Grid item xs={12}>
        <Title
          title={title}
          description={description}
        />
        <Box width={1} mb={2}>
          <Multiselect
            placeholder={placeholder}
            type="manualMultiple"
            options={[]}
            selectedOptions={values}
            onChange={(val: Array<Option>) => {
              onChange(modifier, (val || []).map(({ value: v }) => v.trim()).join(", "));
            }}
            dataTestId={dataTestId}
            error={{
              isError: hasError(modifier, errors),
              helperText: getErrorMessage(modifier, errors),
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default ChipsInputRender;
