// @flow
import { Record } from "immutable";
import type { RecordOf, RecordFactory } from "immutable";
import {
  PAGE_VERSIONS_CHANGE_VERSION_HTML,
  PAGE_VERSIONS_CHANGE_VERSION_CSS,
  PAGE_VERSIONS_CHANGE_VERSION_JS,
  PAGE_VERSIONS_SET_PAGE_VERSIONS,
} from "../../helpers/constants";
import { defaultHtml } from "../../helpers/pageDefaults";
import type {
  Actions,
  SetPageVersionsAction,
} from "../../actions/pageVersions";

export type Version = {|
  id: string,
  js: string,
  css: string,
  html: string,
  updatedAt: string,
|}

export type VersionType = "current" | "new" | "previous" | "old";

export type DefaultStateType = {
  [key: VersionType]: Version
};

const defaultState: DefaultStateType = {
  current: {
    id: "",
    js: "",
    css: "",
    html: "",
    updatedAt: "",
  },
  new: {
    id: "",
    js: "",
    css: "",
    html: defaultHtml,
    updatedAt: "",
  },
  previous: {
    id: "",
    js: "",
    css: "",
    html: "",
    updatedAt: "",
  },
  old: {
    id: "",
    js: "",
    css: "",
    html: "",
    updatedAt: "",
  },
};

export const makeState: RecordFactory<DefaultStateType> = Record(defaultState);

export type State = RecordOf<DefaultStateType>;

export const initialState: State = makeState({});

export type IncomingPageVersionState = {
  id: string,
  documentId: string,
  version: VersionType,
  content: string,
  css: string,
  js: string,
  description: string,
  createdAt: string,
  updatedAt: string,
  lastEditor: string,
  status?: string,
};

export const initPageVersionsState: () => State = () => initialState;

// eslint-disable-next-line default-param-last
export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case PAGE_VERSIONS_CHANGE_VERSION_HTML: {
      const { version, content } = action;
      return state.setIn([version, "html"], content);
    }
    case PAGE_VERSIONS_CHANGE_VERSION_CSS: {
      const { version, content } = action;
      return state.setIn([version, "css"], content);
    }
    case PAGE_VERSIONS_CHANGE_VERSION_JS: {
      const { version, content } = action;
      return state.setIn([version, "js"], content);
    }
    case PAGE_VERSIONS_SET_PAGE_VERSIONS: {
      const { payload: { versionCollection } }: SetPageVersionsAction = action;
      return state.withMutations((newState: State) => {
        versionCollection.forEach(({
          id,
          version,
          content,
          css,
          js,
          updatedAt,
        }: IncomingPageVersionState): State => newState.set((version: VersionType),
          {
            id,
            html: content,
            css,
            js,
            updatedAt,
          }));
      });
    }
    default:
      return state;
  }
};
