// @flow
/* eslint-disable import/max-dependencies */
import React, { useCallback, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { Dispatch } from "redux";
import {
  Container, Box, Toolbar, Card,
} from "@mui/material";
import { SetupCard } from "@fas/ui-core";
import debounce from "lodash.debounce";
import type { State as ErrorsState } from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import { Loader } from "@fas/ui-core/lib/Loader/Loader";
import CodeEditor from "../../components/CodeEditor";
import CodeEditorActionButtons from "../../components/CodeEditorActions";
import {
  getPageType,
  getPageCurrentVersionType,
  getPageVersionByType,
  getDocumentModifiers,
  getPageResources,
  getDefaultModifiersName,
  getPageInfo,
} from "../../selectors/page";
import {
  getErrors,
} from "../../selectors/errors";
import {
  removeModifier,
  setModifierByName,
} from "../../actions/pageModifiers";
import {
  changePageVersionHtml,
  changePageVersionCss,
  changePageVersionJs,
} from "../../actions/pageVersions";
import {
  addPageResource,
  removePageResource,
  removeAllPageResource,
  downloadPageResourcesSaga,
} from "../../actions/pageResources";
import { getPageSaga } from "../../actions/page";
import type {
  Actions as PageActions,
  GetPageSagaAction,
} from "../../actions/page";
import { pageBackupStore } from "../../pages/AppStoreWrapper/store";
import { setBackup, restoreBackup } from "../../actions/backup";
import type { Actions as BackupActions, SetBackupAction, RestoreBackupAction } from "../../actions/backup";
import PageModifiers from "../../components/PageModifiers";
import PageSettingsResources from "../../components/PageSettingsResources";

import type { State as PageStoreState } from "../../pages/AppStoreWrapper";
import type { Resource } from "../../reducers/pageResources";
import type { Version, VersionType } from "../../reducers/pageVersions";
import type { State as PageInfoState } from "../../reducers/pageInfo";
import type {
  Actions as PageVersionsActions,
  ChangePageVersionHtmlAction,
  ChangePageVersionCssAction,
  ChangePageVersionJsAction,
} from "../../actions/pageVersions";
import type {
  Actions as PageModifiersActions,
  RemoveModifierAction,
  SetModifierByName,
} from "../../actions/pageModifiers";
import type {
  Actions as PageResourcesActions,
  AddPageResourceAction,
  RemovePageResourceAction,
  RemoveAllPageResourceAction,
  ResourceFileFormats,
  DownloadPageResourcesSaga,
} from "../../actions/pageResources";
import type {
  Actions as AccountModuleActions,
} from "../../actions/accountModules";

import { getResourceTypes } from "../../selectors/accountModules";
import {
  getIsPageLoading,
  getIsResourceDownloading,
} from "../../selectors/loading";
import { type Modifier, DefaultState as defaultModifiers } from "../../reducers/pageModifiers/reducer";
import ModifierFormContainer from "../ModifierForm";
import type { DownloadResource } from "../../components/PageSettingsResources/types/PageSettingsResources.types";

type OwnProps = $ReadOnly<{|
  store?: Object,
|}>;

type UseState<T> = [T, ((T => T) | T) => void];

type StateToProps = $ReadOnly<{|
  pageModifiers: Array<Modifier>,
  defaultPageModifiersName: Array<string>,
  pageResources: Array<Resource>,
  errors: ErrorsState,
  pageType:string,
  versionType: VersionType,
  pageVersion: Version,
  pageInfo: PageInfoState,
  fileFormats: Array<ResourceFileFormats>,
  isPageLoading: boolean,
  isResourceDownloading: boolean,
|}>;
type DispatchToProps = $ReadOnly<{|
  onSetModifierByName: (name: string, content: string) => SetModifierByName,
  onRemoveModifier: (name: string) => RemoveModifierAction,
  onChangePageVersionHtml:(version: VersionType, content: string) => ChangePageVersionHtmlAction,
  onChangePageVersionCss:(version: VersionType, content: string) => ChangePageVersionCssAction,
  onChangePageVersionJs:(version: VersionType, content: string) => ChangePageVersionJsAction,
  onAddDocumentResource: (content: Resource) => AddPageResourceAction,
  onRemoveDocumentResource: (index: number) => RemovePageResourceAction,
  onRemoveAllDocumentResource: () => RemoveAllPageResourceAction,
  onDownloadPageResources: (payload: DownloadResource[]) => DownloadPageResourcesSaga,
  onGetPage: (id: string, versionId?: string | null) => GetPageSagaAction,
  onSetBackup: (backup: Array<string>) => SetBackupAction,
  onRestoreBackup: () => RestoreBackupAction,
|}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateToProps,
  ...DispatchToProps,
|}>;

const mapStateToProps: (state: PageStoreState) => StateToProps = (state) => {
  const versionType: VersionType = getPageCurrentVersionType(state);
  const pageVersion: Version = getPageVersionByType(state, versionType);
  const pageInfo: PageInfoState = getPageInfo(state);

  return {
    pageModifiers: getDocumentModifiers(state),
    defaultPageModifiersName: getDefaultModifiersName(),
    pageResources: getPageResources(state),
    errors: getErrors(state),
    pageType: getPageType(state),
    versionType,
    pageVersion,
    pageInfo,
    fileFormats: getResourceTypes(state),
    isPageLoading: getIsPageLoading(state),
    isResourceDownloading: getIsResourceDownloading(state),
  };
};

const mapDispatchToProps: (
  Dispatch<PageVersionsActions
  | PageModifiersActions
  | PageResourcesActions
  | PageActions
  | BackupActions
  | AccountModuleActions>
) => DispatchToProps = (dispatch) => bindActionCreators({
  onSetModifierByName: setModifierByName,
  onChangePageVersionHtml: changePageVersionHtml,
  onChangePageVersionCss: changePageVersionCss,
  onChangePageVersionJs: changePageVersionJs,
  onAddDocumentResource: addPageResource,
  onRemoveDocumentResource: removePageResource,
  onRemoveAllDocumentResource: removeAllPageResource,
  onRemoveModifier: removeModifier,
  onDownloadPageResources: downloadPageResourcesSaga,
  onGetPage: getPageSaga,
  onSetBackup: setBackup,
  onRestoreBackup: restoreBackup,
}, dispatch);

function PageSettingsContainer({
  pageModifiers,
  defaultPageModifiersName,
  pageResources,
  errors,
  onSetModifierByName,
  onChangePageVersionHtml,
  onChangePageVersionCss,
  onChangePageVersionJs,
  onAddDocumentResource,
  onRemoveDocumentResource,
  onRemoveAllDocumentResource,
  onDownloadPageResources,
  pageType,
  versionType,
  pageVersion,
  fileFormats,
  isPageLoading,
  isResourceDownloading,
  onRemoveModifier,
  onGetPage,
  onSetBackup,
  onRestoreBackup,
  pageInfo,
}: Props) {
  const {
    html,
    css,
    js,
  }: Version = pageVersion;

  const location = useLocation();

  const { id }: { id: ?string } = useParams();
  const searchParams: URLSearchParams = new URLSearchParams(location.search);
  const versionId: string | null = searchParams.get("versionId");

  useEffect(() => {
    onSetBackup(pageBackupStore);
    if (id) onGetPage(id, versionId);
    return () => {
      onRestoreBackup();
    };
  }, [id, onGetPage, versionId]);

  const wait: number = 600;
  const debouncedOnChangeDocumentHtml = useCallback(debounce(onChangePageVersionHtml, wait), [onChangePageVersionHtml]);
  const debouncedOnChangeDocumentCss = useCallback(debounce(onChangePageVersionCss, wait), [onChangePageVersionCss]);
  const debouncedOnChangeDocumentJs = useCallback(debounce(onChangePageVersionJs, wait), [onChangePageVersionJs]);
  const [modifiersToRender, setModifiers]: UseState<Array<Modifier>> = useState([]);

  useEffect(() => {
    if (pageType === "template") {
      setModifiers(pageModifiers.slice(defaultModifiers.allIds.size));
    }
    else {
      setModifiers(pageModifiers);
    }
  }, [pageType, pageModifiers]);

  const handleHtmlEditorChange: (string) => void = (value) => {
    debouncedOnChangeDocumentHtml(versionType, value);
  };

  const handleCssEditorChange: (string) => void = (value) => {
    debouncedOnChangeDocumentCss(versionType, value);
  };

  const handleJsEditorChange: (string) => void = (value) => {
    debouncedOnChangeDocumentJs(versionType, value);
  };

  return (
    <Container maxWidth="xl">
      <Loader loading={isPageLoading}>
        <Box display="flex" flexDirection={pageType === "pft" ? "column-reverse" : "column"}>
          <Box>
            {
              pageType !== "pft" && (
                <SetupCard title={`HTML Editor - ${pageInfo.name}`}>
                  <CodeEditor
                    name="html-editor"
                    height="400px"
                    language="html"
                    theme="dark"
                    value={html}
                    onChange={handleHtmlEditorChange}
                    EditorActions={CodeEditorActionButtons}
                  />
                </SetupCard>
              )
            }
            {
              pageType !== "custom" && (
                <SetupCard title="CSS Editor">
                  <CodeEditor
                    name="css-editor"
                    height="400px"
                    language="css"
                    theme="dark"
                    value={css}
                    onChange={handleCssEditorChange}
                    EditorActions={CodeEditorActionButtons}
                  />
                </SetupCard>
              )
            }
            {
              pageType !== "custom" && (
                <SetupCard title="JavaScript Editor">
                  <CodeEditor
                    name="js-editor"
                    value={js}
                    height="400px"
                    theme="dark"
                    onChange={handleJsEditorChange}
                    language="javascript"
                    EditorActions={CodeEditorActionButtons}
                  />
                </SetupCard>
              )
            }
            {
              pageType !== "custom" && (
                <Box data-testid="pages-page-settings-dropzone-content">
                  <PageSettingsResources
                    resources={pageResources}
                    onAddResource={onAddDocumentResource}
                    onRemoveResource={onRemoveDocumentResource}
                    onRemoveAllResource={onRemoveAllDocumentResource}
                    onDownloadResources={onDownloadPageResources}
                    accept={fileFormats}
                    isResourceDownloading={isResourceDownloading}
                  />
                </Box>
              )
            }
          </Box>
          <Box>
            {["template", "pft"].includes(pageType) && (
              <Box data-testid="pages-page-settings">
                <PageModifiers
                  pageModifiers={modifiersToRender}
                  defaultPageModifiersName={defaultPageModifiersName}
                  // $FlowFixMe
                  errors={errors.toJS()}
                  onSetModifierByName={onSetModifierByName}
                  onRemoveModifier={pageType === "template" ? onRemoveModifier : undefined}
                />
                {["template"].includes(pageType) && (
                  <Box component={Card} px={3} py={2} data-testid="pages-page-add-modifier">
                    <ModifierFormContainer />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
        <Toolbar />
      </Loader>
    </Container>
  );
}

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(PageSettingsContainer);
