// @flow
import React from "react";
import { Link as RoutingLink } from "react-router-dom";
import { Link, Typography } from "@mui/material";

function Title() {
  return (
    <Typography variant="h3" textAlign="center" mb={2}>
      Select account or
      <Link to="/createAccount" component={RoutingLink} data-testid="create-account">create </Link>
      new
    </Typography>
  );
}

export default Title;
