// @flow
import React from "react";
import {
  Box, Tooltip, Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

type Props = {
  title: string,
  description: string,
}

const useStyles = makeStyles(() => ({
  field: {
    wordBreak: "break-all",
  },
  box: {
    minHeight: "26px",
    paddingBottom: "4px",
    boxSizing: "border-box",
  },
}));

function Title({
  title,
  description,
}: Props) {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" classes={{ root: classes.box }}>
      <Box display="flex">
        <Box pr={1}>
          <Tooltip title={description}>
            <HelpOutlineIcon />
          </Tooltip>
        </Box>
        <Typography classes={{ root: classes.field }}>
          {title}
        </Typography>
      </Box>
    </Box>
  );
}

export default Title;
