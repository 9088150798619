// @flow
import {
  CHANGE_PAGE_NAME,
  CHANGE_PAGE_TYPE,
  SET_PAGE,
  SET_PAGE_VERSION,
  CHANGE_PAGE_URL,
  CHANGE_PAGE_PFT_TEMPLATE,
  CHANGE_PAGE_TAGS,
  CHANGE_PAGE_TYPE_SAGA,
} from "../../helpers/constants";
import type { IncomingPageInfoState, PageType } from "../../reducers/pageInfo";
import type { VersionType } from "../../reducers/pageVersions";

export type ChangePageNameAction = {|
  type: typeof CHANGE_PAGE_NAME,
  name: string,
|};

export type ChangePageTypeAction = {|
  type: typeof CHANGE_PAGE_TYPE,
  pageType: PageType,
|};

export type ChangePageUrlAction = {|
  type: typeof CHANGE_PAGE_URL,
  url: string,
|};

export type ChangePagePftTemplateAction = {|
  type: typeof CHANGE_PAGE_PFT_TEMPLATE,
  templateId: string,
  templateName: string,
|};

export type Tag = {|
  label: string,
  value: string,
|};

export type SetPageAction = {|
  type: typeof SET_PAGE,
  payload: IncomingPageInfoState,
|}

export type SetPageVersionAction = {|
  type: typeof SET_PAGE_VERSION,
  versionType: VersionType,
  versionId: string,
|}

export type ChangePageTags = {|
  type: typeof CHANGE_PAGE_TAGS,
  tags: Array<string>,
|}

export type ChangePageTypeSaga = {|
  type: typeof CHANGE_PAGE_TYPE_SAGA,
  pageType: PageType,
|};

export type Actions =
  ChangePageNameAction
  | ChangePageTypeAction
  | ChangePageUrlAction
  | ChangePagePftTemplateAction
  | SetPageAction
  | SetPageVersionAction
  | ChangePageTags
  | ChangePageTypeSaga;

export const changePageName: (
  name: string
) => ChangePageNameAction = (
  name: string
): ChangePageNameAction => ({
  type: CHANGE_PAGE_NAME,
  name,
});

export const changePageType: (
  pageType: PageType
) => ChangePageTypeAction = (
  pageType: PageType
): ChangePageTypeAction => ({
  type: CHANGE_PAGE_TYPE,
  pageType,
});

export const changePageUrl: (
  url: string
) => ChangePageUrlAction = (
  url: string
): ChangePageUrlAction => ({
  type: CHANGE_PAGE_URL,
  url,
});

export const changePagePftTemplate: (
  templateId: string,
  templateName: string
) => ChangePagePftTemplateAction = (
  templateId: string,
  templateName: string
): ChangePagePftTemplateAction => ({
  type: CHANGE_PAGE_PFT_TEMPLATE,
  templateId,
  templateName,
});

export const setPage: (
  payload: IncomingPageInfoState
) => SetPageAction = (
  payload: IncomingPageInfoState
): SetPageAction => ({
  type: SET_PAGE,
  payload,
});

export const setPageVersion: (
  versionType: VersionType,
  versionId: string
) => SetPageVersionAction = (
  versionType: VersionType,
  versionId: string
): SetPageVersionAction => ({
  type: SET_PAGE_VERSION,
  versionType,
  versionId,
});

export const changePageTags: (tags:Array<string>) => ChangePageTags = (tags) => ({
  type: CHANGE_PAGE_TAGS,
  tags,
});

export const changePageTypeSaga: (pageType: PageType)=>ChangePageTypeSaga = (pageType: PageType) => ({
  type: CHANGE_PAGE_TYPE_SAGA,
  pageType,
});
