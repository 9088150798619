const prefix = "/api/v1/jus";

export default (baseUrl) => ({
  baseUrl,
  appLinks: {
    dmp: "https://rel-admin-dmp.insigit.com/",
    jus: "https://jus.rel.insigit.com/",
    cpa: "https://cpa-admin.rel.insigit.com/",
    webPush: "https://webpush.rel.noclef.com/",
    adsBox: "https://adsbox.rel.insigit.com/",
    reports: "https://reports.rel.insigit.com/managersReport",
    crm: "https://crm.rel.insigit.com/",
  },
  links: {
    accountView: "/accounts/view",
    documentList: "/pages/list",
    redirectList: "/redirects/list",
    linkingList: "/linking/list",
  },
  endpoints: {
    get: {
      documentsList: `${baseUrl}${prefix}/documents/list`,
      apiKey: `${baseUrl}/api/login`,
      loginUrl: `${baseUrl}/login`,
      logoutUrl: `${baseUrl}/logout`,
      accountList: `${baseUrl}${prefix}/accounts/list`,
      redirectList: `${baseUrl}${prefix}/redirects/list`,
      resourceList: `${baseUrl}${prefix}/resources/{versionId}`,
      page: `${baseUrl}${prefix}/documents/{id}`,
      redirect: `${baseUrl}${prefix}/redirects/{id}`,
      account: `${baseUrl}${prefix}/accounts/{id}`,
      usersList: `${baseUrl}${prefix}/users`,
      pageVersionPreview: `${baseUrl}${prefix}/documents/preview/{documentId}`,
      documentsViewAll: `${baseUrl}${prefix}/documents/viewAll`,
      linkingList: `${baseUrl}${prefix}/linking/list`,
      linking: `${baseUrl}${prefix}/linking/{linkingId}`,
      tags: `${baseUrl}${prefix}/tags`,
      accountModules: `${baseUrl}${prefix}/accountModules`,
      permissions: `${baseUrl}${prefix}/userPermissions`,
    },
    put: {
      account: `${baseUrl}${prefix}/accounts/{id}`,
      redirect: `${baseUrl}${prefix}/redirects/{id}`,
      page: `${baseUrl}${prefix}/documents/{id}`,
      users: `${baseUrl}${prefix}/users/{id}`,
      linking: `${baseUrl}${prefix}/linking/{linkingId}`,
    },
    post: {
      account: `${baseUrl}${prefix}/accounts`,
      page: `${baseUrl}${prefix}/documents`,
      pageVersion: `${baseUrl}${prefix}/documentVersion`,
      redirect: `${baseUrl}${prefix}/redirects`,
      clonePage: `${baseUrl}${prefix}/documents/clone`,
      exportPage: `${baseUrl}${prefix}/documents/export`,
      importPage: `${baseUrl}${prefix}/documents/import`,
      archivePage: `${baseUrl}${prefix}/documents/archive/{documentId}`,
      linking: `${baseUrl}${prefix}/linking`,
      downloadPageResources: `${baseUrl}${prefix}/resources/content`,
    },
    delete: {
      account: `${baseUrl}${prefix}/accounts/{id}`,
      redirect: `${baseUrl}${prefix}/redirects`,
      documentsAll: `${baseUrl}${prefix}/documents/deleteAll`,
      linking: `${baseUrl}${prefix}/linking/{linkingId}`,
      linkingBulk: `${baseUrl}${prefix}/linking/bulkDelete`,
    },
    redirect: {},
  },
});
