// @flow
import {
  takeEvery, call, put,
} from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import type { Saga } from "redux-saga";
import { GET_REDIRECT_SAGA, GET_REDIRECT_LOADING } from "../../helpers/constants";
import RedirectApi from "../../services/redirectApi";
import { setRedirect } from "../../actions/redirectInfo";
import setLoading from "../../actions/loading";
import type { GetRedirectSagaAction } from "../../actions/redirect";
import type { RedirectLoadingTypes as LoadingTypes } from "../../actions/loading";

export function* makeFetch(action: GetRedirectSagaAction): Saga<void> {
  try {
    yield put(setLoading<LoadingTypes>(GET_REDIRECT_LOADING, true));
    const { id }: GetRedirectSagaAction = action;

    const { data: { result: redirect } } = yield call(RedirectApi.getRedirect, id);

    yield put(setRedirect(redirect));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch redirect", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading<LoadingTypes>(GET_REDIRECT_LOADING, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_REDIRECT_SAGA, makeFetch);
}
