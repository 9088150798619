// @flow
import {
  GET_REDIRECT_LIST_SAGA,
  DELETE_REDIRECT_LIST_SAGA,
  SET_REDIRECT_LIST,
} from "../../helpers/constants";
import type { Redirect } from "../../reducers/redirectList";

export type GetRedirectListSaga = {|
  type: typeof GET_REDIRECT_LIST_SAGA,
|}

export type DeleteRedirectListSaga = {|
  type: typeof DELETE_REDIRECT_LIST_SAGA,
|}

export type SetRedirectList = {|
  type: typeof SET_REDIRECT_LIST,
  payload: { data: Redirect[], count: number}
|}

export type Actions =
  GetRedirectListSaga
  | DeleteRedirectListSaga
  | SetRedirectList

export const getRedirectList = (): GetRedirectListSaga => ({
  type: GET_REDIRECT_LIST_SAGA,
});

export const deleteRedirect = (): DeleteRedirectListSaga => ({
  type: DELETE_REDIRECT_LIST_SAGA,
});

export const setRedirectList = (payload: { data: Redirect[], count: number}): SetRedirectList => ({
  type: SET_REDIRECT_LIST,
  payload,
});
