// @flow
import type { Saga } from "redux-saga";
import {
  takeEvery, call, put,
} from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { IMPORT_PAGE_SAGA, PAGE_IMPORTING_LOADING } from "../../helpers/constants";
import type { StartImportPage } from "../../actions/pageActions/actions";
import { importPage } from "../../services/documentApi/document";
import { closeImportPageDialog } from "../../actions/pageActions";
import { getPagesListSaga } from "../../actions/pages";
import setLoading from "../../actions/loading/actions";
import type { PageImportingTypes as LoadingTypes } from "../../actions/loading/actions";

export function* makeFetch(action: StartImportPage): Saga<void> {
  try {
    yield put(setLoading<LoadingTypes>(PAGE_IMPORTING_LOADING, true));
    yield call(importPage, action.file, action.fileType, action.activate);
    yield put(closeImportPageDialog());

    yield put(getPagesListSaga());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to import page", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading<LoadingTypes>(PAGE_IMPORTING_LOADING, false));
  }
}

export default function* clonePageSaga(): Saga<void> {
  yield takeEvery(IMPORT_PAGE_SAGA, makeFetch);
}
