// @flow
import { type NavigateFunction } from "react-router";
import {
  GET_PAGE_SAGA,
  SAVE_PAGE_SAGA,
  CREATE_PAGE_SAGA,
  GET_PAGE_VERSION_PREVIEW,
} from "../../helpers/constants";

export type GetPageSagaAction = {|
  type: typeof GET_PAGE_SAGA,
  id: string,
  versionId?: string | null,
|};

export type SavePageSagaOptions = {|
  isPreviewEnabled?: boolean,
  activateAfterSave?: boolean,
|};
export type SavePageSagaAction = {|
  type: typeof SAVE_PAGE_SAGA,
  ...SavePageSagaOptions,
|};

export type CreatePageSagaAction = {|
  type: typeof CREATE_PAGE_SAGA,
  navigate: NavigateFunction,
|};

export type GetPageVerisionPreviewSagaAction = {|
  type: typeof GET_PAGE_VERSION_PREVIEW,
  pageId: string,
  versionId: string,
|};

export type Actions =
  | GetPageSagaAction
  | SavePageSagaAction
  | CreatePageSagaAction
  | GetPageVerisionPreviewSagaAction;

export const getPageSaga: (
  id: string,
  versionId?: string | null
) => GetPageSagaAction = (
  id: string,
  versionId?: string | null
): GetPageSagaAction => ({
  type: GET_PAGE_SAGA,
  id,
  versionId,
});

export const savePageSaga: (
  options?: SavePageSagaOptions
) => SavePageSagaAction = ({
  isPreviewEnabled,
  activateAfterSave,
}: SavePageSagaOptions = {}): SavePageSagaAction => ({
  type: SAVE_PAGE_SAGA,
  isPreviewEnabled,
  activateAfterSave,
});

export const createPageSaga: (navigate: NavigateFunction) => CreatePageSagaAction = (navigate):
CreatePageSagaAction => ({
  type: CREATE_PAGE_SAGA,
  navigate,
});

export const getPageVerisionPreviewSaga: (
  pageId: string,
  versionId: string
) => GetPageVerisionPreviewSagaAction = (
  pageId: string,
  versionId: string
) => ({
  type: GET_PAGE_VERSION_PREVIEW,
  pageId,
  versionId,
});
