// @flow
import React from "react";
import { connect } from "react-redux";
import ExportPageFd from "../../components/ExportPageFd";
import { closeExportPageDialog, startExportPage } from "../../actions/pageActions";
import type { ExportType, PageTypes } from "../../components/ExportPageFd/types/ExportPageFd.types";
import type { Page } from "../../reducers/exportPage/reducer";
import { getIsPageExporting } from "../../selectors/loading";
import { getIsPageExportingOpen, getExportingSelectedPage } from "../../selectors/pageActions";

type OwnProps = $ReadOnly<{|
  store?: Object,
|}>;

type Props = $ReadOnly<{|
...OwnProps,
  isOpen: boolean,
  isPageExporting: boolean,
  selectedPage: Page,
  startExport: (type: ExportType, ids: Array<string>, pageType:PageTypes) => void,
  closeDialog: () => void,
|}>;

const mapStateToProps = (state) => ({
  isOpen: getIsPageExportingOpen(state),
  selectedPage: getExportingSelectedPage(state),
  isPageExporting: getIsPageExporting(state),
});

const mapDispatchToProps = (dispatch) => ({
  startExport: (type: ExportType, ids: Array<string>, pageType: PageTypes) => dispatch(
    startExportPage(type, ids, pageType)
  ),
  closeDialog: () => dispatch(
    closeExportPageDialog()
  ),
});

function ExportPageDialog({
  isOpen,
  isPageExporting,
  selectedPage,
  startExport,
  closeDialog,
}: Props) {
  return (
    <ExportPageFd
      open={isOpen}
      exporting={isPageExporting}
      page={selectedPage}
      onApply={startExport}
      onClose={closeDialog}
    />
  );
}

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(ExportPageDialog);
