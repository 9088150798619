// @flow
import {
  takeEvery, call, all, put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table/actions";
import { PAGES_LIST, PAGES_LIST_BULK_DELETE_SAGA } from "../../helpers/constants";
import type { PagesListBulkDeleteSaga } from "../../actions/pages/actions";
import { bulkDelete } from "../../services/pagesApi";
import { getPagesListSaga, removeSelectedPage } from "../../actions/pages";

export function* makeFetch(action: PagesListBulkDeleteSaga): Saga<void> {
  try {
    const { selectedPages } = action;
    yield call(bulkDelete, selectedPages);

    yield put(setTableSelection(PAGES_LIST, []));
    yield all(selectedPages.map((x) => put(removeSelectedPage(x))));

    yield put(addNotification({ message: "Pages deleted", severity: "success" }));

    yield put(getPagesListSaga());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to delete", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(PAGES_LIST_BULK_DELETE_SAGA, makeFetch);
}
