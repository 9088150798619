// @flow
/* eslint-disable import/max-dependencies */
import {
  TableActionGroup,
  TableActions,
  SimpleTableActionButton,
  ConfirmTableActionButton,
} from "@fas/ui-core";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import {
  Edit, Visibility, Delete, Check, FileCopy, GetApp, Archive,
} from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import type { DataItem } from "@fas/ui-framework/lib/redux/reducers/table";
import type { PageTypes } from "../ExportPageFd/types/ExportPageFd.types";
import Can from "../Can";
import { activateDocumentSaga, archivePageSaga } from "../../actions/pages/actions";
import { pagesListBulkDeleteSaga } from "../../actions/pages";
import { getPageVerisionPreviewSaga } from "../../actions/page";
import { openClonePageDialog, openExportPageDialog } from "../../actions/pageActions";
import externalRedirect from "../../helpers/ExternalRedirect";
import { getHashValue } from "../../services/jusCan";
import { PAGES_LIST } from "../../helpers/constants";

type Props = {};

type PropsActions = {
  items: Array<DataItem>,
};

export function PreviewAction({ items }: PropsActions) {
  const dispatch: <A>(A) => A = useDispatch();

  const handlePreview = (documentId, versionCollection) => {
    const { id: versionId } = versionCollection.filter(({ version }) => version === "current")[0];
    dispatch(getPageVerisionPreviewSaga(documentId, versionId));
  };

  return (
    <SimpleTableActionButton
      tooltip="Preview"
      onClick={() => items.map(({ documentId, versionCollection }) => handlePreview(documentId, versionCollection))}
      Icon={<Visibility />}
    />
  );
}

export function EditAction({ items }: PropsActions) {
  const hashId = getHashValue();

  const handleEdit = (documentId) => {
    externalRedirect({ to: `/pages/edit/${documentId}?hashId=${hashId}`, target: "_blank" });
  };

  return (
    <SimpleTableActionButton
      tooltip="Edit"
      onClick={() => items.map(({ documentId }) => handleEdit(documentId))}
      Icon={<Edit />}
    />
  );
}

export function EditGeneralAction({ items }: PropsActions) {
  const hashId = getHashValue();

  const handleEditGeneralInfo = (documentId) => {
    externalRedirect({ to: `/pages/editInfo/${documentId}?hashId=${hashId}`, target: "_blank" });
  };

  return (
    <SimpleTableActionButton
      tooltip="EditGeneralInfo"
      onClick={() => items.map(({ documentId }) => handleEditGeneralInfo(documentId))}
      Icon={<SettingsIcon />}
    />
  );
}

export function ActivateAction({ items }: PropsActions) {
  const dispatch: <A>(A) => A = useDispatch();
  const handleActivate: (id: string) => mixed = (id) => dispatch(activateDocumentSaga(id));

  const isActive: boolean = items[0].versionCollection.some(
    ({ version }) => version === "current"
  );

  const isHaveNew: boolean = items[0].versionCollection.some(
    ({ version }) => version === "new"
  );

  const isDisabled = items.length > 1 || isActive || !isHaveNew;

  return (
    <Can permissions={["documentFactory.activateVersion"]}>
      <ConfirmTableActionButton
        tooltip="Activate"
        viewPath="activate" // ToDO fix this with normal prop and key id https://jira.togethernetworks.com/browse/MTU-71122
        items={[items[0]]}
        disabled={isDisabled}
        onClick={() => handleActivate(items[0].documentId)}
        Icon={<Check />}
        confirmTitle="Activate items "
        confirmText="Are you sure you want to activate "
      />
    </Can>
  );
}

export function DeleteAction({ items }: PropsActions) {
  const dispatch: <A>(A) => A = useDispatch();
  const handleBulkDelete: (Array<string>) => mixed = (ids) => dispatch(pagesListBulkDeleteSaga(ids));

  return (
    <Can permissions={["documentFactory.remove"]}>
      <ConfirmTableActionButton
        tooltip="Delete"
        viewPath="delete" // ToDO fix this with normal prop and key id https://jira.togethernetworks.com/browse/MTU-71122
        items={items.map((i) => ({ id: i.documentId, name: i.name }))}
        onClick={() => handleBulkDelete(items.map((i) => i.documentId))}
        Icon={<Delete />}
        confirmTitle="Delete items "
        confirmText="Are you sure you want to delete "
      />
    </Can>
  );
}

export function CloneAction({ items }: PropsActions) {
  const dispatch: <A>(A) => A = useDispatch();
  const handleClone: (string, string, PageTypes, string) => mixed = (pageId, pageName, pageType, pageUrl) => dispatch(
    openClonePageDialog(pageId, pageName, pageType, pageUrl)
  );

  return (
    <SimpleTableActionButton
      tooltip="Clone"
      disabled={items.length > 1}
      onClick={() => {
        const { aliasCollection } = items[0];
        const last = aliasCollection[aliasCollection.length - 1];
        const url: string = last && last.alias;
        handleClone(items[0].documentId, items[0].name, items[0].type, url);
      }}
      Icon={<FileCopy />}
    />
  );
}

export function ExportAction({ items }: PropsActions) {
  const dispatch: <A>(A) => A = useDispatch();
  const handleExport: (string, string, PageTypes) => mixed = (pageId, pageName, pageType) => dispatch(
    openExportPageDialog(pageId, pageName, pageType)
  );
  const isActive: boolean = items[0].versionCollection.some(
    ({ version }) => version === "current"
  );

  const isDisabled = items.length > 1 || (!(["page", "pft"].includes(items[0].type) && isActive));

  return (
    <SimpleTableActionButton
      tooltip="Export"
      disabled={isDisabled}
      onClick={() => handleExport(items[0].documentId, items[0].name, items[0].type)}
      Icon={<GetApp />}
    />
  );
}

export function ArchiveAction({ items }: PropsActions) {
  const dispatch: <A>(A) => A = useDispatch();
  const handleArchive: (string) => mixed = (id) => dispatch(
    archivePageSaga(id)
  );

  return (
    <Can permissions={["documentFactory.remove"]}>
      <ConfirmTableActionButton
        tooltip="Archive"
        viewPath="archive" // ToDO fix this with normal prop and key id https://jira.togethernetworks.com/browse/MTU-71122
        items={items.map((i) => ({ id: i.documentId, name: i.name }))}
        disabled={items.length > 1}
        onClick={() => handleArchive(items[0].documentId)}
        Icon={<Archive />}
        confirmTitle="Archive items "
        confirmText="Are you sure you want to archive "
      />
    </Can>
  );
}

function PagesActions({}: Props) {
  const items = useSelector(
    (state) => getTableSelections(state, PAGES_LIST)
  );

  return (
    <TableActions>
      <TableActionGroup>

        <EditAction items={items} />

        <EditGeneralAction items={items} />

        <PreviewAction items={items} />

        <ActivateAction items={items} />

        <DeleteAction items={items} />

        <CloneAction items={items} />

        <ExportAction items={items} />

        <ArchiveAction items={items} />

      </TableActionGroup>
    </TableActions>
  );
}

export default PagesActions;
