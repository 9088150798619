// @flow
import type { RecordOf, RecordFactory, Map } from "immutable";
import { Record } from "immutable";
// import type { Reducer } from "redux";
import {
  SET_PAGES_LIST,
  SET_PAGES_LIST_COUNT,
  ADD_SELECTED_PAGE,
  REMOVE_SELECTED_PAGE,
  CHANGE_DOCUMENT_VERSION_STATUS,
  PAGES_LIST_SET_PAGE,
  PAGES_LIST_SET_SORTING,
  PAGES_LIST_SET_FILTERS,
  PAGES_LIST_SET_PAGE_SIZE,
} from "../../helpers/constants";
import type { Page } from "../../components/PagesList/types/PagesList.types";
import type {
  Actions,
  SetPagesList,
  SetPagesListCount,
  AddSelectedPage,
  RemoveSelectedPage,
} from "../../actions/pages";

type SortingValues = "asc" | "desc";
export type DefaultStateType = {
  data: Array<Page>,
  selected: Array<string>,
  count: number,
  page: number,
  pageSize: number,
  filters: { [key: $Keys<Page>]: string },
  sorting: { [key: $Keys<Page>]: string },
};

export const defaultState: DefaultStateType = {
  data: [],
  selected: [],
  count: 0,
  page: 1,
  pageSize: 10,
  filters: {},
  sorting: {},
};

export const makeState: RecordFactory<DefaultStateType> = Record(defaultState);

export type State = RecordOf<DefaultStateType>;

export const initialState: State = makeState({});

export function initPagesState(
  args: {
    page?: number,
    pageSize?: number,
    filters?: Map<$Keys<Page>, string>,
    sorting?: Map<$Keys<Page>, SortingValues>,
  } = {}
): State {
  return makeState(args);
}

// eslint-disable-next-line default-param-last
export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_PAGES_LIST: {
      const { data }: SetPagesList = action;
      return state.set("data", data);
    }
    case SET_PAGES_LIST_COUNT: {
      const { count }: SetPagesListCount = action;
      return state.set("count", count);
    }
    case ADD_SELECTED_PAGE: {
      const { selectedPage }: AddSelectedPage = action;
      return state.update(
        "selected",
        (selected: Array<string>): Array<string> => [...selected, selectedPage]
      );
    }
    case REMOVE_SELECTED_PAGE: {
      const { removedPage }: RemoveSelectedPage = action;
      return state.update(
        "selected",
        (selected: Array<string>): Array<string> => selected.filter((x: string): boolean => x !== removedPage)
      );
    }
    case CHANGE_DOCUMENT_VERSION_STATUS: {
      const { documentId, status } = action;
      const documentIndex = state
        .get("data")
        .findIndex(({ documentId: docId }) => docId === documentId);
      return state.updateIn(
        ["data", documentIndex, "versionCollection", 0],
        (x) => ({ ...x, version: status })
      );
    }
    case PAGES_LIST_SET_PAGE: {
      const { page } = action;
      return state.set("page", page);
    }
    case PAGES_LIST_SET_SORTING: {
      const { sorting } = action;
      return state.set("sorting", sorting);
    }
    case PAGES_LIST_SET_FILTERS: {
      const { filters } = action;
      return state.set("filters", filters);
    }
    case PAGES_LIST_SET_PAGE_SIZE: {
      const { pageSize } = action;
      return state.set("pageSize", pageSize).set("page", 1);
    }
    default:
      return state;
  }
};
