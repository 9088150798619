// @flow
import { Record } from "immutable";
import type { RecordFactory, RecordOf } from "immutable";
import {
  OPEN_IMPORT_PAGE_DIALOG,
  CLOSE_IMPORT_PAGE_DIALOG,
} from "../../helpers/constants";
import type { Actions } from "../../actions/pageActions";

export type DefaultState = {
  isOpen: boolean,
}

const defaultValues: DefaultState = {
  isOpen: false,
};

export const makeState: RecordFactory<DefaultState> = Record(defaultValues);

export type State = RecordOf<DefaultState>;

export const initialState: State = makeState({});

export function initImportPageState(): State {
  return initialState;
}

// eslint-disable-next-line no-restricted-exports,default-param-last
export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case OPEN_IMPORT_PAGE_DIALOG: {
      return state.withMutations((newState) => {
        newState.set("isOpen", true);
      });
    }
    case CLOSE_IMPORT_PAGE_DIALOG: {
      return state.withMutations((newState) => {
        newState.set("isOpen", false);
      });
    }
    default:
      return state;
  }
};
