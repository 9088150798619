// @flow
import type { Saga } from "redux-saga";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import {
  call, takeEvery, put, select,
} from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table/actions";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { DELETE_REDIRECT_LIST_SAGA, REDIRECT_LIST } from "../../helpers/constants";
import RedirectApi from "../../services/redirectApi";
import { getRedirectList } from "../../actions/redirectList";
import setLoading from "../../actions/loading";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(REDIRECT_LIST, true));
    const selected = yield select(getTableSelections, REDIRECT_LIST);
    yield call(RedirectApi.deleteRedirect, selected.map((i) => i.id));
    yield put(addNotification({ message: "Redirect deleted", severity: "success" }));
    yield put(setTableSelection(REDIRECT_LIST, []));
    yield put(getRedirectList());
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(REDIRECT_LIST, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(DELETE_REDIRECT_LIST_SAGA, makeFetch);
}
