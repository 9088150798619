// @flow
/* eslint-disable max-classes-per-file */
import type { State as PageInfoState } from "../../reducers/pageInfo";
import type { State as PageVersionsState, Version } from "../../reducers/pageVersions";
import type { Resource } from "../../reducers/pageResources";
import type {
  PageDocumnetModifierType,
  PageResourceType,
  PageToSaveType,
} from "../../containers/FloatingControlButtons/types/FloatingControlButtons.types";
import type { Modifier } from "../../reducers/pageModifiers/reducer";

export default class PageType {
  pageTypeTitle: string;

  pageTypeKey: string;

  page: PageToSaveType;

  version: Version;

  modifiers: Array<PageDocumnetModifierType>;

  resources: Array<PageResourceType>;

  pageInfo: PageInfoState;

  pageVersions: PageVersionsState;

  pageModifiers: Array<Modifier>;

  pageResources: Array<Resource>;

  preparePageData(
    pageInfo: PageInfoState,
    pageVersions: PageVersionsState,
    pageModifiers: Array<Modifier>,
    pageResources: Array<Resource>
  ) {
    this.pageInfo = pageInfo;
    this.pageVersions = pageVersions;
    this.pageModifiers = pageModifiers;
    this.pageResources = pageResources;

    const {
      versionType,
    }: PageInfoState = this.pageInfo;

    const {
      html: content,
      css,
      js,
    }: Version = this.pageVersions.get(versionType);

    this.page = {
      name: this.pageInfo.get("name"),
      type: this.pageInfo.get("pageType"),
      tags: this.pageInfo.get("tags"),
      contentType: "text/html",
      aliases: [this.pageInfo.get("url")],
      description: "",
      languages: ["en"],
      documentModifiers: [], // FIXME: remove after MTU-51872 is Done
      content,
      css,
      js,
    };
  }

  _proceedPageModifiersData() {
    this.modifiers = this.pageModifiers
      .reduce((
        result: Array<PageDocumnetModifierType>,
        modifier: Modifier
      ): Array<PageDocumnetModifierType> => result.concat({
        anchor: modifier.name,
        name: modifier.name,
        type: modifier.type,
        content: modifier.content,
        description: modifier.description,
      }), []);
  }

  _proceedPageResourcesData() {
    const resources: Array<Resource> = this.pageResources;
    this.resources = resources.reduce((
      result: Array<PageResourceType>,
      resource: Resource
    ): Array<PageResourceType> => {
      if (resource.resourceId) {
        return result.concat({ resourceId: resource.resourceId, name: resource.name });
      }
      // FIXME: backoffice api issue - should delete resource if not transfered - MTU-51386
      if (resource.base64URL) {
        return result.concat({ resource: resource.base64URL, name: resource.name });
      }
      return result;
    }, []);
  }

  getPageData(): PageToSaveType {
    return this.page;
  }
}

export class Page extends PageType {
  pageTypeTitle = "Page";

  pageTypeKey = "page";

  preparePageData(
    pageInfo: PageInfoState,
    pageVersions: PageVersionsState,
    pageModifiers: Array<Modifier>,
    pageResources: Array<Resource>
  ) {
    super.preparePageData(pageInfo, pageVersions, pageModifiers, pageResources);
    this._proceedPageResourcesData();

    this.page = {
      ...this.page,
      resources: this.resources,
    };
  }
}

export class Pft extends PageType {
  pageTypeTitle = "Pft";

  pageTypeKey = "pft";

  preparePageData(
    pageInfo: PageInfoState,
    pageVersions: PageVersionsState,
    pageModifiers: Array<Modifier>,
    pageResources: Array<Resource>
  ) {
    super.preparePageData(pageInfo, pageVersions, pageModifiers, pageResources);
    this._proceedPageModifiersData();
    this._proceedPageResourcesData();

    const { templateId }: { templateId: string } = this.pageInfo.get("template");

    this.page = {
      ...this.page,
      parentId: templateId,
      documentModifiers: this.modifiers,
      resources: this.resources,
    };
  }

  _proceedPageModifiersData() {
    this.modifiers = this.pageModifiers
      .filter(({ content }) => Boolean(content))
      .reduce((
        result: Array<PageDocumnetModifierType>,
        modifier: Modifier
      ): Array<PageDocumnetModifierType> => result.concat({
        anchor: modifier.name,
        name: modifier.name,
        type: modifier.type,
        content: modifier.content,
        description: modifier.description,
      }), []);
  }
}
export class Template extends PageType {
  pageTypeTitle = "Template";

  pageTypeKey = "template";

  preparePageData(
    pageInfo: PageInfoState,
    pageVersions: PageVersionsState,
    pageModifiers: Array<Modifier>,
    pageResources: Array<Resource>
  ) {
    super.preparePageData(pageInfo, pageVersions, pageModifiers, pageResources);
    this._proceedPageModifiersData();
    this._proceedPageResourcesData();

    this.page = {
      ...this.page,
      documentModifiers: this.modifiers,
      resources: this.resources,
    };
  }
}
export class Custom extends PageType {
  pageTypeTitle = "Custom";

  pageTypeKey = "custom";

  preparePageData(
    pageInfo: PageInfoState,
    pageVersions: PageVersionsState,
    pageModifiers: Array<Modifier>,
    pageResources: Array<Resource>
  ) {
    super.preparePageData(pageInfo, pageVersions, pageModifiers, pageResources);
    this._proceedPageResourcesData();
    // FIXME: backoffice api issue - custom does not accept resources but return error if not provided

    this.page = {
      ...this.page,
      resources: this.resources,
    };
  }
}
