// @flow
import React, { useEffect, useCallback } from "react";
import {
  Link, useParams, useMatch, useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@fas/ui-core";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  savePageSaga,
  createPageSaga,
} from "../../actions/page";
import { getIsSavePageLoading } from "../../selectors/loading";
import { getHashValue } from "../../services/jusCan";

const useStyles = makeStyles(() => ({
  root: {
    background: "white",
    zIndex: 4,
  },
}));

export default function ControlButtons() {
  const dispatch = useDispatch();
  const isSavePageLoading = useSelector(getIsSavePageLoading);
  const { id: pageId } = useParams();
  const navigate = useNavigate();
  const isEditInfo = useMatch("/pages/editInfo/:pageId");
  const classes = useStyles();

  const onSavePage = useCallback((options) => {
    dispatch(savePageSaga(options));
  }, [dispatch]);

  const onCreatePage = useCallback(() => {
    dispatch(createPageSaga(navigate));
  }, [dispatch]);

  const saveCallback = useCallback(
    (e) => {
      if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) && e.key === "s") {
        e.preventDefault();
        onSavePage();
      }
    },
    [onSavePage]
  );

  useEffect(() => {
    window.addEventListener("keydown", saveCallback, false);
    return () => {
      window.removeEventListener("keydown", saveCallback, false);
    };
  }, [saveCallback]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        position: "fixed",
        bottom: "0px",
        right: "0px",
        left: "0px",
      }}
      className={classes.root}
      data-testid="action-container"
    >
      <Box>
        {!isEditInfo && pageId && (
          <Link
            to={`/pages/editInfo/${pageId}?hashId=${getHashValue()}`}
            data-testid="page-editinfo-btn"
            sx={{ m: 1 }}
          >
            <Button variant="outlined" color="info">General Info</Button>
          </Link>
        )}
        {pageId && (
          <>
            <LoadingButton
              sx={{ m: 1 }}
              data-testid="page-preview-btn"
              loading={isSavePageLoading}
              variant="outlined"
              color="secondary"
              onClick={() => onSavePage({ isPreviewEnabled: true })}
            >
              Preview
            </LoadingButton>

            <LoadingButton
              sx={{
                m: 1,
              }}
              data-testid="page-publish-btn"
              loading={isSavePageLoading}
              variant="contained"
              color="primary"
              onClick={() => onSavePage({ activateAfterSave: true })}
            >
              Activate
            </LoadingButton>

            <LoadingButton
              sx={{ m: 1 }}
              data-testid="page-save-btn"
              loading={isSavePageLoading}
              variant="contained"
              color="secondary"
              onClick={onSavePage}
            >
              Save
            </LoadingButton>
          </>
        )}
        {!pageId && (
          <LoadingButton
            sx={{ m: 1 }}
            data-testid="page-create-btn"
            loading={isSavePageLoading}
            variant="contained"
            color="secondary"
            onClick={onCreatePage}
          >
            Create
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
}
