// @flow
import React from "react";
import { Typography } from "@mui/material";
import type { Breadcrumb } from "@fas/ui-core/lib/AppNavbar";
import { capitalizeFirstLetter } from "../../helpers";

interface Location {
  pathname: string;
  search: string;
}

const defaultCreateRegex = "/(?<name>.+)/create$";
const defaultEditRegex = "/(?<name>.+)/edit/(?<id>.+)";
const defaultEditInfoRegex = "/(?<name>.+)/editInfo/(?<id>.+)";
const defaultListRegex = "/(?<name>.+)/list$";
const defaultViewRegex = "/(?<name>.+)/view/(?<id>.+)";
const defaultIndexRegex = "/";

const createAccountRegex = "/createAccount";
const editAccountRegex = "/editAccount/(?<id>.+)";

const splitByUpperCaseRegex = /[A-Z]*[^A-Z]+/g;

export default function getBreadcrumbs(location: Location): Array<Breadcrumb> {
  if (location.pathname === defaultIndexRegex) {
    return [
      { path: "/", title: "Index page", component: <Typography>Main</Typography> },
    ];
  }
  if (location.pathname === createAccountRegex) {
    return [
      { path: "/", title: "Main" },
      { path: "/createAccount", title: "Create", component: <Typography>Create account</Typography> },
    ];
  }
  if (location.pathname.match(editAccountRegex)) {
    return [
      { path: "/", title: "Main" },
      { path: "/editAccount", title: "Edit", component: <Typography>Edit account</Typography> },
    ];
  }
  if (location.pathname.match(defaultEditRegex)) {
    const {
      groups: { name, id },
    }: any = location.pathname.match(defaultEditRegex);

    const title = capitalizeFirstLetter(
      name.match(splitByUpperCaseRegex).join(" ")
    );
    return [
      { path: `/${name}/list${location.search}`, title },
      {
        path: name,
        title: "Edit",
        component: <Typography>{`Edit: ${id}`}</Typography>,
      },
    ];
  }

  if (location.pathname.match(defaultEditInfoRegex)) {
    const {
      groups: { name, id },
    }: any = location.pathname.match(defaultEditInfoRegex);

    const title = capitalizeFirstLetter(
      name.match(splitByUpperCaseRegex).join(" ")
    );
    return [
      { path: `/${name}/list${location.search}`, title },
      {
        path: name,
        title: "Edit",
        component: <Typography>{`${id}`}</Typography>,
      },
      {
        path: name,
        title: "EditGeneral Info",
        component: <Typography>Edit General Info</Typography>,
      },
    ];
  }

  if (location.pathname.match(defaultCreateRegex)) {
    const {
      groups: { name },
    }: any = location.pathname.match(defaultCreateRegex);

    const title = capitalizeFirstLetter(
      name.match(splitByUpperCaseRegex).join(" ")
    );
    return [
      { path: `/${name}/list${location.search}`, title },
      {
        path: name,
        title: "New",
        component: <Typography>{`New ${title.slice(0, -1)}`}</Typography>,
      },
    ];
  }

  if (location.pathname.match(defaultListRegex)) {
    const {
      groups: { name },
    }: any = location.pathname.match(defaultListRegex);

    const br = capitalizeFirstLetter(
      name.match(splitByUpperCaseRegex).join(" ")
    );
    return [
      {
        path: `/${name}/list${location.search}`,
        title: "List",
        component: <Typography>{br}</Typography>,
      },
    ];
  }

  if (location.pathname.match(defaultViewRegex)) {
    const {
      groups: { name, id },
    }: any = location.pathname.match(defaultViewRegex);

    const br = capitalizeFirstLetter(
      name.match(splitByUpperCaseRegex).join(" ")
    );
    return [
      {
        path: `/${name}/list${location.search}`,
        title: br,
      },
      {
        path: name,
        title: "View",
        component: <Typography>{`View: ${id}`}</Typography>,
      },
    ];
  }
  return [];
}
