// @flow
import { takeEvery, call, put } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table/actions";
import { ACTIVATE_DOCUMENT_SAGA, PAGES_LIST } from "../../helpers/constants";
import DocumentApi from "../../services/documentApi";
import type { ActivateDocumentSaga } from "../../actions/pages";
import setLoading from "../../actions/loading";
import { getPagesListSaga } from "../../actions/pages/actions";
import type { PagesListLoading as LoadingTypes } from "../../actions/loading";

export function* makeFetch(action: ActivateDocumentSaga): Saga<void> {
  try {
    const { documentId }: ActivateDocumentSaga = action;
    yield put(setLoading<LoadingTypes>(PAGES_LIST, true));
    yield call(DocumentApi.publishDocument, { documentId });
    yield put(getPagesListSaga());
    yield put(setTableSelection(PAGES_LIST, []));
    yield put(addNotification({ message: "Page activated", severity: "success" }));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to change document status", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading<LoadingTypes>(PAGES_LIST, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(ACTIVATE_DOCUMENT_SAGA, makeFetch);
}
