// @flow
import { Record, List, Map } from "immutable";
import type { RecordFactory, RecordInstance } from "immutable";
import { getNextId } from "@fas/ui-framework/lib/services/generators";
import {
  PAGE_MODIFIERS_SET_PAGE_MODIFIERS,
  PAGE_MODIFIERS_SET_MODIFIER,
  PAGE_MODIFIERS_ADD_PAGE_MODIFIERS,
  PAGE_MODIFIERS_REMOVE_PAGE_MODIFIERS,
  PAGE_MODIFIERS_RESET_PAGE_MODIFIERS,
} from "../../helpers/constants";
import type { Actions } from "../../actions/pageModifiers";

export type Modifier = {
  name: string, // and anchor
  content: string,
  description: string,
  type: string,
};

export const makeModifier: RecordFactory<Modifier> = Record({
  name: "",
  content: "",
  description: "",
  type: "",
});

export type DefaultStateType = {
  byId: Map<string, RecordInstance<Modifier>>,
  allIds: List<string>
}

export const DefaultState: DefaultStateType = {
  byId: Map({
    "1": makeModifier({ name: "title", description: "Title page", type: "input" }),
    "2": makeModifier({ name: "description", description: "Description page", type: "input" }),
    "3": makeModifier({ name: "meta_title", description: "Meta title", type: "input" }),
    "4": makeModifier({ name: "meta_keywords", description: "Meta keywords", type: "input" }),
    "5": makeModifier({ name: "anchor", description: "Anchor page", type: "input" }),
    "6": makeModifier({ name: "header", description: "Header page", type: "input" }),
    "7": makeModifier({ name: "first_content", description: "First content", type: "html" }),
    "8": makeModifier({ name: "second_content", description: "Second content", type: "html" }),
    "9": makeModifier({ name: "third_content", description: "Third content", type: "html" }),
    "10": makeModifier({ name: "noindex", description: "Noindex page", type: "input" }),
  }),
  allIds: List(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]),
};

export type State = RecordInstance<DefaultStateType>;

export const makeState: RecordFactory<DefaultStateType> = Record(DefaultState);

export const initialState: State = makeState();

export function initPageModifiersState(): State {
  return initialState;
}

export type IncomingPageModifiersState = {|
  content: string,
  documentId: string,
  id: string,
  name: string,
  description: string,
  type: string, // input textarea html pageCrossing
  anchor?: string,
  createdAt?: string,
  updatedAt?: string,
|}

// eslint-disable-next-line complexity,default-param-last
export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case PAGE_MODIFIERS_SET_PAGE_MODIFIERS: {
      const { modifierCollection } = action;
      if (!Array.isArray(modifierCollection)) return state;

      return state.withMutations((newState: State) => {
        modifierCollection.forEach((i: Modifier) => {
          const index: string = newState.get("byId").findKey((item: RecordInstance<Modifier>): boolean => item.get("name") === i.name) || "";
          if (index) {
            newState.setIn(["byId", index], makeModifier(i));
          }
          else {
            const newIndex: string = getNextId(newState.get("allIds"));
            newState.setIn(["byId", newIndex], makeModifier(i));
            newState.set("allIds", newState.get("allIds").push(newIndex));
          }
        });
      });
    }
    case PAGE_MODIFIERS_RESET_PAGE_MODIFIERS: {
      return initialState;
    }
    case PAGE_MODIFIERS_ADD_PAGE_MODIFIERS: {
      const key: string = state.get("byId").findKey((item: RecordInstance<Modifier>): boolean => item.get("name") === action.modifier.name) || "";
      if (key) return state;

      const newIndex: string = getNextId(state.get("allIds"));
      return state.setIn(["byId", newIndex], makeModifier(action.modifier))
        .set("allIds", state.get("allIds").push(newIndex));
    }
    case PAGE_MODIFIERS_REMOVE_PAGE_MODIFIERS: {
      const key: string = state.get("byId").findKey((item: RecordInstance<Modifier>): boolean => item.get("name") === action.name) || "";
      return state.deleteIn(["byId", key])
        .set("allIds", state.get("allIds").filter((k: string): boolean => k !== key));
    }
    case PAGE_MODIFIERS_SET_MODIFIER: {
      const key: string = state.get("byId").findKey((item: RecordInstance<Modifier>): boolean => item.get("name") === action.name) || "";
      if (!key) return state;

      return state.setIn(["byId", key, "content"], action.content);
    }
    default:
      return state;
  }
};
