// @flow
import { type NavigateFunction } from "react-router";
import {
  GET_LINKING_LIST_SAGA,
  SET_LINKING_LIST,
  SET_LINKING_LIST_COUNT,
  GET_LINKING_ITEM_SAGA,
  SET_LINKING_ITEM,
  SET_LINKING_ITEM_NAME,
  SET_LINKING_ITEM_TYPE,
  SET_LINKING_ITEM_TAGS_GENERATION,
  SET_LINKING_ITEM_TAGS_DISPLAY,
  SET_LINKING_ITEM_TEMPLATE_CODE,
  SET_LINKING_ITEM_SORTING,
  SET_LINKING_ITEM_WIDTH,
  BULK_DELETE_LINKING_SAGA,
  SAVE_LINKING_ITEM_SAGA,
  SET_LINKING_ITEM_ALGORITHM,
  SET_LINKING_ITEM_HEIGHT,
  GET_LINKING_ITEM_HEIGHT_SAGA,
  SET_LINKING_ITEM_USE_PAGINATION,
} from "../../helpers/constants";
import type {
  LinkingInfo,
  Types as LinkingInfoTypes,
  Sorting as LinkingInfoSortings,
  Algorithm as LinkingInfoAlgorithms,
} from "../../components/LinkingInfoForm/types/LinkingInfoForm.types";

export type GetLinkingListSaga = {
  type: typeof GET_LINKING_LIST_SAGA,
};

export type SetLinkingList = {
  type: typeof SET_LINKING_LIST,
  linkingList: Array<LinkingInfo>,
};

export type SetLinkingListCount = {
  type: typeof SET_LINKING_LIST_COUNT,
  count: number,
};

export type GetLinkingInfoSaga = {
  type: typeof GET_LINKING_ITEM_SAGA,
  id: string,
};

export type SetLinkingInfo = {
  type: typeof SET_LINKING_ITEM,
  linkingInfo: LinkingInfo,
};

export type SetLinkingInfoName = {
  type: typeof SET_LINKING_ITEM_NAME,
  name: string,
};

export type SetLinkingInfoType = {
  type: typeof SET_LINKING_ITEM_TYPE,
  linkingInfoType: LinkingInfoTypes,
};

export type SetLinkingInfoTagsGeneration = {
  type: typeof SET_LINKING_ITEM_TAGS_GENERATION,
  tagsGeneration: Array<string>,
};

export type SetLinkingInfoTagsDisplay = {
  type: typeof SET_LINKING_ITEM_TAGS_DISPLAY,
  tagsDisplay: Array<string>,
};

export type SetLinkingInfoTemplateCode = {
  type: typeof SET_LINKING_ITEM_TEMPLATE_CODE,
  templateCode: string,
};

export type SetLinkingInfoSorting = {
  type: typeof SET_LINKING_ITEM_SORTING,
  sorting: LinkingInfoSortings,
};

export type SetLinkingInfoWidth = {
  type: typeof SET_LINKING_ITEM_WIDTH,
  width: number,
};

export type BulkDeleteLinkingSaga = {
  type: typeof BULK_DELETE_LINKING_SAGA,
};

export type SaveLinkingInfoSaga = {
  type: typeof SAVE_LINKING_ITEM_SAGA,
  navigate: NavigateFunction,
};

export type SetLinkingInfoAlgorithm = {
  type: typeof SET_LINKING_ITEM_ALGORITHM,
  algorithm: LinkingInfoAlgorithms,
};

export type SetLinkingInfoHeight = {
  type: typeof SET_LINKING_ITEM_HEIGHT,
  height: number,
};

export type GetLinkingInfoHeightSaga = {
  type: typeof GET_LINKING_ITEM_HEIGHT_SAGA,
  tag: Array<string>,
};

export type SetLinkingInfoUsePagination = {
  type: typeof SET_LINKING_ITEM_USE_PAGINATION,
  usePagination: boolean,
};

export type Actions =
  | BulkDeleteLinkingSaga
  | GetLinkingInfoSaga
  | GetLinkingListSaga
  | SetLinkingInfo
  | SetLinkingInfoName
  | SetLinkingInfoSorting
  | SetLinkingInfoTagsDisplay
  | SetLinkingInfoTagsGeneration
  | SetLinkingInfoTemplateCode
  | SetLinkingInfoType
  | SetLinkingInfoWidth
  | SetLinkingList
  | SetLinkingListCount
  | SetLinkingInfoAlgorithm
  | SetLinkingInfoHeight
  | SetLinkingInfoUsePagination;

export const getLinkingListSaga: () => GetLinkingListSaga = () => ({
  type: GET_LINKING_LIST_SAGA,
});

export const setLinkingList: (
  linkingList: Array<LinkingInfo>
) => SetLinkingList = (linkingList) => ({
  type: SET_LINKING_LIST,
  linkingList,
});

export const setLinkingListCount: (count: number) => SetLinkingListCount = (
  count
) => ({
  type: SET_LINKING_LIST_COUNT,
  count,
});

export const getLinkingInfoSaga: (id: string) => GetLinkingInfoSaga = (id) => ({
  type: GET_LINKING_ITEM_SAGA,
  id,
});

export const setLinkingInfo: (linkingInfo: LinkingInfo) => SetLinkingInfo = (
  linkingInfo
) => ({
  type: SET_LINKING_ITEM,
  linkingInfo,
});

export const setLinkingInfoName: (name: string) => SetLinkingInfoName = (
  name
) => ({ type: SET_LINKING_ITEM_NAME, name });

export const setLinkingInfoType: (
  linkingInfoType: LinkingInfoTypes
) => SetLinkingInfoType = (linkingInfoType) => ({
  type: SET_LINKING_ITEM_TYPE,
  linkingInfoType,
});

export const setLinkingInfoTagsGeneration: (
  tagsGeneration: Array<string>
) => SetLinkingInfoTagsGeneration = (tagsGeneration) => ({
  type: SET_LINKING_ITEM_TAGS_GENERATION,
  tagsGeneration,
});

export const setLinkingInfoTagsDisplay: (
  tagsDisplay: Array<string>
) => SetLinkingInfoTagsDisplay = (tagsDisplay) => ({
  type: SET_LINKING_ITEM_TAGS_DISPLAY,
  tagsDisplay,
});

export const setLinkingInfoTemplateCode: (
  templateCode: string
) => SetLinkingInfoTemplateCode = (templateCode) => ({
  type: SET_LINKING_ITEM_TEMPLATE_CODE,
  templateCode,
});

export const setLinkingInfoSorting: (
  sorting: LinkingInfoSortings
) => SetLinkingInfoSorting = (sorting) => ({
  type: SET_LINKING_ITEM_SORTING,
  sorting,
});

export const setLinkingInfoWidth: (width: number) => SetLinkingInfoWidth = (
  width
) => ({
  type: SET_LINKING_ITEM_WIDTH,
  width,
});

export const bulkDeleteLinkingInfoSaga: () => BulkDeleteLinkingSaga = () => ({
  type: BULK_DELETE_LINKING_SAGA,
});

export const saveLinkingInfoSaga: (navigate: NavigateFunction) => SaveLinkingInfoSaga = (navigate) => ({
  type: SAVE_LINKING_ITEM_SAGA,
  navigate,
});

export const setLinkingInfoAlgorithm: (
  algorithm: LinkingInfoAlgorithms
) => SetLinkingInfoAlgorithm = (algorithm) => ({
  type: SET_LINKING_ITEM_ALGORITHM,
  algorithm,
});

export const setLinkingInfoHeight: (height: number) => SetLinkingInfoHeight = (
  height
) => ({
  type: SET_LINKING_ITEM_HEIGHT,
  height,
});

export const getLinkingInfoHeightSaga: (
  tag: Array<string>
) => GetLinkingInfoHeightSaga = (tag) => ({
  type: GET_LINKING_ITEM_HEIGHT_SAGA,
  tag,
});

export const setLinkingInfoUsePagination: (boolean) => SetLinkingInfoUsePagination = (usePagination) => ({
  type: SET_LINKING_ITEM_USE_PAGINATION,
  usePagination,
});
