// @flow
import { Record } from "immutable";
import type { RecordFactory, RecordInstance } from "immutable";
import { makeModifier, type Modifier } from "../pageModifiers/reducer";
import { SET_MODIFIER_FORM_FIELDS, SET_MODIFIER_FORM_IS_EXPAND } from "../../helpers/constants";

export type DefaultStateType = {
  data: RecordInstance<Modifier>,
  isExpand: boolean,
}

const DefaultState: DefaultStateType = {
  data: makeModifier({ name: "", description: "", type: "textarea" }),
  isExpand: false,
};

export type State = RecordInstance<DefaultStateType>;

export const makeState: RecordFactory<DefaultStateType> = Record(DefaultState);

export const initialState: State = makeState();

export function initModifierFormState(): State {
  return initialState;
}

// eslint-disable-next-line default-param-last
export default (state: State = initialState, action: any): State => {
  switch (action.type) {
    case SET_MODIFIER_FORM_IS_EXPAND: {
      return state.set("isExpand", action.value);
    }
    case SET_MODIFIER_FORM_FIELDS: {
      return state.set("data", state.get("data").merge(action.data));
    }
    default:
      return state;
  }
};
