// @flow
import React from "react";
import DocumentTitle from "react-document-title";
import { useParams } from "react-router-dom";
import {
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import RedirectInfo from "../../containers/RedirectInfo";

const useStyles = makeStyles((theme) => ({
  mainBackground: {
    background: theme.palette.background.mainBg,
    minHeight: "calc(100% - 64px)",
  },
}));

function Redirect() {
  const { id } = useParams();

  const classes = useStyles();

  return (
    <DocumentTitle title={id ? "Update redirect" : "Create redirect"}>
      <Grid container direction="column" className={classes.mainBackground}>
        <Grid item>
          <RedirectInfo />
        </Grid>
      </Grid>
    </DocumentTitle>
  );
}

export default Redirect;
