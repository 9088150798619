// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type { RecordInstance } from "immutable";
import type { Modifier } from "../../reducers/pageModifiers/reducer";
import type { State } from "../../pages/AppStoreWrapper";

export const getModifierFormData: OutputSelector<State, *, Modifier> = createSelector(
  (state: State): RecordInstance<Modifier> => state.page.modifierForm.get("data"),
  (data: RecordInstance<Modifier>): Modifier => data.toObject()
);

export const getModifierFormIsExpand: OutputSelector<State, *, boolean> = createSelector(
  (state: State): boolean => state.page.modifierForm.get("isExpand"),
  (value: boolean): boolean => value
);
