// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import { useDispatch } from "react-redux";
import type { TableProps } from "@fas/ui-framework/lib/services/table";
import { useTable } from "@fas/ui-framework/lib/services/table";
import { Link, useNavigate } from "react-router-dom";
import {
  SelectSearchComponent, ShowMoreItems,
  Table,
} from "@fas/ui-core";
import type {
  Props,
} from "./types/LinkingList.types";
import ActionsMenu from "./ActionsMenu";
import { LINKING_LIST } from "../../helpers/constants";
import {
  getLinkingListSaga,
} from "../../actions/linkingList";
import { getHashValue } from "../../services/jusCan";

function SearchComponent(searchProps) {
  return (
    <SelectSearchComponent
      {...searchProps}
      values={[
        { value: "matrix", title: "Matrix" },
        { value: "list", title: "List" },
      ]}
    />
  );
}

function LinkingList({
}: Props) {
  const columns = [
    {
      field: "name",
      label: "Name",
      searchable: true,
      sortable: true,
      render: ({ id, name }) => (
        <Link to={`/linking/edit/${id}?hashId=${getHashValue()}`}>
          {name}
        </Link>
      ),
    },
    {
      field: "type",
      label: "Type",
      searchable: true,
      sortable: true,
      SearchComponent,
    },
    {
      field: "tagsGeneration",
      label: "Tags generation",
      searchable: false,
      sortable: false,
      render: (row) => (<ShowMoreItems data={row.tagsGeneration} />),
    },
    {
      field: "tagsDisplay",
      label: "Tags display",
      searchable: false,
      sortable: false,
      render: (row) => (<ShowMoreItems data={row.tagsDisplay} />),
    },
    {
      field: "updatedAt",
      label: "Updated at",
      searchable: false,
      sortable: true,
    },
    {
      field: "lastEditor",
      label: "Author",
      searchable: false,
      sortable: true,
    },
  ];
  const navigate = useNavigate();
  const dispatch: <A>(A) => A = useDispatch();
  const tableProps: TableProps = useTable(LINKING_LIST);
  const onLoad = () => dispatch(getLinkingListSaga());

  const onCreate: () => mixed = () => navigate(`/linking/create?hashId=${getHashValue()}`);

  return (
    <Table
      {...tableProps}
      columns={columns}
      title="Linking list"
      onLoad={onLoad}
      onCreate={onCreate}
      rowSelectAvailable={() => true}
      allSelectAvailable={() => true}
      Actions={<ActionsMenu />}
    />
  );
}

export default LinkingList;
