// @flow
import {
  takeEvery, call, put, all,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import get from "lodash.get";
import { GET_ACCOUNT_MODULES_SAGA, ACCOUNT_MODULES_LOADING } from "../../helpers/constants";
import type {
  GetAccountsModulesSaga,
  Modules,
  ModuleNames,
} from "../../actions/accountModules";
import { setAccountModule } from "../../actions/accountModules";

import getAccountModules from "../../services/accountModulesApi";
import setLoading from "../../actions/loading";

export function* makeFetch(action: GetAccountsModulesSaga): Saga<void> {
  try {
    yield put(setLoading(ACCOUNT_MODULES_LOADING, true));
    const { moduleNames } = action;
    const response = yield call(getAccountModules, moduleNames);

    const modules: {
      [key: ModuleNames]: Modules,
    } = get(response, "data.modules");

    yield all(
      //  $FlowFixMe
      Object.values(modules).map((x) => put(setAccountModule(x)))
    );
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch account modules", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(ACCOUNT_MODULES_LOADING, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_ACCOUNT_MODULES_SAGA, (makeFetch: Function));
}
