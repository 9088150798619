// @flow
/* eslint-disable import/max-dependencies */
import {
  takeEvery, call, put,
} from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import type { Response } from "@fas/ui-framework/lib/services/request";
import type { Saga } from "redux-saga";
import DocumentApi from "../../services/documentApi";
import {
  setPage,
  setPageVersion,
  changePagePftTemplate,
} from "../../actions/pageInfo";
import { setPageModifiers } from "../../actions/pageModifiers";
import { setPageVersions } from "../../actions/pageVersions";
import { setPageResources } from "../../actions/pageResources";
import setLoading from "../../actions/loading";
import type { GetPageSagaAction } from "../../actions/page";
import type { VersionType, IncomingPageVersionState } from "../../reducers/pageVersions";
import type { IncomingPageInfoState } from "../../reducers/pageInfo";
import type { PageLoadingTypes as LoadingTypes } from "../../actions/loading";
import type { IncominResource } from "../../reducers/pageResources";
import type { IncomingPageModifiersState } from "../../reducers/pageModifiers";
import { GET_PAGE_LOADING, GET_PAGE_SAGA } from "../../helpers/constants";

export function* makeFetch(action: GetPageSagaAction): Saga<void> {
  try {
    yield put(setLoading<LoadingTypes>(GET_PAGE_LOADING, true));
    let version: string;
    let versionType: VersionType;
    const {
      id: paramsPageId,
      versionId: paramsVersionId,
    }: GetPageSagaAction = action;

    const response: Response<mixed, { result: IncomingPageInfoState }> = yield call(DocumentApi.getPage, paramsPageId);
    const {
      data: {
        result: page,
      },
    }: Response<mixed, { result: IncomingPageInfoState }> = response;

    const { versionCollection, type, parentId }: IncomingPageInfoState = page;

    if (paramsVersionId && versionCollection.some(({
      id,
    }: IncomingPageVersionState): boolean => id === paramsVersionId)) {
      version = paramsVersionId;
      const incomingVersionIndex: number = versionCollection
        .findIndex(({ id }: IncomingPageVersionState): boolean => id === paramsVersionId);
      versionType = versionCollection[incomingVersionIndex].version;
    }
    else {
      versionType = versionCollection
        .findIndex(({ version: incomingVersion }: IncomingPageVersionState): boolean => incomingVersion === "new") !== -1
        ? "new"
        : "current";
      const incomingVersionIndex: number = versionCollection
        .findIndex(({ version: incomingVersion }: IncomingPageVersionState): boolean => (
          incomingVersion === versionType));
      version = versionCollection[incomingVersionIndex].id;
    }

    if (type === "pft" && parentId) {
      const {
        data: {
          result: {
            name: templateName,
            modifierCollection,
          },
        },
      }: Response<mixed, {
        result: {
          name: string,
          modifierCollection?: IncomingPageModifiersState[],
        }
      }> = yield call(DocumentApi.getPage, parentId);

      yield put(changePagePftTemplate(parentId, templateName));
      // we set modifier from template without content
      yield put(setPageModifiers((modifierCollection || []).map((item: IncomingPageModifiersState): IncomingPageModifiersState => ({ ...item, content: "" }))));
    }

    yield put(setPage({ ...page }));
    yield put(setPageVersions({ ...page }));
    yield put(setPageModifiers(page.modifierCollection));

    yield put(setPageVersion(versionType, version));

    const {
      data: { data },
    }: Response<mixed, { data: IncominResource[] }> = yield call(DocumentApi.getVersionResources, version);

    yield put(setPageResources(data));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch a page", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading<LoadingTypes>(GET_PAGE_LOADING, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_PAGE_SAGA, makeFetch);
}
