// @flow
/* eslint-disable import/max-dependencies */
import { put, select, takeEvery } from "redux-saga/effects";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";
import joi from "joi";
import type { Saga } from "redux-saga";
import { SAVE_MODIFIER_FORM_SAGA } from "../../helpers/constants";
import { type SaveModifierFormSagaAction, setModifierFormFields, setModifierFormIsExpand } from "../../actions/modifierForm";
import type { Modifier } from "../../reducers/pageModifiers/reducer";
import errorsTransform from "../../helpers/errorsTransform";
import { addModifier } from "../../actions/pageModifiers";
import { getDocumentModifiers } from "../../selectors/page";

function validateModifier(modifier: Modifier, pageModifiers: Array<Modifier>): ErrorsMapper {
  const validateSchema = joi.object({
    modifierCreateFormName: joi.string()
      .min(1).max(255)
      .label("Anchor")
      .custom((value, helpers) => {
        const isExistModifierByName: boolean = pageModifiers.some((item) => item.name === value);
        if (isExistModifierByName) {
          return helpers.message("\"Anchor\" is duplicate");
        }
        return value;
      })
      .required(),
    modifierCreateFormDescription: joi.string()
      .min(1).max(255)
      .label("Description")
      .required(),
    modifierCreateFormType: joi.valid("input", "textarea", "html")
      .label("Type")
      .required(),
  }).required();

  return errorsTransform(validateSchema.validate({
    modifierCreateFormName: modifier.name,
    modifierCreateFormType: modifier.type,
    modifierCreateFormDescription: modifier.description,
  }, { abortEarly: false }));
}

export function* makeSave(action: SaveModifierFormSagaAction): Saga<void> {
  const pageModifiers: Array<Modifier> = yield select(getDocumentModifiers);
  const err: ErrorsMapper = validateModifier(action.data, pageModifiers);

  yield put(setErrors(err));
  if (Object.keys(err).length === 0) {
    yield put(addModifier(action.data));
    yield put(setModifierFormIsExpand(false));
    yield put(setModifierFormFields({
      name: "",
      description: "",
      type: "textarea",
    }));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(SAVE_MODIFIER_FORM_SAGA, makeSave);
}
