// @flow
import { type NavigateFunction } from "react-router";
import {
  GET_ACCOUNT_SAGA,
  SAVE_ACCOUNT_SAGA,
} from "../../helpers/constants";

export type GetAccountSaga = {|
  type: typeof GET_ACCOUNT_SAGA,
  accountId: string,
|};

export type SaveAccountSaga = {|
  type: typeof SAVE_ACCOUNT_SAGA,
  accountId?: string,
  navigate: NavigateFunction,
|};

export type Actions =
  GetAccountSaga
  | SaveAccountSaga;

export const getAccount = (accountId: string): GetAccountSaga => ({
  type: GET_ACCOUNT_SAGA,
  accountId,
});

export const saveAccount = (accountId?: string, navigate: NavigateFunction): SaveAccountSaga => ({
  type: SAVE_ACCOUNT_SAGA,
  accountId,
  navigate,
});
