// @flow
import {
  GET_ACCOUNT_MODULES_SAGA,
  SET_ACCOUNTS_MODULES,
} from "../../helpers/constants";
import type {
  Types as LinkingInfoTypes,
  Sorting as LinkingInfoSorting,
  Algorithm as LinkingInfoAlgorithm,
} from "../../components/LinkingInfoForm/types/LinkingInfoForm.types";
import type { ResourceFileFormats } from "../pageResources";

export type { ResourceFileFormats };
export type ModuleNames = "sitemap" | "linking" | "resource" | "document";

export type LinkingSettings = {
  algorithm: Array<LinkingInfoAlgorithm>,
  sorting: Array<LinkingInfoSorting>,
  type: Array<LinkingInfoTypes>,
};

export type ResourceSettings = {
  fileFormat: Array<ResourceFileFormats>,
};

export type SitemapSettings = {
  tag: "sitemap",
  name: "sitemap",
  template: "default",
};

export type DocumentAliasMode = "default" | "openId";

export type DocumentSettings = {
  documentAliasMode: DocumentAliasMode,
  pageNameAutoGenerating: boolean,
};

export type DocumentModule = {
  name: "document",
  settings: DocumentSettings,
};

export type LinkingModule = {
  name: "linking",
  settings: LinkingSettings,
};

export type ResourceModule = {
  name: "resource",
  settings: ResourceSettings,
};

export type SitemapModule = {
  name: "sitemap",
  settings: SitemapSettings,
};
export type ModuleSettings =
  | LinkingSettings
  | ResourceSettings
  | DocumentSettings
  | SitemapSettings;

export type Modules = LinkingModule | ResourceModule | SitemapModule | DocumentModule;
export type GetAccountsModulesSaga = {
  type: typeof GET_ACCOUNT_MODULES_SAGA,
  moduleNames: Array<ModuleNames>,
};

export type SetAccountModule = {
  type: typeof SET_ACCOUNTS_MODULES,
  accountModule: LinkingModule | ResourceModule | SitemapModule | DocumentModule,
};

export type Actions = SetAccountModule | GetAccountsModulesSaga;

export const getAccountsModulesSaga = (
  moduleNames: Array<ModuleNames>
): GetAccountsModulesSaga => ({
  type: GET_ACCOUNT_MODULES_SAGA,
  moduleNames,
});

export const setAccountModule: (
  accountModule: LinkingModule | ResourceModule | SitemapModule | DocumentModule
) => SetAccountModule = (accountModule): SetAccountModule => ({
  type: SET_ACCOUNTS_MODULES,
  accountModule,
});
