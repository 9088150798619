// @flow
import {
  PAGE_VERSIONS_CHANGE_VERSION_HTML,
  PAGE_VERSIONS_CHANGE_VERSION_CSS,
  PAGE_VERSIONS_CHANGE_VERSION_JS,
  PAGE_VERSIONS_SET_PAGE_VERSIONS,
} from "../../helpers/constants";

import type { IncomingPageInfoState } from "../../reducers/pageInfo";
import type { VersionType } from "../../reducers/pageVersions";

export type ChangePageVersionHtmlAction = {|
  type: typeof PAGE_VERSIONS_CHANGE_VERSION_HTML,
  version: VersionType,
  content: string,
|}

export type ChangePageVersionCssAction = {|
  type: typeof PAGE_VERSIONS_CHANGE_VERSION_CSS,
  version: VersionType,
  content: string,
|}

export type ChangePageVersionJsAction = {|
  type: typeof PAGE_VERSIONS_CHANGE_VERSION_JS,
  version: VersionType,
  content: string,
|}

export type SetPageVersionsAction = {|
  type: typeof PAGE_VERSIONS_SET_PAGE_VERSIONS,
  payload: IncomingPageInfoState,
|}

export type Actions =
  ChangePageVersionHtmlAction
  | ChangePageVersionCssAction
  | ChangePageVersionJsAction
  | SetPageVersionsAction;

export const changePageVersionHtml = (version: VersionType, content: string): ChangePageVersionHtmlAction => ({
  type: PAGE_VERSIONS_CHANGE_VERSION_HTML,
  version,
  content,
});
export const changePageVersionCss = (version: VersionType, content: string): ChangePageVersionCssAction => ({
  type: PAGE_VERSIONS_CHANGE_VERSION_CSS,
  version,
  content,
});
export const changePageVersionJs = (version: VersionType, content: string): ChangePageVersionJsAction => ({
  type: PAGE_VERSIONS_CHANGE_VERSION_JS,
  version,
  content,
});
export const setPageVersions = (payload: IncomingPageInfoState): SetPageVersionsAction => ({
  type: PAGE_VERSIONS_SET_PAGE_VERSIONS,
  payload,
});
